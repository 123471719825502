<template>
	<b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white cursor-pointer" @click="initData" title="Click to refresh notifications.">YouTube Notifications</h3>
			<div class="d-flex">
				<b-link v-if="pending == 0" to="/youtube-notifications?pending=1"><b-button variant="success" class="mr-2">
					Pending Notifications <b-badge variant="danger">{{ pendingYoutubeNotification }}</b-badge>
				</b-button></b-link>
				<b-link v-else to="/youtube-notifications"><b-button variant="success" class="mr-2">Active Notifications</b-button></b-link>
			</div>
		</template>
		<br>
		<div class="d-flex">
			<b-button v-if="!pending" class="my-2 btn-success" @click="deleteAllSuccessfulNotifications">Delete All Sucessful Notifications</b-button>
			<b-button v-if="pending" class="my-2 mr-1 btn-success" @click="resetSelectedNotifications">Reset Selected Notifications</b-button>
			<b-button v-if="pending" class="my-2 btn-danger" @click="resetAllFailedNotifications">Reset All Failed Notifications</b-button>
		</div>
		<br>

		<b-table v-if="!loading && count"  responsive="sm" :items="notifications" :fields="fields" class="text-center" striped bordered >

			<template v-slot:cell(select)="data">
				<b-form-checkbox v-model="data.item.selected" v-if="pending" />
			</template>

			<template v-slot:cell(video)="data">
				<div class="dashboard-action d-flex m-auto">
					<a  @click="videoPlay(data.item)">
						<feather-icon icon="PlayCircleIcon"
						size="18"/>
					</a>
					<a v-if="data.item.youtubeVideoId" @click="copyToClipboard(data.item.youtubeVideoId)" title="copy youtube id">
						<feather-icon icon="ClipboardIcon"
						size="18"/>
					</a>
				</div>
			</template>

			<template v-slot:cell(createdAt)="data">
				{{ data.item.createdAt
					? new Date(
							Number(data.item.createdAt)
						).toLocaleString()
					: ""
				}}
			</template>
			<template v-slot:cell(processingStartedAt)="data">
				{{ data.item.processingStartedAt
					? new Date(
							Number(data.item.processingStartedAt)
						).toLocaleString()
					: ""
				}}
			</template>
			<template v-slot:cell(processingEndedAt)="data">
				{{ data.item.processingEndedAt
					? new Date(
							Number(data.item.processingEndedAt)
						).toLocaleString()
					: ""
				}}
			</template>

			<template v-slot:cell(status)="data">
				<span
					v-if="
						data.item.processingStartedAt != null &&
						data.item.processingEndedAt != null
					"
					>Successful</span
				>
				<span
					v-else-if="
						data.item.processingStartedAt === null ||
						(data.item.processingStartedAt &&
							Date.now() -
								Number(data.item.processingStartedAt) <
								60000 &&
							data.item.processingEndedAt === null)
					"
					>Pending</span
				>
				<span v-else>Failed</span>
			</template>


			<template v-slot:cell(actions)="data">
				<div class="d-flex justify-content-center">
					<b-button variant="danger" class="mr-min btn-icon" @click="updateNotification([data.item.id], 'delete')" v-if="!pending">
						<feather-icon icon="TrashIcon" size="18"/>
					</b-button>
					<b-button variant="primary" class="mr-min btn-icon" @click="updateNotification([data.item.id], 'retry')" v-if="pending">
						<feather-icon icon="RefreshCwIcon" size="18"/>
					</b-button>

					<b-button variant="danger" class="mr-min btn-icon" @click="updateNotification([data.item.id], 'delete')" v-if="pending">
						<feather-icon icon="TrashIcon" size="18"/>
					</b-button>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="loading">loading...</span>
			<span v-else>No notifications found</span>
		</div>

		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="count" :limit="limit" :page="page"/>
		</template>
    <player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>
  </b-card>

</template>

<script>
import { mapGetters } from 'vuex'


export default {
  data() {
    return {
			fields: [
				{ key: 'select', label: '' },
				{ key: 'youtubeVideoId', label: 'Youtube Video Id' },
				{ key: 'video', label: 'Video' },
				{ key: 'createdAt', label: 'Created At' },
				{ key: 'processingStartedAt', label: 'Processing Started At' },
				{ key: 'processingEndedAt', label: 'Processing Ended At' },
				{ key: 'status', label: 'Status' },
				{ key: 'actions', label: 'Actions' },
			],
			selectedShow: {},
			showVideoPlayer: false,
    }
  },
	computed: {
		...mapGetters('youtubeNotification', ['notifications', 'count', 'loading', 'selectedNotifications']),
		...mapGetters('notifications', ['pendingYoutubeNotification']),
		page: {
			get() {
				return (this.$route.query?.page || 1) * 1;
			},
			set(page) {
				if(page == this.page) return;
				this.$router.push({ query: { ...this.$route.query, page } })
			}
		},
		limit: {
			get() {
				return (this.$route.query?.limit || 10) * 1;
			},
			set(limit) {
				if(limit == this.limit) return;
				this.$router.push({ query: { ...this.$route.query, limit } })
			}
		},
		pending: {
			get() {
				return (this.$route.query?.pending || 0) * 1;
			},
			set(pending) {
				if(pending == this.pending) return;
				this.$router.push({ query: { ...this.$route.query, pending } })
			}
		},
	},
  mounted() {
    this.initData()
  },
  watch: {
    page () {
      this.getYoutubeNotificaitons()
    },
		pending () {
			this.getYoutubeNotificaitons()
		}
  },
	methods: {
		initData() {
    	this.getYoutubeNotificaitons()
		},
		linkGen(page) {
			return {
				name: 'youtubeNotification',
				query: {
					page,
					limit: this.limit,
					pending: this.pending,
				},
			}
		},
		getYoutubeNotificaitons(page) {
			if(page) {
				this.page = page
			}
			this.$store.dispatch("youtubeNotification/fetchYoutubeNotifications", {
				skip: (this.page - 1) * this.limit,
				limit: this.limit * 1,
				pending: this.pending,
			});
		},
		updateNotification(ids, type) {
			this.confirmDialog().then((value) => {
				if(!value) return;
				this.$store.dispatch("youtubeNotification/updateNotifications", {ids, type, success: this.successToast, error: this.errorDialog	});
			})
		},
		updateSelected(type) {
			const ids = this.selectedNotifications.map((item) => item.id)
			this.updateNotification(ids, type)
		},
    videoPlay(show = {}) {
      this.selectedShow = show
      this.showVideoPlayer = show.youtubeVideoId ? true : false
    },
		deleteAllSuccessfulNotifications() {
			this.confirmDialog().then((value) => {
				if(!value) return;
				this.$store.dispatch("youtubeNotification/deleteAllSuccessfulNotifications", {success: this.successToast, error: this.errorDialog});
			})
		},
		resetSelectedNotifications() {
			const ids = this.selectedNotifications.map((item) => item.id)
			this.updateNotification(ids, "reset")
		},
		resetAllFailedNotifications() {
			this.confirmDialog().then((value) => {
				if(!value) return;
				this.$store.dispatch("youtubeNotification/resetAllFailedNotifications", {success: this.successToast, error: this.errorDialog});
			})
		},

	},
}
</script>

<style>
.modal-body{
	overflow: unset;
}
.input-icon {
	border-radius: 6px;
	padding: 2px;
	line-height: 17px;
}
.dashboard-action {
	justify-content: center;
}
</style>
