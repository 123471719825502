<template>
  <b-card class="content-body">
    <div class="search-form">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="Search by name..." @input="debounceInput" v-model.trim="query"/>
        <b-input-group-append>
          <b-button variant="primary" @click="getUsers">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-button class="my-2 btn-success" @click="sendMailToggle">Send Email to Users</b-button>

    <table class="table table-bordered table-striped bordered  table-hover text-center shows-table shows-table" style="position:relative">
      <thead>
        <tr>
          <th> </th>
          <th>Name</th>
          <th>Email</th>
          <th>Signup With</th>
          <th>Photo</th>
          <th>Role</th>
          <th>Reviews</th>
          <th>Memeber Since</th>
          <th>Actions </th>
        </tr>
      </thead>
      <tr v-if="loading">
        <div class="table-spinner">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </tr>

      <tbody>
        <tr v-for="user in users" :key="user.id">
          <th> <b-form-checkbox v-model="user.selected"/> </th>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.providerId }}</td>
          <td>
            <a
              v-if="
                user.displayImage &&
                user.displayImage != '/img/default.png'
              "
              :href="user.displayImage"
              target="_blank"
            >
              <img
                :src="user.displayImage"
                class="stars-image-list w-100px"
              />
            </a>
            <svg
              style="max-height: 95px"
              v-else
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="svg-inline--fa fa-user fa-w-14"
            >
              <path
                fill="currentColor"
                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                class="___class_+?20___"
              ></path>
            </svg>
          </td>
          <td>{{ user.type === "admin" ? "Admin" : "User" }}</td>
          <td>
            <a
              v-if="user.reviewCount > 0"
              :href="getPublicUrl('/reviews?user=' + user.uuid)"
              target="_blank"
              class="btn btn-sm btn-primary"
              >{{ user.reviewCount }}</a
            >
            <div v-else>{{ user.reviewCount }}</div>
          </td>
          <td>
            {{ new Date(Number(user.createdAt)).toLocaleString() }}
          </td>
          <td class="p-1">
            <div class="dashboard-action d-flex">
              <a  class="ta-button" v-if="user.type == 'admin'" @click="changeRole(user, 'guest')" title="Make Member.">
								<small>Make member</small>
              </a>
              <a class="ta-button" v-else @click="changeRole(user, 'admin')" title="Make Admin.">
								<small>Make Admin</small>
              </a>

              <a  @click="toggleGiveOwnership(user)" title="Add ownership.">
                <feather-icon icon="UserPlusIcon"
                size="18"/>
              </a>
							<a  @click="toggleActivity(user)" title="View user activities.">
                <feather-icon icon="ActivityIcon"
                size="18"/>
              </a>
              <a  @click="toggleOwnShowsTags(user)" title="View user own items.">
                <feather-icon icon="MenuIcon"
                size="18"/>
              </a>
              <a  @click="toggleFavorites(user)" title="View user favorites.">
                <feather-icon icon="StarIcon"
                size="18"/>
              </a>
            </div>
          </td>
        </tr>
      </tbody>

    </table>
    <br>
    <b-pagination-nav
      v-model="page"
      :link-gen="linkGen"
      :number-of-pages="Math.ceil(count / limit) || 1"
      use-router
    ></b-pagination-nav>
		<show-count :count="count" :limit="limit" :page="page"/>
    <!-- show user activities -->
    <b-modal
      v-model="activities.show"
      ok-only
      ok-title="Close"
      size="xl"
      :title="'User Activities - ' + activities.title"
    >
    <div v-if="!activities.loading  && activities.count > 0" >
      <b-table striped bordered  responsive="sm" :items="activities.activities" :fields="activities.fields" class="text-center">
        <template #cell(name_or_title)="data">
          <span class="text-nowrap">
            <a
              v-if="data.item.show || data.item.tag"
              :href="getPublicUrl(
                  data.item.show
                  ? data.item.show.url
                  : data.item.tag
                  ? data.item.tag.url
                  : null)
              "
              target="_blank" >
              {{ data.item.entityName }}
            </a>
            <span v-else>{{ data.item.entityName }}</span>
          </span>
        </template>

        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ new Date(Number(data.item.createdAt)).toLocaleString() }}
          </span>
        </template>

        <template #cell(details)="data">
          <div @click="toggleActivityCollapse(data.item.id)" class="activity-details text-center" :class="data.item.collapse ? 'activity-details-collapse': ''">
          <p >
            {{ data.item.details }}
          </p>
          <feather-icon v-if="data.item.details" class="icon" icon="ChevronDownIcon"
                size="18"/>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="activities.page"
        @change="getActivities()"
        :total-rows="activities.count"
        :per-page="activities.limit"
        first-number
        last-number
      />
    </div>


    <div v-else class="p-2 text-center">{{ activities.loading ? 'loading...' : 'Empty.!' }}</div>

    </b-modal>
    <!--  show user activities end -->

    <!-- show user favorites -->
    <b-modal
      v-model="favorites.show"
      ok-only
      ok-title="Close"
      size="lg"
      :title="'User Favorites - ' + favorites.title"
    >
    <div v-if="!favorites.loading && favorites.count > 0" >
      <h2 class="text-center" v-if="favorites.favoriteShows.length">Favorite Shows</h2>
      <hr>
      <b-table striped bordered  responsive="sm" :items="favorites.favoriteShows" :fields="favorites.showsFields" class="text-center">
        <template #cell(title)="data">
          <span class="text-nowrap">
            <a
              v-if="data.item.isPublic"
              :href="getPublicUrl(data.item.url)"
              target="_blank" >
              {{ data.item.title }}
            </a>
            <span v-else>{{ data.item.title }}</span>
          </span>
        </template>

        <template #cell(thumbImageUrl)="data">
          <a
            :href="largeImage(data.item.thumbImageUrl)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="show-thumbnail w-200px"
              :src="data.item.thumbImageUrl"
              :alt="data.item.title"
            />
          </a>
        </template>

        <template #cell(youtubeVideoId)="data">
          <div class="dashboard-action d-flex">
            <a  @click="videoPlay(data.item)">
              <feather-icon icon="PlayCircleIcon"
              size="18"/>
            </a>
          </div>

        </template>
      </b-table>
      <br>
      <br>

      <h2 class="text-center"  v-if="favorites.favoriteTags.length">Favorite Stars and Channels</h2>
      <hr>
      <b-table striped bordered  responsive="sm" :items="favorites.favoriteTags" :fields="favorites.tagsFields" class="text-center">
        <template #cell(title)="data">
          <span class="text-nowrap">
            <a
              :href="getPublicUrl(data.item.url)"
              target="_blank" >
              {{ data.item.name }}
            </a>
          </span>
        </template>

        <template #cell(thumbImageUrl)="data">
          <a
            :href="largeImage(data.item.thumbImageUrl)"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              class="show-thumbnail w-200px"
              :src="data.item.thumbImageUrl"
              :alt="data.item.title"
            />
          </a>
        </template>
      </b-table>

      <b-pagination
        v-model="favorites.page"
        @change="getFavorites()"
        :total-rows="favorites.count"
        :per-page="favorites.limit"
        first-number
        last-number
      />
    </div>


    <div v-else class="p-2 text-center">{{ favorites.loading ? 'loading...' : 'Empty.!' }}</div>

    </b-modal>
    <!--  show user favorites end -->

    <!-- show user own items -->
    <b-modal
      v-model="ownShowsTags.show"
      ok-only
      ok-title="Close"
      size="lg"
      :title="'Own items - ' + ownShowsTags.title"
    >
    <div v-if="!ownShowsTags.loading && ownShowsTags.count > 0" >
      <div v-if="ownShowsTags.shows.length">
        <h2 class="text-center">Own Shows</h2>
        <hr>
        <b-table striped bordered  responsive="sm" :items="ownShowsTags.shows" :fields="ownShowsTags.showFields" class="text-center">
          <template #cell(title)="data">
            <span class="text-nowrap">
              <a
                v-if="data.item.isPublic"
                :href="getPublicUrl(data.item.url)"
                target="_blank" >
                {{ data.item.title }}
              </a>
              <span v-else>{{ data.item.title }}</span>
            </span>
          </template>

          <template #cell(thumbImageUrl)="data">
            <a
              :href="largeImage(data.item.thumbImageUrl)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="show-thumbnail w-200px"
                :src="data.item.thumbImageUrl"
                :alt="data.item.title"
              />
            </a>
          </template>

          <template #cell(youtubeVideoId)="data">
            <div class="dashboard-action d-flex">
              <a @click="videoPlay(data.item)">
                <feather-icon icon="PlayCircleIcon"
                size="18"/>
              </a>
            </div>
          </template>

          <template #cell(action)="data">
            <div class="dashboard-action d-flex">
              <a @click="deleteOwnership('show', data.item)" class="trash">
                <feather-icon icon="TrashIcon"
                size="18"/>
              </a>
            </div>
          </template>
        </b-table>
        <br>
        <br>
      </div>

      <div v-if="ownShowsTags.tags.length">
        <h2 class="text-center" >Own Stars and Channels</h2>
        <hr>
        <b-table striped bordered  responsive="sm" :items="ownShowsTags.tags" :fields="ownShowsTags.tagFields" class="text-center">
          <template #cell(title)="data">
            <span class="text-nowrap">
              <a
                :href="getPublicUrl(data.item.url)"
                target="_blank" >
                {{ data.item.name }}
              </a>
            </span>
          </template>

          <template #cell(thumbImageUrl)="data">
            <a
              :href="largeImage(data.item.thumbImageUrl)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="show-thumbnail w-200px"
                :src="data.item.thumbImageUrl"
                :alt="data.item.title"
              />
            </a>
          </template>


          <template #cell(action)="data">
              <div class="dashboard-action d-flex">
                <a @click="deleteOwnership('tag', data.item)" class="trash">
                  <feather-icon icon="TrashIcon"
                  size="18"/>
                </a>
              </div>
            </template>
        </b-table>
      </div>


      <b-pagination
        v-model="ownShowsTags.page"
        @change="getFavorites()"
        :total-rows="ownShowsTags.count"
        :per-page="ownShowsTags.limit"
        first-number
        last-number
      />
    </div>


      <div v-else class="p-2 text-center">{{ ownShowsTags.loading ? 'loading...' : 'Empty.!' }}</div>

    </b-modal>
    <!--  show user own items end -->


    <!-- show giveOwnership-->
    <b-modal
      v-model="giveOwnership.show"
      ok-title="Add"
			@ok="saveOwnership"
      size="sm"
      :title="'Give Ownership to - ' + giveOwnership.title"
    >
		<div class="video-edit-wrapper add-tag-wrapper">
			<div v-if="giveOwnership.errorMessage" class="show-details text-center error">
				{{ giveOwnership.errorMessage }}
			</div>
			<div
				class="show-details text-center"
				v-if="Object.keys(giveOwnership.selectedEntity).length"
			>
				<div class="show-img">
					<img
						class="w-200px m-auto"
						:src="giveOwnership.selectedEntity.thumbImageUrl"
						:alt="giveOwnership.selectedEntity.title || giveOwnership.selectedEntity.name"
					/>
				</div>
				<h4 class="mt-2 mb-3">
					{{ giveOwnership.selectedEntity.title || giveOwnership.selectedEntity.name }}
				</h4>
				<br />
			</div>
			<div class="form-group">
				<label for="title">Select Type</label>
				<select v-model="giveOwnership.entityType" class="form-control">
					<option value="star" selected>Star</option>
					<option value="channel">Channel</option>
					<option value="show">Show</option>
				</select>
			</div>
			<div class="form-group">
				<label for="title">Search</label>
				<input
					type="text"
					v-model="giveOwnership.searchQuery"
					v-on:keyup="debounceSearch"
					class="form-control video-parent"
				/>
				<div v-if="giveOwnership.searchQuery && !giveOwnership.loading">
					<div class="result-show" v-if="giveOwnership.searchResults.length > 0">
						<div
							v-for="result in giveOwnership.searchResults"
							v-bind:key="result.slug"
							@click="selectResult(result)"
							class="d-flex align-items-center mb-1 cursor-pointer"
						>
							<div class="search-result-image-wrapper">
								<img
									class="w-100px"
									:src="result.thumbImageUrl"
									:alt="result.title || result.name"
								/>
							</div>
							<div>
								<h5 class="p-1">{{ result.title || result.name }}</h5>
							</div>
						</div>
					</div>
					<div v-else class="result-show text-center">
						No search result found
					</div>
				</div>
				<div v-else-if="giveOwnership.loading" class="result-show">
					<div class="text-center">Searching....</div>
				</div>
			</div>
		</div>

    </b-modal>
    <!--  show giveOwnership -->


      <!-- show giveOwnership-->
    <b-modal
      v-model="sendMail.show"
      ok-title="Send"
			@ok="validationForm"
      size="sm"
      title="Send Email to Users"
    >
			<!--errorMessage-->
			<div v-if="sendMail.errorMessage" class="show-details text-center error text-danger">
				{{ sendMail.errorMessage }}
			</div>
			<div class="selected-users">
				<span class="chip mr-min" v-for="user in selectedUsers" :key="user.id">{{ user.name }} <feather-icon icon="XCircleIcon" @click="removeSelectedUser(user)" size="15"/></span>
			</div>
			<validation-observer ref="simpleRules">
				<b-form>
					<b-row>

						<!-- subject -->
						<b-col cols="12">
							<b-form-group
								label="subject"
								label-for="subject"
							>
								<validation-provider
									#default="{ errors }"
									name="subject"
									rules="required"
								>
									<b-form-input
										id="subject"
										v-model="sendMail.subject"
										:state="errors.length > 0 ? false:null"
										placeholder="Subject"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- message -->
						<b-col cols="12">
							<b-form-group
								label="Message"
								label-for="message"
							>
								<validation-provider
									#default="{ errors }"
									name="Message"
									rules="required"
								>

									<b-form-textarea
										id="message"
										v-model="sendMail.message"
										type="message"
										:state="errors.length > 0 ? false:null"
										placeholder="Message"
										rows="3"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
    </b-modal>
    <!--  show giveOwnership -->


    <player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>
  </b-card>
</template>

<script>
import allUsers from '@/graphql/query/users/allUsers'
import tagShowChannel from '@/graphql/query/search/tagShowChannel'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'

export default {
  name: 'UsersPage',
  data() {
    return {
      page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 10,
      count: 0,
      users: [],
      loading: false,
      error: null,
      query: '',
      activities: {
        show: false,
        loading: false,
        activities: [],
        count: 0,
        title: '',
        page: 1,
        limit: 20,
        fields: [
          { key: 'name_or_title', label: 'Name/Title' },
          { key: 'entityType', label: 'Type' },
          { key: 'activity', label: 'Activity' },
          { key: 'childSlug', label: 'Episode' },
          { key: 'createdAt', label: 'Date' },
          { key: 'details', label: 'Details' },
        ],
      },
      favorites: {
        show: false,
        loading: false,
        favorites: [],
        favoriteTags: [],
        favoriteShows: [],
        count: 0,
        title: '',
        page: 1,
        limit: 10,
        tagsFields: [
          { key: 'title', label: 'Title' },
          { key: 'type', label: 'Type' },
          { key: 'thumbImageUrl', label: 'Image' },
        ],
        showsFields: [
          { key: 'title', label: 'Title' },
          { key: 'thumbImageUrl', label: 'Image' },
          { key: 'youtubeVideoId', label: 'Vdeo' },
        ],
      },
      ownShowsTags: {
        show: false,
        loading: false,
        tags: [],
        shows: [],
        count: 0,
        title: '',
        page: 1,
        limit: 10,
        showFields: [
          { key: 'title', label: 'Title' },
          { key: 'thumbImageUrl', label: 'Image' },
          { key: 'youtubeVideoId', label: 'Video' },
          { key: 'action', label: 'Action' },
        ],
        tagFields: [
          { key: 'name', label: 'Name' },
          { key: 'type', label: 'Type' },
          { key: 'thumbImageUrl', label: 'Image' },
          { key: 'action', label: 'Action' },
        ],
      },
      selectedShow: {},
      showVideoPlayer: false,
      giveOwnership: {
        show: false,
        loading: false,
				title: '',
        searchQuery: '',
        entityType: 'star',
        searchResults: [],
				selectedEntity: {},
				errorMessage: '',
				user: {},
      },
      sendMail: {
        show: false,
        loading: false,
        errorMessage: '',
				subject: '',
				message: '',
      },
    }
  },
	components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
		BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.getUsers()
  },
  watch: {
    page() {
      this.getUsers()
    },

  },
  computed: {
    selectedUsers() {
      return this.users.filter((user) => user.selected);
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    debounceInput: debounce(function (e) {
      this.getUsers(true)
    }, 500),
    debounceSearch: debounce(function (e) {
      this.search()
    }, 500),
    videoPlay(show = {}) {
      this.selectedShow = show
      this.showVideoPlayer = show.id ? true : false
    },
    getUsers(pageReset) {
			if (pageReset) {
				this.page = 1
			}
      this.loading = true
      axios.post('/graphql', {
        operationName: '',
        query: allUsers,
        variables: {
          first: this.limit,
          skip: (this.page - 1) * this.limit,
          query: this.query,
        },
      }).then((response) => {
        this.loading = false
        this.users = response.data.data.users?.rows
        this.count = response.data.data.users?.count
      })
    },
    toggleActivity(user = null) {
      this.activities.show = !this.activities.show
      this.activities.title = user?.name
      this.activities.user = user
      this.activities.activities = []
      if(user && this.activities.show) {
        this.getActivities()
      }
    },
    getActivities() {
      this.activities.loading = true
      const skip = (this.activities.page - 1) * this.activities.limit
      this.$store.dispatch('users/getUserActivities', {first: this.activities.limit, skip: skip, users:[ this.activities.user.id ], success : (activities, count) => {
        this.activities.activities = activities
        this.activities.count = count

        this.activities.loading = false
      }, error: this.errorToast})
    },
    toggleActivityCollapse(id) {
      this.activities.activities = this.activities.activities.map(activity => {
        if(activity.id === id) {
          activity.collapse = activity.collapse ? false : true
        }
        return activity
      })
  },
    toggleFavorites(user = null) {
      this.favorites.show = !this.favorites.show
      this.favorites.title = user?.name
      this.favorites.user = user
      this.favorites.favoriteTags = this.favorites.favoriteShows = []
      if(user && this.favorites.show) {
        this.getFavorites()
      }
    },
    getFavorites() {
      this.favorites.loading = true
      this.$store.dispatch('users/getUserFavorites', {userId: this.favorites.user.id, success : ({favoriteShows, favoriteTags, count}) => {
        this.favorites.favoriteShows = favoriteShows
        this.favorites.favoriteTags = favoriteTags
        this.favorites.count = count

        this.favorites.loading = false
      }, error: this.errorToast})
    },
    toggleOwnShowsTags(user = null) {
      this.ownShowsTags.show = !this.ownShowsTags.show
      this.ownShowsTags.title = user?.name
      this.ownShowsTags.user = user
      this.ownShowsTags.tags = this.ownShowsTags.shows = []
      if(user && this.ownShowsTags.show) {
        this.getOwnShowsTags()
      }
    },
    getOwnShowsTags() {
      this.ownShowsTags.loading = true
      const skip = (this.ownShowsTags.page - 1) * this.ownShowsTags.limit
      this.$store.dispatch('users/getUserOwnShowsTags', {first: this.ownShowsTags.limit, skip: skip, userId: this.ownShowsTags.user.id, success : ({tags, shows, count}) => {
        this.ownShowsTags.tags = tags
        this.ownShowsTags.shows = shows
        this.ownShowsTags.count = count

        this.ownShowsTags.loading = false
      }, error: this.errorToast})
    },
    async deleteOwnership(type, entity) {

      this.confirmDialog({
        text: "Yes, remove ownership."
      }).then(value => {
        if (value) {
          this.deleteOwnershipQ(type, entity);
        }
      });
    },
    async deleteOwnershipQ(type, entity) {
      let query = `mutation($type: String!, $entityId: ID!){
        deleteOwnership(type: $type, entityId: $entityId)
        }`;
      let variables = { type, entityId: entity.id };
      let response = await axios.post( '/graphql',{
        query,
        variables,
        operationName: "",
      });
      if (response.data.data && response.data.data.deleteOwnership) {
        if (type === "tag") {
          this.ownShowsTags.tags.splice(this.ownShowsTags.tags.indexOf(entity), 1);
        }
        if (type === "show") {
          this.ownShowsTags.shows.splice(this.ownShowsTags.shows.indexOf(entity), 1);
        }
        this.successToast("The ownership is deleted");
      } else {
        this.errorToast(response.data.errors[0].message);
      }
    },
    changeRole(user, type) {
      this.confirmDialog({
        text: `Yes, change user role to ${type}.`
      }).then(value => {
        if (value) {
          this.changeRoleQ(user, type);
        }
      });
    },

		toggleGiveOwnership(user = null) {
			this.giveOwnership.show = !this.giveOwnership.show
			this.giveOwnership.title = user?.name
			this.giveOwnership.user = user
		},
    async changeRoleQ(user, type) {
      let query = `mutation($user: UserUpdate!, $userId: ID){
                userUpdate(user: $user, userId: $userId){
                  id
                  type
                }
            }`;
      let variables = { user: { type }, userId: user.id };
      let response = await axios.post( '/graphql',{
        query,
        variables,
        operationName: "",
      });
      if (response.data.data && response.data.data?.userUpdate) {
        user.type = response.data.data.userUpdate.type;
        this.successToast("The role is updated");
      } else {
        this.errorToast(response.data.errors[0].message);
      }
    },
    async search() {
			if (this.giveOwnership.searchQuery.trim()) {
        this.giveOwnership.searchResults = [];
				this.giveOwnership.loading = true;
        try {
          let variables = { type: this.giveOwnership.entityType, query: this.giveOwnership.searchQuery };
          let response = await axios.post("/graphql", {
            operationName: "",
            query: tagShowChannel,
            variables,
          });
					this.giveOwnership.loading = false;

          if (response.data.data && response.data.data.search) {
            this.giveOwnership.searchResults = response.data.data.search;
          } else {
            this.errorToast(response.data.errors[0].message);
          }
        } catch (error) {
					this.giveOwnership.loading = false;
          this.errorToast(error.message);
        }
      }
    },
		selectResult(item) {
			this.giveOwnership.selectedEntity = item;
			this.giveOwnership.searchQuery = '';
		},
		async saveOwnership() {
      if (!this.giveOwnership.selectedEntity.id) {
        this.errorToast("Please selcet any show, star or channel");
        return;
      }
      let query = `mutation($entityType: String!, $entityId: ID!, $userId: ID!){
                   giveOwnership(entityType: $entityType, entityId: $entityId, userId: $userId)
                  }`;
			const ownershipInput = {}
			ownershipInput.entityType =
        this.giveOwnership.entityType  === "star" || this.giveOwnership.entityType  === "channel"
          ? "tag"
          : "show";
      ownershipInput.entityId = this.giveOwnership.selectedEntity.id;
      ownershipInput.userId = this.giveOwnership.user.id;
      let variables = ownershipInput;
      let response = await axios.post( '/graphql',{
        query,
        variables,
        operationName: "",
      });
      console.log("respnse", response);
      if (response.data.data && response.data.data.giveOwnership) {
        this.successToast("The ownership is saved");
      } else {
        this.errorToast(response.data.errors[0].message);
      }
    },
    sendMailToggle() {
      this.sendMail.show = !this.sendMail.show
    },
    removeSelectedUser(user) {
      user.selected = false;
    },
		validationForm(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.sendEmail()
        }
      })
		},
		async sendEmail() {
      let userIds = this.selectedUsers.map((user) => user.id);
      if (!userIds.length) {
        this.sendMail.errorMessage = "No user selected";
        return;
      }
			// check validation


      let response = await this.sendEmailQ(userIds);
      if (response.data.data && response.data.data.emailToUsers) {
        this.successToast("Email send successfully");
        this.selectedUsers.forEach((user) => {
          user.selected = false;
        });
				this.sendMail.show = false;
				this.sendMail.subject = '';
				this.sendMail.message = '';
      } else {
        this.errorToast(response.data.errors[0].message);
      }
    },
    async sendEmailQ(userIds) {
      let query = `mutation($ids: [ID!], $subject: String!, $message: String!){
         emailToUsers(ids: $ids, subject: $subject, message: $message)
     }`;
      let variables = {
        ids: userIds,
        subject: this.sendMail.subject,
        message: this.sendMail.message,
      };
      return await axios.post("/graphql", {
        query,
        variables,
        operationName: "",
      });
    },
  },
}

</script>
