<template>
	<div>
		<b-table
			v-if="!reports.loading && reports.count"
			responsive="sm"
			:items="reports.rows"
			:fields="fields"
			class="text-center"
			striped
			bordered
		>

			<template v-slot:cell(title)="data">
				<b-link :href="getPublicUrl(data.item.showstag.show.url)" target="__blank">{{ data.item.showstag.show.title }}</b-link>
			</template>

			<template v-slot:cell(tag-alias)="data">

				<span v-if="data.item.showstag.alias">{{ data.item.showstag.alias.aliasName }}</span>
				<span v-if="data.item.showstag.tag"><a target="__black" :href="getPublicUrl(data.item.showstag.tag.url)">{{ data.item.showstag.tag.name }}</a></span>
			</template>

			<template v-slot:cell(action)="data">
				<div class="dashboard-action d-flex m-auto">
					<a @click="approveReport(data.item)" title="approve">
						<feather-icon icon="CheckCircleIcon"
						size="18"/>
					</a>
					<a @click="rejectSuggestion(data.item)" title="reject" class="trash">
						<feather-icon icon="XCircleIcon"
						size="18"/>
					</a>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="reports.loading">loading...</span>
			<span v-else>No suggestion found</span>
		</div>

		<br>
		<div>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(reports.count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="reports.count" :limit="limit" :page="page"/>
		</div>
	</div>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BInputGroup, } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			page: Number(this.$route.query.page) || 1,
			limit: Number(this.$route.query.limit) || 20,
			fields: [
				{ key: 'title', label: 'Show Title' },
				{ key: 'tag-alias', label: 'Tag/Alias' },
				{ key: 'type', label: 'Type' },
				{ key: 'name', label: 'Name' },
				{ key: 'value', label: 'Value' },
				{ key: 'user.name', label: 'Report by' },
				{ key: 'action', label: 'Action' },
			],
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		...mapGetters('suggestions', ['reports']),
	},
	watch: {
		page() {
			this.loadData()
		},
	},
	methods: {
		linkGen(page) {
			return {
				name: 'reportSuggestions',
				query: {
					page,
					limit: this.limit,
				},
			}
		},
		loadData() {
			this.$store.dispatch('suggestions/fetchReports', {
				page: this.page,
				limit: this.limit,
			})
		},
		approveReport(report) {
			this.confirmDialog().then((value) => {
				if (value) {
					this.$store.dispatch('suggestions/approveReport', {input: report, success: this.successToast, error: this.errorToast})
				}
			})
		},
		rejectSuggestion(report) {
			this.confirmDialog().then((value) => {
				if (value) {
					this.$store.dispatch('suggestions/rejectReport', {input: report, success: this.successToast, error: this.errorToast})
				}
			})
		},
	},


}


</script>
