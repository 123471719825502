<template>
  <b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white">Suggestions</h3>
		</template>
		<br>
		<b-nav tabs fill>
			<b-nav-item :active="routeName == 'showSuggestions'" to="/suggestions" >
				<span class="mr-1">Shows</span> <b-badge v-if="showInfoSuggNotification" variant="danger"> {{ showInfoSuggNotification }}</b-badge>
			</b-nav-item>
			<b-nav-item :active="routeName == 'starSuggestions'" to="/suggestions/stars" >
				<span class="mr-1">Stars</span> <b-badge v-if="newStarSuggNotification" variant="danger"> {{ newStarSuggNotification }}</b-badge>
			</b-nav-item>
			<!-- <b-nav-item :active="routeName == 'channelSuggestions'" to="/suggestions/channels" >
				<span class="mr-1">Channels</span> <b-badge v-if="newChannelSuggestion" variant="danger"> {{ newChannelSuggestion }}</b-badge>
			</b-nav-item> -->
			<b-nav-item :active="routeName == 'starChannelSuggestions'" to="/suggestions/star-channel" >
				<span class="mr-1">Star & Channels</span> <b-badge v-if="starChannelInfoSuggNotification" variant="danger"> {{ starChannelInfoSuggNotification }}</b-badge>
			</b-nav-item>
			<b-nav-item :active="routeName == 'videoSuggestions'" to="/suggestions/videos" >
				<span class="mr-1">Vidoes</span> <b-badge v-if="videoSuggestion" variant="danger"> {{ videoSuggestion }}</b-badge>
			</b-nav-item>
			<b-nav-item :active="routeName == 'reportSuggestions'" to="/suggestions/reports" >
				<span class="mr-1">Reports</span> <b-badge v-if="reportsCount" variant="danger"> {{ reportsCount }}</b-badge>
			</b-nav-item>
		</b-nav>
		<template>
			<router-view></router-view>
		</template>
		<br>
  </b-card>
</template>

<script>
import { BCard, BNav, BNavItem } from 'bootstrap-vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
		BNav,
		BNavItem
  },

	computed: {
		...mapGetters('notifications', [
			'newShowSuggNotification',
			'newStarSuggNotification',
			'showInfoSuggNotification',
			'starChannelInfoSuggNotification',
			'requestedChannel',
			'videoSuggestion',
			'newChannelSuggestion',
			'reportsCount',
			'allNotifications'
		]),
    routeName() {
			return this.$route.name
		},
	},
}
</script>

<style>
.dashboard-action {
	align-items: center;
	justify-content: center;
}
.text-truncate {
	max-width: 120px;
}
.tag-thumbnail,
.tag-image-prev {
	max-width: 130px;
}
.video-img img {
	height: 100%;
	width: 100%;
}
.video-details-play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #00000082;
	text-align: center;
	color: white;
	border-radius: 10px;
	transform: translate(-50%, -50%);
	padding: 0.9375rem 1.25rem;
}
.video-details-play-icon:hover {
	color: white;
}
.video-details-play-icon {
	background-color: #464680;
}
</style>
