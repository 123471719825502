<template>
	<div>

		<b-button variant="success" @click="showForm">ADD SHOW</b-button>
    <!-- add show-->
    <b-modal
      v-model="showAddShowModal"
      ok-title="Add"
			@ok="validateForm"
      size="lg"
      title="Add Show"
    >
			<!--errorMessage-->
			<div v-if="errorMessage" class="show-details text-center error text-danger">
				{{ errorMessage }}
			</div>
			<validation-observer ref="showFormRole">
				<b-form>
					<b-row>
						<b-col cols="6">
							<b-form-group
								label="English Title"
								label-for="titleEN"
							>
								<b-form-input
									id="titleEN"
									v-model.trim="showInput.titleEn"
									placeholder="English Title"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group
								label="Bangla Title"
								label-for="titleBn"
							>
								<b-form-input
									id="titleBn"
									v-model="showInput.titleBn"
									placeholder="Bangla Title"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="12">
							<b-form-group
								label="Type"
							>
								<b-form-select
									v-model="showInput.type"
									:options="['youtube', 'others']"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="12">
							<validation-provider
									#default="{ errors }"
									name="Youtube Video"
									:rules="`${showInput.type == 'youtube' ? 'required' : ''}`"
								>
									<b-form-group
										label="Youtube Video"
									>
										<b-form-input
											v-model.trim="youtubeVideo"
											:state="errors.length > 0 ? false:null"
											placeholder="Youtube Video Link/ID"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
						</b-col>
						<b-col cols="12">
							<validation-provider
									#default="{ errors }"
									name="Youtube Trailer"
								>
									<b-form-group
										label="Youtube Trailer"
									>
										<b-form-input
											v-model.trim="trailerVideo"
											:state="errors.length > 0 ? false:null"
											placeholder="Youtube Trailer Link/ID"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
						</b-col>
						<b-col cols="12" v-if="showInput.type != 'youtube'">
							<validation-provider
									#default="{ errors }"
									name="External Link"
								>
									<b-form-group
										label="External Link"
									>
										<b-form-input
											v-model.trim="showInput.videoLink"
											:state="errors.length > 0 ? false:null"
											placeholder="External Link"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</b-form-group>
								</validation-provider>
						</b-col>

						<b-col cols="12">
							<b-form-group
								label="Released Year"
							>
								<b-form-input
									v-model.number="showInput.releasedYear"
									placeholder="Released Year"
									type="number"
								/>
							</b-form-group>
						</b-col>

						<b-col cols="12">
							<b-form-group
								label="Story Plot"
							>
								<b-form-textarea
									v-model.trim="showInput.storyPlot"
									placeholder="Story Plot"
								/>
							</b-form-group>
						</b-col>

						<b-col cols="12">
							<div class="form-group">
								<label for="imageUrl">Thumbnail</label>
								<input type="file" class="form-control tag-image" id="imageUrl"
										v-on:change="handleChange" :input="selectedFile" />
								<div class="text-center">
										<img v-if="imageUrl" :src="imageUrl" class="stars-image pt-2" />
								</div>
							</div>
						</b-col>

					</b-row>
				</b-form>
			</validation-observer>
    </b-modal>
    <!-- add show end -->
	</div>
</template>

<script>
import rows from '@/graphql/query/shows/rows.js'

import { mapGetters } from 'vuex'
const validLInkRegx = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;



export default {
	data() {
		return {
			showAddShowModal: false,
			errorMessage: '',
			showInput: {
				releasedYear: null,
				type: "youtube",
				trailerVideoId: "",
				title: "",
				titleBn: "",
				titleEn: "",
				videoLink: "",
				storyPlot: "",
			},
			youtubeVideo: null,
			trailerVideo: null,
			imageUrl: null,
			selectedFile: null,
		}
	},
	computed: {
    ...mapGetters('shows', ['shows']),
	},
	methods: {
		showForm() {
			this.showAddShowModal = true;
			this.resetForm()
		},
		validateForm(e) {
			e.preventDefault();
			this.$refs.showFormRole.validate().then((success) => {
				if (success) {
					if(this.validate()) {
						if (this.selectedFile) {
									const reader = new FileReader();
									reader.readAsDataURL(this.selectedFile);
									reader.onload = async () => {
											this.showInput.thumbImageUrlLg = await reader.result;
											this.showCreate();
									};
							} else {
									this.showCreate();
							}
						}
					}
			});
		},

		showCreate() {
			if (this.youtubeVideo.length > 11) {
					const match = this.youtubeVideo.match(validLInkRegx);
					this.showInput.youtubeVideoId =
							match && match[7].length == 11 ? match[7] : false;
			} else {
					this.showInput.youtubeVideoId = this.youtubeVideo;
			}

			if (this.trailerVideo && this.trailerVideo.length >= 11) {
					if (this.trailerVideo.length > 11) {
							const match = this.trailerVideo.match(validLInkRegx);
							this.showInput.trailerVideoId =
									match && match[7].length == 11 ? match[7] : false;
					} else {
							this.showInput.trailerVideoId = this.trailerVideo;
					}
					if (!this.youtubeVideo && this.showInput.trailerVideoId) {
							this.showInput.youtubeVideoId = this.showInput.trailerVideoId;
					}
			}
			if (this.showInput.titleEn || this.showInput.titleBn) {
					this.showInput.title = this.showInput.titleEn + (this.showInput.titleBn && " | " + this.showInput.titleBn);
			}
			let titleBn = this.showInput.titleBn;
			let titleEn = this.showInput.titleEn;
			delete this.showInput.titleEn;
			delete this.showInput.titleBn;
			axios
					.post("/graphql", {
							operationName: "",
							query: `
							mutation($input: ShowInput!) {
									showCreate(input: $input) {
											${rows}
											episodes{
                        id
                        slug
                        showId
                        youtubeVideoId
                        videoEmbeddable
                        thumbImageUrl
                        thumbImageUrlLg
                        duration
                        videoPublishedAt
                        externalLink
                        isActive
                        isPublic
                    	}
									}
							}
							`,
							variables: { input: this.showInput }
					})
					.then(response => {
							if (response.data.data.showCreate) {
									let episodeAddedShow = this.shows.find(
											show => show.id === response.data.data.showCreate.id
									);
									if (episodeAddedShow) {
											// commit episode to store
											this.$store.commit("shows/addEpisodes", {
													show: response.data.data.showCreate
											});
											this.successToast(
													`The Video has been suggested to the show ${
															response.data.data.showCreate.title
													}`
											);
									} else {
											let showData = response.data.data.showCreate;
											//emit event to parent component
											this.$emit("showAdded", showData);
											this.resetForm();
											this.showAddShowModal = false;
									}
							} else {
								this.showInput.titleBn = titleBn;
								this.showInput.titleEn = titleEn;
								this.errorDialog(response.data.errors[0].message);
							}
					});
		},
		handleChange(e) {
			this.imageUrl = ''

			this.selectedFile = e.target.files[0];
			if(this.selectedFile) {
					this.imageUrl = URL.createObjectURL(this.selectedFile);
			} else {
					this.imageUrl = ''
			}
		},
		resetForm() {
			this.showInput = {
				releasedYear: null,
				type: "youtube",
				trailerVideoId: "",
				title: "",
				titleBn: "",
				titleEn: "",
				videoLink: "",
				storyPlot: "",
			}
			this.youtubeVideo = ''
			this.imageUrl = null
			this.selectedFile = null
		},
		validate() {
			if(this.youtubeVideo || this.trailerVideo) {
				return true
			} else if( this.showInput.titleEn && this.showInput.releasedYear && this.selectedFile) {
				return true
			}

			this.errorDialog('YouTube Video or title, release year and image fields are required.', {title:'Please fill all the required fields' });
			return false;
		},

	},
}
</script>
