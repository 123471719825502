import profiles from '../../graphql/query/websites/profile'

export default {
    namespaced: true,
    state: {
        files: [],
        loading: false,
        count: 0,
        page: 1,
				trash: false,
				type: 'all',
    },
    getters: {
			files: state => state.files,
			count: state => state.count,
			loading: state => state.loading,
			selectedFiles: state => state.files.filter(file => file.selected),
    },
    mutations: {
      setFiles (state, {rows, count, page}) {
				state.files = rows || []
				state.count = count || 0
				state.page = page || 1
				state.loading = false
			},
			setLoading (state, loading) {
				state.loading = loading
			},
			toggleSelect(state, file) {
				state.files = state.files.map(f => {
					if (f.id === file.id) {
						f.selected = !f.selected
					}
					return f
				})
			},
			deleteFiles(state, ids) {
				state.files = state.files.filter(f => !ids.includes(f.id))
			}
    },
    actions: {
			async getFiles({commit, state}, {query = null, page = 1, limit = 20, trash = false, type = ''}) {
				commit('setLoading', true)
				const skip = (page - 1) * limit;
      	axios
        .post("/graphql", {
          operationName: "",
          query: `
						query($query: String,$skip: Int, $first: Int, $type: String, $trash: Boolean) {
								files(query : $query, skip: $skip, first: $first, type: $type, trash: $trash) {
										count
										rows {
												id
												type
												userId
												s3Key
												imageUrl
												entityType
												entityId
												createdBy{
														name
												}
												show{
														title
														url
														thumbImageUrl
												}
												tag{
														name
														slug
														url
														type
														thumbImageUrl
														displayImageUrl
												}
												user{
														name
														email
														createdAt
														displayImage
												}
												website {
													name
													domain
													image
												}
												createdAt
												updatedAt
												trashedAt
												deletedAt
										}

								}
						}
						`,
          variables: {"query": query, "skip": skip, "first": limit, "type": type, "trash": trash},
        })
        .then((response) => {
          if (response.data.data.files) {
						commit('setFiles', {rows: response.data.data.files.rows, count: response.data.data.files.count, page})
          }
        })
				.catch((error) => {
					console.log(error)
				})
				.finally(() => {
					commit('setLoading', false)
				})
			},
    },
  }
