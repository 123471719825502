<template>
	<div>
		<facebook-icon v-if="icon == 'facebook'" size="25" class="brand-icon"></facebook-icon>
		<twitter-icon v-if="icon == 'twitter'" size="25" class="brand-icon"></twitter-icon>
		<instagram-icon v-if="icon == 'instagram'" size="25" class="brand-icon"></instagram-icon>
		<linkedin-icon v-if="icon == 'linkedin'" size="25" class="brand-icon"></linkedin-icon>
		<you-tube-icon v-if="icon == 'youtube'" size="25" class="brand-icon"></you-tube-icon>
		<snapchat-icon v-if="icon == 'snapchat'" size="25" class="brand-icon"></snapchat-icon>
		<wikipedia-icon v-if="icon == 'wikipedia' || icon == 'wikipedia-w'" size="25" class="brand-icon"></wikipedia-icon>
	</div>
</template>

<script>
	import { FacebookIcon, TwitterIcon, InstagramIcon, LinkedInIcon, SnapchatIcon, WikipediaIcon, YouTubeIcon } from 'vue-simple-icons'
	export default {
		components: {
			FacebookIcon,
			TwitterIcon,
			InstagramIcon,
			LinkedInIcon,
			SnapchatIcon,
			WikipediaIcon,
			YouTubeIcon
		},
		props: {
			icon: {
				type: String,
				required: true,
			},
		},

	}
</script>
