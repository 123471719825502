<template>
	<b-modal
		v-model="showModal"
		:ok-title="approve ? 'Approve' : 'Update'"
		@ok="validateForm"
		size="lg"
		:title="approve ? 'Approve Show' : 'Update Show'"
	>
		<!--Reload Details Button-->
		<div class="overflow-hidden">
			<b-button
				variant="outline-primary"
				class="float-right"
				@click="reloadDetails(showUpdateInput.youtubeVideoId)"
			>
				Reload Details
			</b-button>
		</div>
		<!--errorMessage-->
		<div v-if="errorMessage" class="show-details text-center error text-danger">
			{{ errorMessage }}
		</div>

		<validation-observer ref="showFormRole">
			<b-row>
				<b-col cols="6">
					<validation-provider
						#default="{ errors }"
						name="English Title"
						rules="required"
					>
						<b-form-group label="English Title" label-for="titleEN">
							<b-form-input
								@blur="makeSlug"
								id="titleEN"
								v-model.trim="showUpdateInput.titleEn"
								placeholder="English Title"
							/>
						</b-form-group>
						<div v-if="errors[0]" class="text-danger">
							{{ errors[0] }}
						</div>
					</validation-provider>
				</b-col>
				<b-col cols="6">
					<validation-provider
						#default="{ errors }"
						name="Bangla Title"
						rules=""
					>
						<b-form-group label="Bangla Title" label-for="titleBn">
							<b-form-input
								@blur="makeSlug"
								id="titleBn"
								v-model="showUpdateInput.titleBn"
								placeholder="Bangla Title"
							/>
						</b-form-group>
						<div v-if="errors[0]" class="text-danger">
							{{ errors[0] }}
						</div>
					</validation-provider>
				</b-col>
				<b-col cols="12">
					<validation-provider
						#default="{ errors }"
						name="Slug"
						rules="required|alpha-dash"
					>
						<b-form-group label="Slug" label-for="slug">
							<b-form-input
								id="slug"
								v-model="showUpdateInput.slug"
								placeholder="Slug"
							/>
						</b-form-group>
						<div v-if="errors[0]" class="text-danger">
							{{ errors[0] }}
						</div>
					</validation-provider>
				</b-col>
				<b-col cols="12">
					<validation-provider
						#default="{ errors }"
						name="Released Year"
						rules="required|integer"
					>
						<b-form-group label="Released Year" label-for="releasedYear">
							<b-form-input
								id="releasedYear"
								v-model="showUpdateInput.releasedYear"
								placeholder="Released Year"
								type="number"
							/>
						</b-form-group>
						<div v-if="errors[0]" class="text-danger">
							{{ errors[0] }}
						</div>
					</validation-provider>
				</b-col>
				<b-col cols="12">
					<validation-provider #default="{ errors }" name="Duration">
						<b-form-group label="Duration" label-for="duration">
							<b-form-input
								id="duration"
								v-model="showUpdateInput.duration"
								placeholder="duration"
							/>
						</b-form-group>
						<div v-if="errors[0]" class="text-danger">
							{{ errors[0] }}
						</div>
					</validation-provider>
				</b-col>
				<b-col cols="12">
					<label for="videoPublishedAt">Video Published At</label>
					<input
						v-if="showUpdateInput.videoPublishedAt"
						type="date"
						class="form-control video-title"
						:value="
							showUpdateInput.videoPublishedAt &&
							showUpdateInput.videoPublishedAt.split('T')[0]
						"
						@input="inputDate"
					/>
					<input
						v-else
						type="date"
						class="form-control video-title"
						value="null"
						@input="inputDate"
					/>
				</b-col>
				<b-col cols="12" class="mt-1" v-if="showUpdateInput.origin != 'others'">
					<validation-provider #default="{ errors }" name="Youtube Video ID">
						<b-form-group label="Youtube Video ID" label-for="youtubeVideoId">
							<b-input-group>
								<b-form-input
									id="youtubeVideoId"
									v-model="showUpdateInput.youtubeVideoId"
									placeholder="Youtube Video ID"
								/>
								<b-input-group-append is-text>
									<feather-icon
										icon="CopyIcon"
										class="cursor-pointer"
										@click="copyToClipboard(showUpdateInput.youtubeVideoId)"
									/>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
						<div v-if="errors[0]" class="text-danger">
							{{ errors[0] }}
						</div>
					</validation-provider>
				</b-col>

				<!--YouTube Channel-->
				<b-col cols="12" class="mt-1">
					<b-form-group label="Youtube Channel" label-for="youtubeChannel">
						<div
							class="selected-tag chips"
							v-if="Object.keys(selectedChanel).length != 0"
						>
							<p data-role="tagsinput" class="badge badge-success">
								{{ selectedChanel.name }}
								<span v-if="selectedChanel.slug != 'unknown-channel'">
									<feather-icon
										icon="XIcon"
										class="cursor-pointer"
										@click="removeSelectedChannel()"
									/>
								</span>
							</p>
						</div>
						<vue-bootstrap-typeahead
							@hit="selectChannel"
							ref="channelInput"
							:serializer="(channel) => channel.tag.name"
							:data="allChannels"
						>
						</vue-bootstrap-typeahead>
					</b-form-group>
				</b-col>

				<!--Video Embadable-->
				<b-col cols="12" class="mt-1">
					<label for="videoEmbeddable">Video Embeddable</label>
					<input
						type="checkbox"
						v-model="showUpdateInput.videoEmbeddable"
						class="ml-3"
						id="videoEmbeddable"
					/>
				</b-col>

				<!--Types-->
				<b-col cols="12" class="mt-1">
					<b-form-group label="Type" label-for="type">
						<b-form-select
							id="type"
							v-model="showUpdateInput.type"
							:options="types"
							text-field="title"
							value-field="value"
						/>
						<div
							class="form-group mt-1"
							v-if="customCheck(showUpdateInput.type)"
						>
							<input
								type="text"
								v-model.trim="typeCustomInput"
								class="form-control video-title"
								id="typeCustomInput"
							/>
						</div>
					</b-form-group>
				</b-col>

				<!--Video Origin-->
				<b-col cols="12" class="mt-1">
					<b-form-group label="Video Origin" label-for="videoOrigin">
						<b-form-select
							id="videoOrigin"
							v-model="showUpdateInput.origin"
							:options="[
								{ text: 'Youtube', value: 'youtube' },
								{ text: 'Others', value: 'others' },
							]"
						/>
					</b-form-group>
				</b-col>

				<!--External Link-->
				<b-col cols="12" class="mt-1" v-if="showUpdateInput.origin == 'others'">
					<validation-provider v-slot="{ errors }" name="External Link">
						<b-form-group label="External Link" label-for="videoLink">
							<b-input-group>
								<b-form-input
									id="videoLink"
									v-model="showUpdateInput.videoLink"
									placeholder="External Link"
								/>
								<b-input-group-append is-text>
									<feather-icon
										icon="CopyIcon"
										class="cursor-pointer"
										@click="
											copyToClipboard(showUpdateInput.videoLink, 'Link Copied')
										"
									/>
								</b-input-group-append>
							</b-input-group>
							<span class="text-danger">{{ errors[0] }}</span>
						</b-form-group>
					</validation-provider>
				</b-col>

				<!--Trailer Video-->
				<b-col cols="6" class="mt-1">
					<validation-provider
						rules=""
						v-slot="{ errors }"
						name="Trailer Video ID"
					>
						<b-form-group label="Trailer Video ID" label-for="trailerVideoId">
							<b-input-group>
								<b-form-input
									id="trailerVideoId"
									v-model="showUpdateInput.trailerVideoId"
									placeholder="Trailer Video ID"
								/>
								<b-input-group-append is-text>
									<feather-icon
										icon="CopyIcon"
										class="cursor-pointer"
										@click="
											copyToClipboard(
												showUpdateInput.trailerVideoId,
												'Trailer Video ID Copied'
											)
										"
									/>
								</b-input-group-append>
							</b-input-group>
							<span class="text-danger">{{ errors[0] }}</span>
						</b-form-group>
					</validation-provider>
				</b-col>

				<!-- Trailer Embadable-->
				<b-col cols="6" class="mt-1">
					<label for="trailerVideoEmbeddable">Trailer Embeddable</label>
					<input
						type="checkbox"
						v-model="showUpdateInput.trailerVideoEmbeddable"
						class="ml-3"
						id="trailerVideoEmbeddable"
					/>
				</b-col>

				<b-col cols="12" class="mt-1">
					<div class="form-group">
						<label for="asTrailer">As Trailer</label>
						<input
							type="checkbox"
							v-model="showUpdateInput.isTrailer"
							class="ml-3"
							id="asTrailer"
						/>
					</div>
				</b-col>
				<b-col cols="12" class="mt-1">
					<div class="form-group">
						<label for="autoApproveEpisode">Auto Approve Episodes</label>
						<input
							type="checkbox"
							v-model="showUpdateInput.autoApproveEpisode"
							class="ml-3"
							id="autoApproveEpisode"
						/>
					</div>
				</b-col>
				<b-col cols="12" class="mt-1">
					<label for="description">Description</label>
					<textarea
						rows="8"
						class="form-control"
						id="description"
						v-model.trim="showUpdateInput.description"
						multiline="{true}"
					></textarea>
				</b-col>
				<b-col cols="12" class="mt-1">
					<label for="story-plot">Story Plot</label>
					<textarea
						rows="8"
						class="form-control"
						id="story-plot"
						v-model.trim="showUpdateInput.storyPlot"
						multiline="{true}"
					></textarea>
				</b-col>

				<b-col cols="12" class="mt-1">
					<a v-if="getSuggLoading" class="btn btn-success load-suggestion"
						>Loading...</a
					>
					<a
						v-else
						class="btn btn-success load-suggestion"
						@click="getTagSuggestions(selectedShow.id)"
					>
						Get Tag Suggestions</a
					>
				</b-col>
				<b-col cols="12">
					<div class="row">
						<div class="col">
							<div class="tag-wrapper form-group">
								<div class="tag-list">
									<h5>Tag List:</h5>
									<p
										v-for="tag in selectedShowTextTags"
										:key="tag.name || tag.aliasName"
										data-role="tagsinput"
										class="badge badge-success"
										:class="tag.thumbImageUrl ? 'badge-has-img' : ''"
									>
										<a
											class="position-static mr-1 text-white"
											:href="tag.url ? getPublicUrl(tag.url) : null"
											target="__blank"
										>
											<img
												v-if="tag.thumbImageUrl"
												class="badge-img"
												:src="tag.thumbImageUrl + '-25x25.jpg'"
												v-b-tooltip.html.hover.bottom="
													tag.thumbImageUrl
														? '<img src=' +
														  tag.thumbImageUrl +
														  '-180x180.jpg  />'
														: 'Image not found'
												"
											/>
											{{ tag.name || tag.aliasName }}
										</a>
										<span>
											<feather-icon
												icon="XIcon"
												@click="removeTextTagItem(tag)"
											/>
										</span>
									</p>
								</div>
								<label for="tag">Tag</label>
								<vue-bootstrap-typeahead
									@hit="selectTextItem"
									ref="textInput"
									:serializer="(tag) => tag.name"
									:data="allTextTags"
									v-model="text_tag_input"
								>
									<template slot="append">
										<button
											type="button"
											@click="selectTextItem({ name: text_tag_input }, true)"
											class="btn btn-success"
										>
											Add
										</button>
									</template>
								</vue-bootstrap-typeahead>
							</div>
						</div>
						<div class="col">
							<div
								class="tag-wrapper form-group"
								:class="showStarSectionActive ? 'active-input' : ''"
							>
								<drop
									@drop="handleDrop('star', ...arguments)"
									@dragover="showStarSectionActive = true"
									@dragleave="showStarSectionActive = false"
								>
									<div class="tag-list">
										<h5>Star List:</h5>
										<drag
											v-for="tag in selectedShowStars"
											class="drag"
											:transfer-data="{ item: tag, list: 'star' }"
											:key="'star' + tag.id + tag.name + tag.aliasName"
										>
											<p
												data-role="tagsinput"
												class="badge badge-success"
												:class="tag.thumbImageUrl ? 'badge-has-img' : ''"
											>
												<a
													class="position-static mr-1 text-white"
													:href="tag.url ? getPublicUrl(tag.url) : null"
													target="__blank"
												>
													<img
														v-if="tag.thumbImageUrl"
														class="badge-img"
														:src="tag.thumbImageUrl + '-25x25.jpg'"
														v-b-tooltip.html.hover.bottom="
															tag.thumbImageUrl
																? '<img src=' +
																  tag.thumbImageUrl +
																  '-180x180.jpg  />'
																: 'Image not found'
														"
													/>
													{{ tag.name || tag.aliasName }}
												</a>
												<span>
													<feather-icon
														icon="XIcon"
														@click="removeStarItem(tag)"
													/>
												</span>
											</p>
										</drag>
									</div>
									<label for="tag">Star</label>
									<vue-bootstrap-typeahead
										@hit="selectStarItem"
										v-model="star_tag_input"
										ref="starInput"
										:serializer="(tag) => tag.name"
										:data="allStarTags"
									>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<div class="d-flex align-items-center">
												<img
													class="rounded-circle"
													:src="data.thumbImageUrl + '-30x30.jpg'"
													style="width: 30px; height: 30px"
												/>

												<span class="ml-4" v-html="htmlText"></span>
											</div>
										</template>
										<template slot="append">
											<button
												type="button"
												@click="selectStarItem({ name: star_tag_input }, true)"
												class="btn btn-success"
											>
												Add
											</button>
										</template>
									</vue-bootstrap-typeahead>
								</drop>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<div
								class="tag-wrapper form-group"
								:class="showSuppCastSectionActive ? 'active-input' : ''"
							>
								<drop
									@drop="handleDrop('suppCast', ...arguments)"
									@dragover="showSuppCastSectionActive = true"
									@dragleave="showSuppCastSectionActive = false"
								>
									<div class="tag-list">
										<h5>Supporting Cast List:</h5>
										<drag
											v-for="tag in selectedShowSuppCasts"
											class="drag"
											:transfer-data="{ item: tag, list: 'suppCast' }"
											:key="'suppCast' + tag.id + tag.name + tag.aliasName"
										>
											<p
												data-role="tagsinput"
												class="badge badge-success"
												:class="tag.thumbImageUrl ? 'badge-has-img' : ''"
											>
												<a
													class="position-static mr-1 text-white"
													:href="tag.url ? getPublicUrl(tag.url) : null"
													target="__blank"
												>
													<img
														v-if="tag.thumbImageUrl"
														class="badge-img"
														:src="tag.thumbImageUrl + '-25x25.jpg'"
														v-b-tooltip.html.hover.bottom="
															tag.thumbImageUrl
																? '<img src=' +
																  tag.thumbImageUrl +
																  '-180x180.jpg  />'
																: 'Image not found'
														"
													/>
													{{ tag.name || tag.aliasName }}
												</a>
												<span>
													<feather-icon
														icon="XIcon"
														@click="removeSuppCastItem(tag)"
													/>
												</span>
											</p>
										</drag>
									</div>
									<label for="tag">Supporting Cast</label>
									<vue-bootstrap-typeahead
										@hit="selectSuppCastItem"
										v-model="sup_cast_tag_input"
										ref="suppCastInput"
										:serializer="(tag) => tag.name"
										:data="allStarTags"
									>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<div class="d-flex align-items-center">
												<img
													class="rounded-circle"
													:src="data.thumbImageUrl + '-30x30.jpg'"
													style="width: 30px; height: 30px"
												/>

												<span class="ml-4" v-html="htmlText"></span>
											</div>
										</template>
										<template slot="append">
											<button
												type="button"
												@click="
													selectSuppCastItem({ name: sup_cast_tag_input }, true)
												"
												class="btn btn-success"
											>
												Add
											</button>
										</template>
									</vue-bootstrap-typeahead>
								</drop>
							</div>
						</div>
						<div class="col">
							<div
								class="tag-wrapper form-group"
								:class="showWritersSectionActive ? 'active-input' : ''"
							>
								<drop
									@drop="handleDrop('writer', ...arguments)"
									@dragover="showWritersSectionActive = true"
									@dragleave="showWritersSectionActive = false"
								>
									<div class="tag-list">
										<h5>Writers List:</h5>
										<drag
											v-for="tag in selectedShowWriters"
											class="drag"
											:transfer-data="{ item: tag, list: 'writer' }"
											:key="'writer' + tag.id + tag.name + tag.aliasName"
										>
											<p
												data-role="tagsinput"
												class="badge badge-success"
												:class="tag.thumbImageUrl ? 'badge-has-img' : ''"
											>
												<a
													class="position-static mr-1 text-white"
													:href="tag.url ? getPublicUrl(tag.url) : null"
													target="__blank"
												>
													<img
														v-if="tag.thumbImageUrl"
														class="badge-img"
														:src="tag.thumbImageUrl + '-25x25.jpg'"
														v-b-tooltip.html.hover.bottom="
															tag.thumbImageUrl
																? '<img src=' +
																  tag.thumbImageUrl +
																  '-180x180.jpg  />'
																: 'Image not found'
														"
													/>
													{{ tag.name || tag.aliasName }}
												</a>
												<span>
													<feather-icon
														icon="XIcon"
														@click="removeWriterItem(tag)"
													/>
												</span>
											</p>
										</drag>
									</div>
									<label for="tag">Writers</label>
									<vue-bootstrap-typeahead
										@hit="selectWriterItem"
										v-model="writer_tag_input"
										ref="writerInput"
										:serializer="(tag) => tag.name"
										:data="allStarTags"
									>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<div class="d-flex align-items-center">
												<img
													class="rounded-circle"
													:src="data.thumbImageUrl + '-30x30.jpg'"
													style="width: 30px; height: 30px"
												/>

												<span class="ml-4" v-html="htmlText"></span>
											</div>
										</template>
										<template slot="append">
											<button
												type="button"
												@click="
													selectWriterItem({ name: writer_tag_input }, true)
												"
												class="btn btn-success"
											>
												Add
											</button>
										</template>
									</vue-bootstrap-typeahead>
								</drop>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div
								class="tag-wrapper form-group"
								:class="showDirectorsSectionActive ? 'active-input' : ''"
							>
								<drop
									@drop="handleDrop('director', ...arguments)"
									@dragover="showDirectorsSectionActive = true"
									@dragleave="showDirectorsSectionActive = false"
								>
									<div class="tag-list">
										<h5>Directors List:</h5>
										<drag
											v-for="tag in selectedShowDirectors"
											class="drag"
											:transfer-data="{ item: tag, list: 'director' }"
											:key="'director' + tag.id + tag.name + tag.aliasName"
										>
											<p
												data-role="tagsinput"
												class="badge badge-success"
												:class="tag.thumbImageUrl ? 'badge-has-img' : ''"
											>
												<a
													class="position-static mr-1 text-white"
													:href="tag.url ? getPublicUrl(tag.url) : null"
													target="__blank"
												>
													<img
														v-if="tag.thumbImageUrl"
														class="badge-img"
														:src="tag.thumbImageUrl + '-25x25.jpg'"
														v-b-tooltip.html.hover.bottom="
															tag.thumbImageUrl
																? '<img src=' +
																  tag.thumbImageUrl +
																  '-180x180.jpg  />'
																: 'Image not found'
														"
													/>
													{{ tag.name || tag.aliasName }}
												</a>
												<span>
													<feather-icon
														icon="XIcon"
														@click="removeDirectorItem(tag)"
													/>
												</span>
											</p>
										</drag>
									</div>
									<label for="tag">Directors</label>
									<vue-bootstrap-typeahead
										@hit="selectDirectorItem"
										v-model="director_tag_input"
										ref="directorInput"
										:serializer="(tag) => tag.name"
										:data="allStarTags"
									>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<div class="d-flex align-items-center">
												<img
													class="rounded-circle"
													:src="data.thumbImageUrl + '-30x30.jpg'"
													style="width: 30px; height: 30px"
												/>

												<span class="ml-4" v-html="htmlText"></span>
											</div>
										</template>
										<template slot="append">
											<button
												type="button"
												@click="
													selectDirectorItem({ name: director_tag_input }, true)
												"
												class="btn btn-success"
											>
												Add
											</button>
										</template>
									</vue-bootstrap-typeahead>
								</drop>
							</div>
						</div>
						<div class="col">
							<div
								class="tag-wrapper form-group"
								:class="showProducersSectionActive ? 'active-input' : ''"
							>
								<drop
									@drop="handleDrop('producer', ...arguments)"
									@dragover="showProducersSectionActive = true"
									@dragleave="showProducersSectionActive = false"
								>
									<div class="tag-list">
										<h5>Producers List:</h5>
										<drag
											v-for="tag in selectedShowProducers"
											class="drag"
											:transfer-data="{ item: tag, list: 'producer' }"
											:key="'producer' + tag.id + tag.name + tag.aliasName"
										>
											<p
												data-role="tagsinput"
												class="badge badge-success"
												:class="tag.thumbImageUrl ? 'badge-has-img' : ''"
											>
												<a
													class="position-static mr-1 text-white"
													:href="tag.url ? getPublicUrl(tag.url) : null"
													target="__blank"
												>
													<img
														v-if="tag.thumbImageUrl"
														class="badge-img"
														:src="tag.thumbImageUrl + '-25x25.jpg'"
														v-b-tooltip.html.hover.bottom="
															tag.thumbImageUrl
																? '<img src=' +
																  tag.thumbImageUrl +
																  '-180x180.jpg  />'
																: 'Image not found'
														"
													/>
													{{ tag.name || tag.aliasName }}
												</a>
												<span>
													<feather-icon
														icon="XIcon"
														@click="removeProducerItem(tag)"
													/>
												</span>
											</p>
										</drag>
									</div>
									<label for="tag">Producers</label>
									<vue-bootstrap-typeahead
										@hit="selectProducerItem"
										v-model="producer_tag_input"
										ref="producerInput"
										:serializer="(tag) => tag.name"
										:data="allStarTags"
									>
										<template slot="suggestion" slot-scope="{ data, htmlText }">
											<div class="d-flex align-items-center">
												<img
													class="rounded-circle"
													:src="data.thumbImageUrl + '-30x30.jpg'"
													style="width: 30px; height: 30px"
												/>

												<span class="ml-4" v-html="htmlText"></span>
											</div>
										</template>
										<template slot="append">
											<button
												type="button"
												@click="
													selectProducerItem({ name: producer_tag_input }, true)
												"
												class="btn btn-success"
											>
												Add
											</button>
										</template>
									</vue-bootstrap-typeahead>
								</drop>
							</div>
						</div>
					</div>
				</b-col>

				<b-col cols="12" class="mt-1">
					<div class="form-group">
						<label for="tagImage">Image</label>
						<div class="row mb-1">
							<div
								:class="
									selectedShow.imageId && selectedShow.youtubeVideoId
										? 'col-8'
										: 'col'
								"
							>
								<input
									type="file"
									v-on:change="handleShowImageChange"
									class="form-control tag-image"
									:input="showInputImage"
								/>
							</div>
							<div
								class="col-4"
								v-if="selectedShow.imageId && selectedShow.youtubeVideoId"
							>
								<button
									type="button"
									@click="removeCustomImage"
									class="btn"
									:class="
										removeCustomImageAction ? 'btn-success' : 'btn-danger'
									"
								>
									{{
										removeCustomImageAction
											? "Undo Change"
											: "Remove Custom Image"
									}}
								</button>
							</div>
						</div>
						<img
							v-if="showImageUrl"
							:src="showImageUrl"
							class="show-image pt-2 img-fluid img-thumbnail"
						/>
					</div>
				</b-col>
			</b-row>
		</validation-observer>
	</b-modal>
</template>

<script>
import { mapGetters } from "vuex";
const validLInkRegx =
	/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

export default {
	data() {
		return {
			showModal: false,
			getSuggLoading: false,
			text_tag_input: "",
			star_tag_input: "",
			sup_cast_tag_input: "",
			writer_tag_input: "",
			director_tag_input: "",
			producer_tag_input: "",

			showStarSectionActive: false,
			showSuppCastSectionActive: false,
			showWritersSectionActive: false,
			showDirectorsSectionActive: false,
			showProducersSectionActive: false,
			errorMessage: "",
			selectedShow: {},
			selectedShowTextTags: [],
			selectedShowStars: [],
			selectedShowSuppCasts: [],
			selectedShowWriters: [],
			selectedShowDirectors: [],
			selectedShowProducers: [],
			selectedTags: [],
			selectedStars: [],
			selectedSuppCasts: [],
			selectedWriters: [],
			selectedDirectors: [],
			selectedProducers: [],
			tagsToRemove: [],
			tagsToAdd: [],
			tagsToUpdate: [],
			showInput: {
				youtubeVideoId: "",
				releasedYear: null,
				type: "",
				trailerVideoId: "",
				title: "",
				titleBn: "",
				titleEn: "",
				videoLink: "",
				storyPlot: "",
			},
			removeCustomImageAction: false,
			episodeUpdateInputs: [],
			episodesToDelete: [],
			episodeInputImage: [],
			defaultEpisode: "",
			suggestedEpisodeInput: [],
			episodeImageUrls: [],
			youtubeVideo: "",
			trailerVideo: "",
			showImageUrl: "",
			showInputImage: "",
			showUpdateInput: {},
			typeCustomInput: "",
			searchQuery: "",
			includeInactiveShows: false,
			showsNullTitleBn: false,
			showPublish: false,
			activities: [],
			activitiesLoading: true,
			expand: {},
			selectedChanel: {},
			saveAsEpisodeInput: {
				show: null,
				parent: null,
				slug: null,
				convertAll: false,
				episodeSlug: null,
			},
			imageUrl: null,
			selectedFile: null,
		};
	},
	computed: {
		...mapGetters("channels", ["allChannels"]),
		...mapGetters("shows", ["types"]),
		...mapGetters("tags", ["allTextTags", "allStarTags"]),
	},
	props: {
		input: {
			type: Object,
			default: {},
		},
		approve: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		input(e) {
			if (e.id) this.selectShow(e);
		},
		show() {
			this.text_tag_input = "";
			this.star_tag_input = "";
			this.sup_cast_tag_input = "";
			this.writer_tag_input = "";
			this.director_tag_input = "";
			this.producer_tag_input = "";
			this.showModal = this.show;
		},
		showModal(e) {
			if (!e) {
				this.$emit("close");
			}
		},
	},
	methods: {
		showForm() {
			this.showModal = true;
		},
		validateForm(e) {
			e.preventDefault();
			this.$refs.showFormRole.validate().then((success) => {
				if (success) {
					this.updateShow();
				}
			});
		},

		updateShow() {
			if (this.showUpdateInput.releasedYear === "") {
				this.showUpdateInput.releasedYear = null;
			}
			let showUpdateInput = { ...this.showUpdateInput };
			if (showUpdateInput.origin == "youtube") {
				showUpdateInput.videoLink = "";
			}
			if (showUpdateInput.videoPublishedAt === "1970-01-01T18:00:00.000Z") {
				showUpdateInput.videoPublishedAt = null;
			}
			delete showUpdateInput.isVideo;
			delete showUpdateInput.origin;

			if (this.showUpdateInput.type == "custom") {
				showUpdateInput.type = this.typeCustomInput;
			}
			if (this.showInputImage) {
				const reader = new FileReader();
				reader.readAsDataURL(this.showInputImage);
				reader.onload = async () => {
					showUpdateInput.thumbImageUrlLg = await reader.result;
					this.updateShowQ(showUpdateInput);
				};
			} else {
				this.updateShowQ(showUpdateInput);
			}
		},
		updateShowQ(showUpdateInput) {
			if (
				showUpdateInput.trailerVideoId &&
				showUpdateInput.trailerVideoId.length > 11
			) {
				const match = showUpdateInput.trailerVideoId.match(validLInkRegx);
				showUpdateInput.trailerVideoId =
					match && match[7].length == 11 ? match[7] : false;
			}
			axios
				.post("/graphql", {
					operationName: "",
					query: `
            mutation($id: ID!, $input: ShowUpdateInput) {
                showUpdate(id : $id, input: $input) {
                 id
                 title
                 titleBn
                 titleEn
                 url
                 type
                 isPublic
                 description
                 storyPlot
                 channelId
                 duration
                 isTrailer
                 externalLink
                 videoPublishedAt
                 videoEmbeddable
                 releasedYear
                 youtubeVideoId
                 autoApproveEpisode
                 channel{
                     id
                     tag{
                         id
                         name
                         slug
                     }
                 }
                 lastUpdatedBy{
                     id
                     name
                     email
                 }
                 slug
                 textTags{
                    ...on Tag {
                        id
                        slug
                        name
                        type
                        thumbImageUrl
                        url
                    }
                    ...on Alias{
                        id
                        tagId
                        aliasName
                    }
                }
                 stars{
                    ...on Tag {
                        id
                        slug
                        name
                        type
                        thumbImageUrl
                        url
                    }
                    ...on Alias{
                        id
                        tagId
                        aliasName
                    }
                }
                supportingCasts{
                    ...on Tag {
                        id
                        slug
                        name
                        type
                        thumbImageUrl
                        url
                    }
                    ...on Alias{
                        id
                        tagId
                        aliasName
                    }
                }
                writers{
                    ...on Tag {
                        id
                        slug
                        name
                        type
                        thumbImageUrl
                        url
                    }
                    ...on Alias{
                        id
                        tagId
                        aliasName
                    }
                }
                directors{
                    ...on Tag {
                        id
                        slug
                        name
                        type
                        thumbImageUrl
                        url
                    }
                    ...on Alias{
                        id
                        tagId
                        aliasName
                    }
                }
                producers{
                    ...on Tag {
                        id
                        slug
                        name
                        type
                        thumbImageUrl
                        url
                    }
                    ...on Alias{
                        id
                        tagId
                        aliasName
                    }
                }
                trailer {
                    youtubeVideoId
                    videoEmbeddable
                }
                thumbImageUrlLg
                thumbImageUrl
                imageId

                }
            }
            `,
					variables: {
						id: this.selectedShow.id,
						input: showUpdateInput,
					},
				})
				.then((res) => {
					if (res.data.errors) {
						this.errorToast(res.data.errors[0].message);
					} else {
						this.successToast("Show updated successfully");
						// commit mutation
						this.$store.commit("shows/updateShow", res.data.data.showUpdate);
						this.showModal = false;
					}
				})
				.catch((err) => {
					this.errorToast(err.message);
				});
		},

		reloadDetails(youtubeVideoId) {
			if (youtubeVideoId && youtubeVideoId.length >= 11) {
				if (youtubeVideoId.length > 11) {
					const match = youtubeVideoId.match(validLInkRegx);
					youtubeVideoId = match && match[7].length == 11 ? match[7] : false;
					if (!youtubeVideoId) {
						this.errorToast("Invalid YouTube video Id or url");
						return;
					}
				}
				if (youtubeVideoId.length === 11) {
					this.loadFromYouTubeQ(youtubeVideoId).then((response) => {
						if (response.data.data && response.data.data.loadFromYouTube) {
							let video = response.data.data.loadFromYouTube;
							this.showUpdateInput.duration = video.duration;
							this.showUpdateInput.youtubeTitle = video.youtubeTitle;
							this.showUpdateInput.videoPublishedAt = video.videoPublishedAt;
							this.showUpdateInput.releasedYear = new Date(
								video.videoPublishedAt
							).getFullYear();
							this.showUpdateInput.videoEmbeddable = video.videoEmbeddable;
							this.showUpdateInput.description = video.description;
							this.showUpdateInput.thumbImageUrl = video.thumbImageUrl;
							this.showUpdateInput.thumbImageUrlLg = video.thumbImageUrlLg;
							this.showImageUrl = video.thumbImageUrlLg;
							this.makeSlug();
							this.successToast("The video data is reloaded from YouTube");
						} else {
							this.errorToast(response.data.errors[0].message);
						}
					});
				}
			} else {
				this.errorToast("Invalid YouTube video Id or url");
			}
		},
		async loadFromYouTubeQ(youtubeVideoId) {
			return await axios.post("/graphql", {
				operationName: "",
				query: `
            query($youtubeVideoId: String) {
                loadFromYouTube(youtubeVideoId: $youtubeVideoId) {
                    slug
                    youtubeVideoId
                    youtubeTitle
                    description
                    videoEmbeddable
                    thumbImageUrl
                    thumbImageUrlLg
                    videoPublishedAt
                    duration
                }
            }
            `,
				variables: { youtubeVideoId },
			});
		},
		selectShow(show, episode = false) {
			this.removeCustomImageAction = false;
			this.tagsToAdd = [];
			this.tagsToRemove = [];
			this.tagsToUpdate = [];
			this.selectedShowTextTags = [];
			this.selectedShowStars = [];
			this.selectedShowSuppCasts = [];
			this.selectedShowWriters = [];
			this.selectedShowDirectors = [];
			this.selectedShowProducers = [];

			this.selectedShow = show;
			if (episode) return;

			this.selectedChanel = this.selectedShow.channel.tag;
			this.selectedShowTextTags = show.textTags.slice();
			this.selectedShowStars = show.stars.slice();
			this.selectedShowSuppCasts = show.supportingCasts.slice();
			this.selectedShowWriters = show.writers.slice();
			this.selectedShowDirectors = show.directors.slice();
			this.selectedShowProducers = show.producers.slice();

			this.showUpdateInput = {
				duration: this.selectedShow.duration,
				channelId: this.selectedShow.channelId || this.selectedShow.channel.id,
				videoPublishedAt: !isNaN(Number(this.selectedShow.videoPublishedAt))
					? new Date(Number(this.selectedShow.videoPublishedAt)).toISOString()
					: new Date(this.selectedShow.videoPublishedAt).toISOString(),
				releasedYear: this.selectedShow.releasedYear,
				videoEmbeddable: this.selectedShow.videoEmbeddable,
				titleBn:
					this.selectedShow.titleBn ||
					this.safeTrim(this.selectedShow.title.split("|")[1]),
				titleEn:
					this.selectedShow.titleEn ||
					this.safeTrim(this.selectedShow.title.split("|")[0]),
				youtubeVideoId: this.selectedShow.youtubeVideoId,
				slug: this.selectedShow.slug,
				type: this.selectedShow.type,
				videoLink: this.selectedShow.externalLink,
				description: this.selectedShow.description,
				storyPlot: this.selectedShow.storyPlot,
				tagsToAdd: this.tagsToAdd,
				tagsToRemove: this.tagsToRemove,
				tagsToUpdate: this.tagsToUpdate,
				isPublic: this.selectedShow.isPublic,
				isTrailer: this.selectedShow.isTrailer,
				autoApproveEpisode: this.selectedShow.autoApproveEpisode,
				isVideo: this.selectedShow.externalLink ? true : false,
				origin: this.selectedShow.externalLink ? "others" : "youtube",
				trailerVideoId: this.selectedShow.trailer
					? this.selectedShow.trailer.youtubeVideoId
					: null,
				trailerVideoEmbeddable: this.selectedShow.trailer
					? this.selectedShow.trailer.videoEmbeddable
					: false,
				removeCustomImage: this.removeCustomImageAction,
			};
			this.typeCustomInput = this.showUpdateInput.type;
			this.showInputImage = null;
			this.showImageUrl = this.selectedShow.thumbImageUrlLg;
		},
		removeCustomImage() {
			this.removeCustomImageAction = !this.removeCustomImageAction;
			if (!this.removeCustomImageAction) {
				this.showImageUrl = this.selectedShow.thumbImageUrlLg;
			} else {
				this.showImageUrl =
					"https://i.ytimg.com/vi/" +
					this.selectedShow.youtubeVideoId +
					"/maxresdefault.jpg";
			}
			this.showUpdateInput.removeCustomImage = this.removeCustomImageAction;
		},
		inputDate(e) {
			this.showUpdateInput.videoPublishedAt = new Date(
				new Date(e.target.valueAsDate).getTime()
			).toISOString();
		},

		selectChannel(channel) {
			this.$refs.channelInput.inputValue = "";
			this.selectedChanel = channel.tag;
			this.showUpdateInput.channelId = channel.id;
		},
		removeSelectedChannel() {
			this.selectedChanel = {};
			this.showUpdateInput.channelId = null;
		},
		customCheck(type) {
			let types = [
				"telefilm",
				"serial",
				"series",
				"natok",
				"film",
				"webfilm",
				"webseries",
				"shortfilm",
				"movie",
				"cinema",
				"show",
				"music",
			];

			return !types.includes(type);
		},
		getTagSuggestions(id) {
			this.getSuggLoading = true;
			axios
				.post("/graphql", {
					operationName: "",
					query: `
				query($showId: ID!) {
						searchTags(showId: $showId) {
								id
								name
								slug
								type
								thumbImageUrl
						}
				}
				`,
					variables: { showId: id },
				})
				.then((response) => {
					if (response.data && response.data.data.searchTags) {
						response.data.data.searchTags.forEach((data) => {
							switch (data.type) {
								case "tag":
									this.selectTextItem(data);
									break;
								case "actor":
									this.selectStarItem(data);
									break;
								case "writer":
									this.selectWriterItem(data);
									break;
								case "director":
									this.selectDirectorItem(data);
									break;
								case "producer":
									this.selectProducerItem(data);
									break;
								default:
							}
						});
					}
					this.getSuggLoading = false;
				})
				.catch((err) => {
					this.getSuggLoading = false;
				});
		},
		selectTextItem(tag, alias = false) {
			if (
				!this.selectedShowTextTags.some(
					(selectedShowTextTag) => selectedShowTextTag.name === tag.name
				)
			) {
				this.selectedShowTextTags.push(tag);
				if (alias) {
					this.tagsToAdd.push({ type: "tag", name: tag.name });
				} else {
					this.tagsToAdd.push({ type: "tag", id: tag.id });
				}
			}
			setTimeout(() => {
				this.$refs.textInput.$refs.input.focus();
				this.$refs.textInput.inputValue = "";
			}, 50);
		},
		selectStarItem(tag, alias = false, update = false, oldType = null) {
			let tags = this.getInputTags(tag, alias);
			tags.forEach((t) => {
				if (
					!this.selectedShowStars.some(
						(selectedShowStar) =>
							(selectedShowStar.name && selectedShowStar.name === t.name) ||
							(selectedShowStar.aliasName &&
								selectedShowStar.aliasName === t.aliasName)
					)
				) {
					this.selectedShowStars.push(t);
					this.pushToTagsToAdd(t, "star", update, oldType);
				}
			});

			setTimeout(() => {
				this.$refs.starInput.$refs.input.focus();
				this.$refs.starInput.inputValue = "";
			}, 50);
		},
		selectSuppCastItem(tag, alias = false, update = false, oldType = null) {
			let tags = this.getInputTags(tag, alias);
			tags.forEach((t) => {
				if (
					!this.selectedShowSuppCasts.some(
						(selectedShowSuppCast) =>
							(selectedShowSuppCast.name &&
								selectedShowSuppCast.name === t.name) ||
							(selectedShowSuppCast.aliasName &&
								selectedShowSuppCast.aliasName === t.aliasName)
					)
				) {
					this.selectedShowSuppCasts.push(t);
					this.pushToTagsToAdd(t, "suppCast", update, oldType);
				}
			});
			setTimeout(() => {
				this.$refs.suppCastInput.$refs.input.focus();
				this.$refs.suppCastInput.inputValue = "";
			}, 50);
		},
		selectWriterItem(tag, alias = false, update = false, oldType = null) {
			let tags = this.getInputTags(tag, alias);
			tags.forEach((t) => {
				if (
					!this.selectedShowWriters.some(
						(selectedShowWriter) =>
							(selectedShowWriter.name && selectedShowWriter.name === t.name) ||
							(selectedShowWriter.aliasName &&
								selectedShowWriter.aliasName === t.aliasName)
					)
				) {
					this.selectedShowWriters.push(t);
					this.pushToTagsToAdd(t, "writer", update, oldType);
				}
			});
			setTimeout(() => {
				this.$refs.writerInput.$refs.input.focus();
				this.$refs.writerInput.inputValue = "";
			}, 50);
		},
		selectDirectorItem(tag, alias = false, update = false, oldType = null) {
			let tags = this.getInputTags(tag, alias);
			tags.forEach((t) => {
				if (
					!this.selectedShowDirectors.some(
						(selectedShowDirector) =>
							(selectedShowDirector.name &&
								selectedShowDirector.name === t.name) ||
							(selectedShowDirector.aliasName &&
								selectedShowDirector.aliasName === t.aliasName)
					)
				) {
					this.selectedShowDirectors.push(t);
					this.pushToTagsToAdd(t, "director", update, oldType);
				}
			});
			setTimeout(() => {
				this.$refs.directorInput.$refs.input.focus();
				this.$refs.directorInput.inputValue = "";
			}, 50);
		},
		selectProducerItem(tag, alias = false, update = false, oldType = null) {
			let tags = this.getInputTags(tag, alias);
			tags.forEach((t) => {
				if (
					!this.selectedShowProducers.some(
						(selectedShowProducer) =>
							(selectedShowProducer.name &&
								selectedShowProducer.name === t.name) ||
							(selectedShowProducer.aliasName &&
								selectedShowProducer.aliasName === t.aliasName)
					)
				) {
					this.selectedShowProducers.push(t);
					this.pushToTagsToAdd(t, "producer", update, oldType);
				}
			});
			setTimeout(() => {
				this.$refs.producerInput.$refs.input.focus();
				this.$refs.producerInput.inputValue = "";
			}, 50);
		},

		removeTextTagItem(tag) {
			let index = this.selectedShowTextTags.indexOf(tag);
			if (index !== -1) {
				this.selectedShowTextTags.splice(index, 1);
			}

			const tagToRemove = this.tagsToAdd.findIndex(
				(tagToAdd) => tagToAdd.type === "tag" && tagToAdd.id === tag.id
			);
			if (tagToRemove != -1) {
				this.tagsToAdd.splice(tagToRemove, 1);
			} else {
				this.tagsToRemove.push({
					type: "tag",
					id: tag.id,
					aliasName: tag.aliasName,
				});
			}
		},
		removeStarItem(tag, onlyUnSelect = false) {
			let index = this.selectedShowStars.indexOf(tag);
			if (index !== -1) {
				this.selectedShowStars.splice(index, 1);
			}
			if (onlyUnSelect) return;

			const tagToRemove = this.tagsToAdd.findIndex(
				(tagToAdd) =>
					(tag.name && tagToAdd.name == tag.name && tagToAdd.type == "star") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "star")
			);
			const tagToUpdate = this.tagsToUpdate.findIndex(
				(tagToAdd) =>
					(tag.name && tagToAdd.name == tag.name && tagToAdd.type == "star") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "star")
			);
			if (tagToRemove != -1) {
				this.tagsToAdd.splice(tagToRemove, 1);
			} else {
				let type = "star";
				if (tagToUpdate != -1) {
					type = this.tagsToUpdate[tagToUpdate].oldType;
					this.tagsToUpdate.splice(tagToUpdate, 1);
				}
				this.tagsToRemove.push({
					type: type,
					id: tag.id,
					aliasName: tag.aliasName,
				});
			}
		},
		removeSuppCastItem(tag, onlyUnSelect = false) {
			let index = this.selectedShowSuppCasts.indexOf(tag);
			if (index !== -1) {
				this.selectedShowSuppCasts.splice(index, 1);
			}
			if (onlyUnSelect) return;

			const tagToRemove = this.tagsToAdd.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "suppCast") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "suppCast")
			);
			const tagToUpdate = this.tagsToUpdate.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "suppCast") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "suppCast")
			);
			if (tagToRemove != -1) {
				this.tagsToAdd.splice(tagToRemove, 1);
			} else {
				let type = "suppCast";
				if (tagToUpdate != -1) {
					type = this.tagsToUpdate[tagToUpdate].oldType;
					this.tagsToUpdate.splice(tagToUpdate, 1);
				}
				this.tagsToRemove.push({
					type: type,
					id: tag.id,
					aliasName: tag.aliasName,
				});
			}
		},
		removeWriterItem(tag, onlyUnSelect = false) {
			let index = this.selectedShowWriters.indexOf(tag);
			if (index !== -1) {
				this.selectedShowWriters.splice(index, 1);
			}
			if (onlyUnSelect) return;
			const tagToRemove = this.tagsToAdd.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "writer") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "writer")
			);
			const tagToUpdate = this.tagsToUpdate.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "writer") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "writer")
			);
			if (tagToRemove != -1) {
				this.tagsToAdd.splice(tagToRemove, 1);
			} else {
				let type = "writer";
				if (tagToUpdate != -1) {
					type = this.tagsToUpdate[tagToUpdate].oldType;
					this.tagsToUpdate.splice(tagToUpdate, 1);
				}
				this.tagsToRemove.push({
					type: type,
					id: tag.id,
					aliasName: tag.aliasName,
				});
			}
		},
		removeDirectorItem(tag, onlyUnSelect = false) {
			let index = this.selectedShowDirectors.indexOf(tag);
			if (index !== -1) {
				this.selectedShowDirectors.splice(index, 1);
			}
			if (onlyUnSelect) return;
			const tagToRemove = this.tagsToAdd.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "director") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "director")
			);
			const tagToUpdate = this.tagsToUpdate.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "director") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "director")
			);
			if (tagToRemove != -1) {
				this.tagsToAdd.splice(tagToRemove, 1);
			} else {
				let type = "director";
				if (tagToUpdate != -1) {
					type = this.tagsToUpdate[tagToUpdate].oldType;
					this.tagsToUpdate.splice(tagToUpdate, 1);
				}
				this.tagsToRemove.push({
					type: type,
					id: tag.id,
					aliasName: tag.aliasName,
				});
			}
		},
		removeProducerItem(tag, onlyUnSelect = false) {
			let index = this.selectedShowProducers.indexOf(tag);
			if (index !== -1) {
				this.selectedShowProducers.splice(index, 1);
			}
			if (onlyUnSelect) return;
			const tagToRemove = this.tagsToAdd.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "producer") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "producer")
			);
			const tagToUpdate = this.tagsToUpdate.findIndex(
				(tagToAdd) =>
					(tag.name &&
						tagToAdd.name == tag.name &&
						tagToAdd.type == "producer") ||
					(tag.id && tagToAdd.id == tag.id && tagToAdd.type == "producer")
			);
			if (tagToRemove != -1) {
				this.tagsToAdd.splice(tagToRemove, 1);
			} else {
				let type = "producer";
				if (tagToUpdate != -1) {
					type = this.tagsToUpdate[tagToUpdate].oldType;
					this.tagsToUpdate.splice(tagToUpdate, 1);
				}
				this.tagsToRemove.push({
					type: type,
					id: tag.id,
					aliasName: tag.aliasName,
				});
			}
		},
		pushToTagsToAdd(tag, type, update = false, oldType = null) {
			if (update && oldType) {
				let tagIndex = this.tagsToUpdate.findIndex(
					(uTag) =>
						uTag.type == oldType &&
						(tag.id ? uTag.id == tag.id : uTag.name == tag.name)
				);

				if (tagIndex == -1) {
					if (tag.aliasName) {
						this.tagsToUpdate.push({
							type: type,
							oldType: oldType,
							id: tag.id,
							aliasName: tag.aliasName,
						});
					} else {
						this.tagsToUpdate.push({
							type: type,
							oldType: oldType,
							id: tag.id,
						});
					}
				} else {
					if (type == this.tagsToUpdate[tagIndex].oldType) {
						this.tagsToUpdate.splice(tagIndex, 1);
						return;
					} else {
						this.tagsToUpdate[tagIndex].type = type;
					}
				}
			} else {
				if (tag.id) {
					this.tagsToAdd.push({ type: type, id: tag.id });
				} else {
					this.tagsToAdd.push({ type: type, name: tag.name });
				}
			}
		},
		handleDrop(toList, data) {
			//removing dashed border
			this.showStarSectionActive = false;
			this.showSuppCastSectionActive = false;
			this.showWritersSectionActive = false;
			this.showDirectorsSectionActive = false;
			this.showProducersSectionActive = false;

			let tag = data.item;
			if (toList == data.list) return;

			let nTag = tag.id
				? { id: tag.id, type: data.list }
				: { name: tag.name, type: data.list };
			let existTag = this.tagsToAdd.find(
				(t) =>
					(nTag.name && t.name == nTag.name && t.type == nTag.type) ||
					(nTag.id && t.id == nTag.id && t.type == nTag.type)
			); // exist on tagsToAdd

			switch (data.list) {
				case "star":
					this.removeStarItem(tag, existTag ? false : true);
					break;
				case "suppCast":
					this.removeSuppCastItem(tag, existTag ? false : true);
					break;
				case "writer":
					this.removeWriterItem(tag, existTag ? false : true);
					break;
				case "director":
					this.removeDirectorItem(tag, existTag ? false : true);
					break;
				case "producer":
					this.removeProducerItem(tag, existTag ? false : true);
					break;
				default:
					console.log("not removed");
			}
			switch (toList) {
				case "star":
					this.selectStarItem(tag, false, existTag ? false : true, data.list);
					break;
				case "suppCast":
					this.selectSuppCastItem(
						tag,
						false,
						existTag ? false : true,
						data.list
					);
					break;
				case "writer":
					this.selectWriterItem(tag, false, existTag ? false : true, data.list);
					break;
				case "director":
					this.selectDirectorItem(
						tag,
						false,
						existTag ? false : true,
						data.list
					);
					break;
				case "producer":
					this.selectProducerItem(
						tag,
						false,
						existTag ? false : true,
						data.list
					);
					break;
				default:
					console.log("not added");
			}
		},
		getInputTags(tag, alias) {
			let tags = [];
			if (alias) {
				let tagArray = tag.name.split(
					/\s*,\s*|\s*&\s*|\s*\|\s*|\s*"\s*|\sand\s/i
				); // separating by comma, pipe, double quote and &(',','&', 'and', '|', '"') ... and also removing extra space
				tagArray.forEach((tag) => {
					tag = tag.replace(/[&\/\\#,+()$~%‘’”'":*?\-<>={}0-9]/g, "").trim(); // removing special character
					let foundTag = this.allStarTags.find(
						(t) => t.name.toLowerCase() == tag.toLowerCase()
					);
					foundTag ? tags.push(foundTag) : tag ? tags.push({ name: tag }) : "";
				});
			} else {
				tags.push(tag);
			}
			return tags;
		},
		handleShowImageChange(e) {
			this.showInputImage = e.target.files[0];
			if (this.showInputImage) {
				this.showImageUrl = URL.createObjectURL(this.showInputImage);
			} else {
				this.showImageUrl = "";
			}
		},
		makeSlug() {
			this.showUpdateInput.slug =
				this.showUpdateInput.titleEn
					.toLowerCase()
					.replace(/[^a-z0-9]+/g, "-")
					.replace(/-$/, "") +
				(this.showUpdateInput.releasedYear != "" &&
				this.showUpdateInput.releasedYear != null
					? "-" + this.showUpdateInput.releasedYear
					: "");
		},
	},
};
</script>
