<template>
	<div>
		<b-button variant="success" class=" btn-icon" @click="show">Action</b-button>
    <!-- Action-->
    <b-modal
      v-model="showModal"
			ok-title="close"
			ok-only
      size="lg"
      title="Action"
    >
		<b-tabs>
			<b-tab
				active
				title="Shows Tags"
			>
				<b-table v-if="!loading && tagConnections.length > 0" responsive="sm" :items="tagConnections" :fields="showTagFields" class="text-center" striped bordered >
					<template v-slot:cell(title)="data">
						<b-link :href="getPublicUrl(data.item.show.url)" target="__blank">{{ data.item.show.title }}</b-link>
					</template>
					<template v-slot:cell(thumbImageUrl)="data">
						<img class="w-200px" v-if="data.item.show.thumbImageUrl" :src="data.item.show.thumbImageUrl" alt="Thumbnail">
					</template>
					<template v-slot:cell(actions)="data">
						<div class="d-flex justify-content-center">
							<b-button variant="primary" class="mr-min btn-icon" v-if="data.item.show.videoEmbeddable" @click="videoPlay(data.item.show)">
									<feather-icon icon="PlayCircleIcon"
									size="18"/>
							</b-button>

							<a v-else :href="'https://www.youtube.com/watch?v='+ data.item.show.youtubeVideoId" class="text-white" target="_blank">
								<b-button variant="primary" class="mr-min btn-icon" >
									<feather-icon icon="PlayCircleIcon"
									size="18"/>
								</b-button>
							</a>

							<b-button variant="danger" class="mr-min btn-icon" @click="deleteConnection([{id: data.item.id}])">
								<feather-icon icon="TrashIcon" size="18"/>
							</b-button>
						</div>
					</template>
				</b-table>
				<div v-else class="p-2 text-center">
					<span v-if="loading">loading...</span>
					<span v-else>No data found</span>
				</div>
			</b-tab>
			<b-tab title="Shows">
				<b-table v-if="!loading && makeUnique(tagConnections).length > 0" responsive="sm" :items="makeUnique(tagConnections)" :fields="showFields" class="text-center" striped bordered >
					<template v-slot:cell(title)="data">
						<b-link :href="getPublicUrl(data.item.url)" target="__blank">{{ data.item.title }}</b-link>
					</template>
					<template v-slot:cell(thumbImageUrl)="data">
						<img class="w-200px" v-if="data.item.thumbImageUrl" :src="data.item.thumbImageUrl" alt="Thumbnail">
					</template>
					<template v-slot:cell(actions)="data">
						<div class="d-flex justify-content-center">
							<b-button variant="primary" class="mr-min btn-icon" v-if="data.item.videoEmbeddable" @click="videoPlay(data.item)">
									<feather-icon icon="PlayCircleIcon"
									size="18"/>
							</b-button>

							<a v-else :href="'https://www.youtube.com/watch?v='+ data.item.youtubeVideoId" class="text-white" target="_blank">
								<b-button variant="primary" class="mr-min btn-icon" >
									<feather-icon icon="PlayCircleIcon"
									size="18"/>
								</b-button>
							</a>

							<b-button variant="danger" class="mr-min btn-icon" @click="deleteAllConnection(data.item.id)">
								<feather-icon icon="TrashIcon" size="18"/>
							</b-button>
						</div>
					</template>
				</b-table>
				<div v-else class="p-2 text-center">
					<span v-if="loading">loading...</span>
					<span v-else>No data found</span>
				</div>
			</b-tab>
		</b-tabs>

    </b-modal>
    <!-- Action end -->

		<player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>
	</div>
</template>

<script>

export default {
	data() {
		return {
			showModal: false,
			loading: false,
			tagConnections: [],
			selectedShow: {},
			showVideoPlayer: false,
			showTagFields: [
				{ key: 'title', label: 'Title' },
				{ key: 'thumbImageUrl', label: 'Thumbnail' },
				{ key: 'tagConnectType', label: 'Connection Type' },
				{ key: 'actions', label: 'Actions' },
			],
			showFields: [
				{ key: 'title', label: 'Title' },
				{ key: 'thumbImageUrl', label: 'Thumbnail' },
				{ key: 'actions', label: 'Actions' },
			]
		}
	},
	props: {
		tagId: {
			required: true,
		},
	},
	methods: {
		show() {
			this.showModal = true;
			this.getTagConnections(this.tagId)
		},
    videoPlay(show = {}) {
      this.selectedShow = show
      this.showVideoPlayer = show.id ? true : false
    },
		getTagConnections(id) {
			this.tagConnections = [];
			this.loading = true;
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						query($tagId: ID!) {
							tagConnections(tagId: $tagId )  {
								id
								showId
								tagId
								tagConnectType
								show {
									id
									youtubeVideoId
									slug
									title
									url
									thumbImageUrl
									videoEmbeddable
								}
								likeCount
								dislikeCount
								loveCount
							}
						}
					`,
					variables: {
						tagId: id
					}
				})
				.then(response => {
					if (response.data.data.tagConnections) {
						this.tagConnections = response.data.data.tagConnections;
					}
					this.loading = false;
				})
				.catch(err => {
					console.log(err);
					this.loading = false;
				});
		},
		makeUnique(showsConnection = []) {
			let shows = [];
			showsConnection.forEach(connection => {
				shows.push(connection.show);
			});
			let uniqueShows = Array.from(new Set(shows.map(a => a.id))).map(
				id => {
					return shows.find(a => a.id === id);
				}
			);
			return uniqueShows;
		},
		deleteConnection(connections) {
			this.confirmDialog({
        text: `You won't be able to revert this!`,
      }).then(value => {
				if (value) {
					axios
						.post("/graphql", {
							operationName: "",
							query: `
								mutation($connectionInput: [connectionInput]!) {
									removeConnections(connections: $connectionInput)
								}
								`,
							variables: {
								connectionInput: connections
							}
						})
						.then(response => {
							if (response.data.data.removeConnections) {
								for (let connection of connections) {
									let connectionIndex;
									let connectionFound = this.tagConnections.some(
										function(item, index) {
											connectionIndex = index;
											return item.id == connection.id;
										}
									);
									if (
										connectionFound &&
										connectionIndex != -1
									) {
										this.tagConnections.splice(
											connectionIndex,
											1
										);
									}
								}
								this.successToast("Connection has been deleted!");
							} else {
								this.errorToast(response.data.errors[0].message.toString());
							}
						});
				}
			});
		},
		deleteAllConnection(showId = null) {
			if (showId) {
				let connections = [];
				for (let tag of this.tagConnections) {
					if (tag.show.id == showId) {
						connections.push({
							id: tag.id
						});
					}
				}
				this.deleteConnection(connections);
			}
		},

	},
}
</script>
