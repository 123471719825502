import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Global Methods
import './global-methods'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

window.debounce = require('debounce')

window.axios = require('axios')
window.axios.defaults.baseURL = process.env.VUE_APP_GRAPHQL_ENDPOINT

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

let $config = {
  stage: process.env.VUE_APP_FIREBASE_ENV
}
// Your web app's Firebase configuration
var firebaseConfig = $config.stage == 'prod' ? {
    apiKey: "AIzaSyDl-v6pD1EHtAkbEchDgMyHQ5aszqXNGHo",
    authDomain: "bdshows.firebaseapp.com",
    databaseURL: "https://bdshows.firebaseio.com",
    projectId: "bdshows",
    storageBucket: "bdshows.appspot.com",
    messagingSenderId: "189863211643",
    appId: "1:189863211643:web:2fdc5bc128d48636"
} : {
    apiKey: "AIzaSyC52iOmvaNDizgAbk2d8T6vYUxVabcORJ0",
    authDomain: "bdshows-b4714.firebaseapp.com",
    projectId: "bdshows-b4714",
    storageBucket: "bdshows-b4714.appspot.com",
    messagingSenderId: "627679126006",
    appId: "1:627679126006:web:e2984d2e9cb3cc906a27d7"
};
// Initialize Firebase
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : ''

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
