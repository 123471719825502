import { onUpdated } from "@vue/composition-api"

export default {
    namespaced: true,
    state: {
				notifications: [],
        loading: false,
        count: 0,
        page: 1,
    },
    getters: {
			notifications: state => state.notifications,
			count: state => state.count,
			loading: state => state.loading,
			selectedNotifications: state => state.notifications?.filter(notification => notification.selected) || [],
    },
    mutations: {
      setNotifications (state, {rows, count, page}) {
        state.notifications = rows
        state.count = count
        state.page = page
        state.loading = false
      },
      setLoading (state, loading) {
        state.loading = loading
      },
			removeNotifications(state, ids) {
				state.notifications = state.notifications.filter(notification => !ids.includes(notification.id))
				state.count = state.count - ids.length
			},
    },
    actions: {
			async fetchYoutubeNotifications ({commit, state}, {page = 1, limit = 20, pending = false, youtubeVideoId = ''}) {
				commit('setLoading', true)
				axios
					.post("/graphql", {
						operationName: "",
						query: `query($first: Int, $skip: Int, $youtubeVideoId: String, $type: String){
							youtubeNotifications(first: $first, skip: $skip, youtubeVideoId: $youtubeVideoId, type: $type){
								count
								rows{
									id
									youtubeVideoId
									processingStartedAt
									processingEndedAt
									deletedAt
									createdAt
								}
							}
						}
							`,
						variables: {
							first: limit,
							skip:  (page - 1) * limit,
							youtubeVideoId: youtubeVideoId.trim(),
							type: pending ? 'pending' : null,
						},
					})
					.then((response) => {
						if (response.data && response.data.data?.youtubeNotifications) {
							commit('setNotifications', {
								rows: response.data.data.youtubeNotifications.rows,
								count: response.data.data.youtubeNotifications.count,
								page: page,
							})
						}
					});
			},
			async deleteAllSuccessfulNotifications ({commit, state}, {success, error}) {
				let query = `mutation{
					deleteAllSuccessfulNotifications
						}`;
				let variables = {};

				axios.post("/graphql", {
					operationName: "",
					query,
					variables,
				})
				.then((response) => {
					if(response.data?.data?.deleteAllSuccessfulNotifications) {
						commit('setNotifications', {
							rows: [],
							count: 0,
							page: 1,
						})
						success('Successfully deleted all successful notifications')
					} else {
						error('Error deleting notifications')
					}
				})

			},
			async resetAllFailedNotifications({commit, state}, { success, error }) {
				let query = `mutation{
					resetAllFailedNotifications
				}`;
				let variables = {};
				await axios.post("/graphql", {
					operationName: "",
					query,
					variables,
				})
				.then((response) => {
					if(response.data?.data?.resetAllFailedNotifications) {
						commit('setNotifications', {
							rows: [],
							count: 0,
							page: 1,
						})
						success('Successfully reset all failed notifications')
					} else {
						error('Error resetting notifications')
					}
				})

			},
			async updateNotifications ({commit, state, dispatch}, {ids, type, success, error}) {
				axios.post("/graphql", {
          operationName: "",
          query: `mutation($ids: [ID!]!, $type: String!){
						updateYoutubeNotifications(ids: $ids, type: $type)
					}`,
          variables:{
						ids: ids,
						type: type,
					}
        })
				.then((response) => {
					if(response.data?.data?.updateYoutubeNotifications) {
						commit('removeNotifications', ids)
						dispatch('notifications/getNotification', '', {root: true})
						success()
					} else {
						error('Error updating notifications')
					}
				})
				.catch((err) => {
					error('Error updating notifications')
				})

			}

    },
  }
