
const tagShowChannel = `
  query($type: String!, $query: String!){
    search(type:$type, query:$query){
      ...on Tag {
        id
        slug
        type
        thumbImageUrl
        url
        name
      }
      ...on Show {
				id
				title
				type
				isTrailer
				youtubeVideoId
				url
				thumbImageUrl
      }
  }
}
`

export default tagShowChannel
