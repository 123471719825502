<template>
	<b-card>

		<template v-slot:header>
			<h3 class="text-white cursor-pointer" @click="initData" title="Click to refresh reviews.">All Reviews</h3>
		</template>
		<br>

		<b-table striped bordered  v-if="!loading && count" responsive="sm" :items="reviews" :fields="fields" class="text-center">
			<template v-slot:cell(title)="data">
				<a
					v-if="data.item.show"
					:href="getPublicUrl(data.item.show.url)"
					target="_blank"
					rel="noopener noreferrer"
				>
					{{ data.item.show.title }}</a
				>
				<p v-else>Empty</p>
			</template>

			<template v-slot:cell(spoilerAlert)="data">
				<div class="custom-control custom-switch" v-if="data.item.comment" :class="loadingAction? 'disable' :''">
					<input type="checkbox" class="custom-control-input" v-model="data.item.spoilerAlert"  :id="data.item.id">
					<label class="custom-control-label" :for="data.item.id"  @click="toggleSpoiler(data.item)" title="Show spoiler alert." ></label>
				</div>
			</template>



			<template v-slot:cell(actions)="data">
				<div class="d-flex justify-content-center">
					<b-button :variant="data.item.isPublic ? 'danger' : 'success'" class="mr-min btn-icon" @click="toggleReview(data.item)"  :title="data.item.isPublic ? 'Hide review description': 'Show review description' ">
						<feather-icon v-if="data.item.isPublic" icon="EyeOffIcon"/>
						<feather-icon v-else icon="EyeIcon"   />
					</b-button>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="loading">loading...</span>
			<span v-else>No reviews found</span>
		</div>

		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="count" :limit="limit" :page="page"/>
		</template>


  </b-card>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
			page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 20,
			loadingAction: false,
			fields: [
				{ key: 'title', label: 'Show Title' },
				{ key: 'user.name', label: 'Review by' },
				{ key: 'comment', label: 'Comment' },
				{ key: 'storyRatingPoint', label: 'Story Rating Point' },
				{ key: 'actingRatingPoint', label: 'Acting Rating Point' },
				{ key: 'directionRatingPoint', label: 'Direction Rating Point' },
				{ key: 'spoilerAlert', label: 'Spoiler Alert' },
				{ key: 'actions', label: 'Actions' },
			],
    }
  },
	computed: {
    ...mapGetters('reviews', ['reviews', 'count', 'loading']),
	},
  mounted() {
    this.initData()
  },
  watch: {
    page () {
      this.getReviews()
    },
  },
	methods: {
		initData() {
    	this.getReviews()
		},
		linkGen(page) {
			return {
				name: 'reviews',
				query: {
					page,
					limit: this.limit,
				},
			}
		},
		getReviews(page) {
			if(page) {
				this.page = page
			}
			this.$store.dispatch('reviews/getReveiws', {
				skip: (this.page - 1) * this.limit,
				limit: this.limit * 1,
			})
		},
		toggleSpoiler(review) {
			this.updateReview(review.id, {
        spoilerAlert: !review.spoilerAlert,
      })
		},
		toggleReview(review) {
      this.updateReview(review.id, { isPublic: !review.isPublic });
		},
		updateReview(id, input) {
			if(this.loadingAction) {
				return
			}
			this.loadingAction = true
      this.$store.dispatch('reviews/updateReview', {
				id,
				input,
				success: (message) => {
					this.successToast(message)
					this.loadingAction = false
				},
				error: this.errorDialog,
			})
    },

	},





}
</script>

<style>
.modal-body{
	overflow: unset;
}
.disable{
	pointer-events: none;
  	opacity: 0.4;
}
.disable::after{
	content: '';
	position: absolute;
	bottom: -5px;
	width: 100%;
	left: 0;
	height: 46px;
	opacity: 0.4;
	z-index: 999;
}
</style>
