export default {
  namespaced: true,
  state: {
    notifications: {}
  },
  getters: {
    newShowSuggNotification: state => state.notifications.newShowSuggNotification || 0,
		newStarSuggNotification: state => state.notifications.newStarSuggNotification || 0,
		showInfoSuggNotification: state => state.notifications.showInfoSuggNotification || 0,
		starChannelInfoSuggNotification: state => state.notifications.starChannelInfoSuggNotification || 0,
		requestedChannel: state => state.notifications.requestedChannel || 0,
		videoSuggestion: state => state.notifications.videoSuggestion || 0,
		newChannelSuggestion: state => state.notifications.newChannelSuggestion || 0,
		reportsCount: state => state.notifications.reportsCount || 0,
		pendingYoutubeNotification: state => state.notifications.pendingYoutubeNotification || 0,
		allNotifications: state => {
			const notifications = state.notifications
			notifications.infoSuggestionsCount = state.notifications.newStarSuggNotification
																					+ state.notifications.showInfoSuggNotification
																					+ state.notifications.starChannelInfoSuggNotification
																					+ state.notifications.videoSuggestion
			return notifications
		}
  },
  mutations: {
		setNotifications (state, notifications = {}) {
			state.notifications = notifications
		},
		updateShowSuggNotification(state, count) {
			if(count == -1) {
				state.notifications.newShowSuggNotification = state.notifications.newShowSuggNotification - 1
			} else {
				state.notifications.newShowSuggNotification = count
			}
		},
		updateShowInfoSuggNotification(state, count) {
			if(count == -1) {
				state.notifications.showInfoSuggNotification = state.notifications.showInfoSuggNotification - 1
			} else {
				state.notifications.showInfoSuggNotification = count
			}
		},
		updateStarChannelInfoSuggNotification(state, count) {
			if(count == -1) {
				state.notifications.starChannelInfoSuggNotification = state.notifications.starChannelInfoSuggNotification - 1
			} else {
				state.notifications.starChannelInfoSuggNotification = count
			}
		},
		updateReportNotification(state, count) {
			if(count == -1) {
				state.notifications.reportsCount = state.notifications.reportsCount - 1
			} else {
				state.notifications.reportsCount = count
			}
		},
		updateVideoInfoSuggNotification(state, count) {
			if(count == -1) {
				state.notifications.videoSuggestion = state.notifications.videoSuggestion - 1
			} else {
				state.notifications.videoSuggestion = count
			}
		},
		updateNewStarSuggNotification(state, count) {
			if(count == -1) {
				state.notifications.newStarSuggNotification = state.notifications.newStarSuggNotification - 1
			} else {
				state.notifications.newStarSuggNotification = count
			}
		},
  },
  actions: {
		async getNotification ({ commit, state }) {
			const res = await	axios.post("/graphql", {
					operationName: "",
					query: `
						query{
								suggNotification{
									newShowSuggNotification
									newStarSuggNotification
									showInfoSuggNotification
									starChannelInfoSuggNotification
									requestedChannel
									videoSuggestion
									newChannelSuggestion
									reportsCount
									pendingYoutubeNotification
								}
						}`
				})
			commit('setNotifications', res.data.data?.suggNotification)
		}
	},
}
