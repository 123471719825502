<template>
	<b-card>

		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white cursor-pointer" @click="getFiles" title="Click to refresh files.">{{ !trash ? 'Active' : 'Trashed' }}  Files</h3>
			<div class="d-flex">
				<b-link v-if="!trash" to="/files?trash=1"><b-button variant="danger" class="mr-2">
					Trash
				</b-button></b-link>
				<b-link v-else to="/files"><b-button variant="success" class="mr-2">Active Files</b-button></b-link>
			</div>
		</template>
		<br>
		<div class="row">
			<form  v-on:submit.prevent="getFiles" class="my-2 overflow-hidden col-6">
					<div class="row">
							<select  @change="getFiles" id="filternIput" v-model="fileType" class="form-control col-8 ml-9">
									<option value="">All</option>
									<option value="user">Users</option>
									<option value="show">Shows</option>
									<option value="tag">Tags</option>
									<option value="siteProfile">WebSites</option>
									<option v-if="!trash" value="unused">UnUsed</option>
							</select>
							<div class="col-3">
									<input class="btn btn-success form-control" type="submit" value="Filter">
							</div>
					</div>
			</form>
			<div class="col-6 my-2">
				<button v-if="trash" class="btn btn-danger" @click="deleteSelectedFiles">Delete Selected Files</button>
			</div>
		</div>

		<div v-if="!loading">
			<table v-if="files.length" class="table">
				<thead>
					<tr>
						<th v-if="trash" scope="col"></th>
						<th scope="col">#</th>
						<th scope="col">Image</th>
						<th scope="col">Uploaded by</th>
						<th scope="col">Used by</th>
						<th scope="col">Created At</th>
						<th scope="col">Trashed At</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody class="text-center">
					<tr
						v-for="file in files"
						:key="file.id"
					>
						<td class="text-center"  v-if="trash"><input type="checkbox" @click="toggleSelect(file)" :value="file.selected"></td>
						<td><b>{{ file.id }}</b></td>
						<td><img class="w-200px h-200px" v-if="file.imageUrl" :src="file.imageUrl+'-200x200.jpg'"></td>
						<td>{{ (file.createdBy) ? file.createdBy.name :'' }}</td>
						<td>{{ (usedBy(file) == 'unknown') ? '' : usedBy(file) }}</td>
						<td> {{ new Date(Number(file.createdAt)).toLocaleString() }} </td>
						<td> {{ file.trashedAt ? new Date(Number(file.trashedAt)).toLocaleString() : '' }} </td>
						<td>

							<a href="#" v-if="usedBy(file) == 'unknown'" v-on:click.prevent="deleteFile([file.id], (trash ? false: true))" class="btn btn-danger btn-sm" title="mark as trash">
									<feather-icon v-if="trash" icon="TrashIcon" />
									<span v-else>Trash</span>
							</a>
							<button
								v-if="usedBy(file) != 'unknown'"
								class="btn btn-success"
								@click="selectFile(file)"
								data-toggle="modal"
								data-target="#fileActionModal"
							>
								Details
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<div v-else class="text-center m-3">empty:)</div>
		</div>

		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="count" :limit="limit" :page="page"/>
		</template>

  </b-card>

</template>

<script>

import { mapGetters } from 'vuex'


export default {
  data() {
		return {
		  types: [],
			selectedType: null,
			limit: 20,
			fileType: '',
		}
  },
	computed: {
    ...mapGetters('files', ['files', 'count', 'loading', 'selectedFiles']),
		page: {
			get() {
				return this.$route.query.page || 1
			},
			set(value) {
				if(page == this.page) return;
				this.$router.push({ query: { ...this.$route.query, page: value } })
			}
		},
		trash: {
			get() {
				return this.$route.query.trash || 0
			},
			set(value) {
				if(trash == this.trash) return;
				this.$router.push({ query: { ...this.$route.query, trash: value } })
			}
		},
	},
	components: {

	},
  mounted() {
    this.getFiles()
  },
  watch: {
    page () {
      this.getFiles()
    },
		trash () {
			this.getFiles()
		}
  },
	methods: {
		linkGen(page) {
			return {
				path: this.$route.path,
				query: {
					trash: this.trash,
					page: page
				}
			}
		},
		getFiles() {
			this.$store.dispatch('files/getFiles', {
				page: this.page,
				trash: this.trash ? true : false,
				limit: this.limit,
				type: this.fileType
			})
		},
    usedBy(file) {
			let user = file.user
			let show = file.show
			let tag = file.tag
			let website = file.website

			if(user) {
					return 'user'
			} else if (show) {
					return 'show'
			} else if (tag) {
					return 'tag'
			} else if (website) {
					return 'website'
			} else {
					return 'unknown'
			}
    },
		toggleSelect(file) {
			this.$store.commit('files/toggleSelect', file)
		},
		deleteSelectedFiles() {
        let filesIdArray = []
        this.selectedFiles.forEach(file => {
            filesIdArray.push(file.id)
        });
        if (filesIdArray.length <= 0) {
            this.warningToast('Please select at least one file')
            return
        }
        this.deleteFile(filesIdArray, this.trash);
    },

    deleteFile(ids, trash = false) {
      let filesMsg = ids.length > 1 ? 'files' : 'file';
      this.confirmDialog({
          title: `you want to delete selected ${filesMsg}?`,
      }).then((result) => {
          if (result) {
              axios
                .post("/graphql", {
                  operationName: "",
                  query: `
										mutation($ids: [ID!], $trash: Boolean) {
												deleteFile(ids: $ids,  trash: $trash)
										}
										`,
                  variables: {ids: ids, trash: trash? true : false},
                })
                .then((response) => {
                  if (response.data.data.deleteFile) {
                    // update files array with ids
										this.$store.commit('files/deleteFiles', ids)
                    //show success message
										this.successToast(`The ${filesMsg} has been ${trash ? 'trashed' : 'deleted'}!`)
                  } else {
										this.errorToast(`${trash ? 'Trash' : 'Delete'} action failed!!`)
                  }
                })
          }
      })
    },
	}
}
</script>

<style>
.modal-body{
	overflow: unset;
}
.input-icon {
	border-radius: 6px;
	padding: 2px;
	line-height: 17px;
}

</style>
