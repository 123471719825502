const tagOrAlias = `
  ...on Tag {
    id
    slug
    name
    type
    thumbImageUrl
    url
  }
  ...on Alias{
    id
    tagId
    aliasName
  }
`

const rows = `
      id
      title
      titleBn
      titleEn
      url
      type
      duration
      isPublic
      isActive
      storyPlot
      externalLink
			episodeList
			defaultEpisode
			suggestioncounts{
				id
				type
				showId
				tagId
				tag{
						id
						type
						name
				}
				count
			}
      videoPublishedAt
      videoEmbeddable
      releasedYear
      description
      slug
      isTrailer
      imageId
      youtubeVideoId
      thumbImageUrl
      thumbImageUrlLg
      autoApproveEpisode
      lastUpdatedBy {
        id
        name
        email
      }
      channel{
        id
        tag {
          id
          name
          slug
        }
      }
      defaultEpisode
      tags{
        ${tagOrAlias}
      }
      textTags{
        ${tagOrAlias}
      }
      stars{
        ${tagOrAlias}
      }
      supportingCasts{
        ${tagOrAlias}
      }
      writers{
        ${tagOrAlias}
      }
      directors{
        ${tagOrAlias}
      }
      producers{
        ${tagOrAlias}
      }
      trailer{
        id
        youtubeVideoId
        videoEmbeddable
      }
    `


export default rows
