<template>
	<b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white cursor-pointer" @click="initData" title="Click to refresh channels.">Channels</h3>
			<div class="d-flex">
				<b-link v-if="suggested == 0" to="/channels?suggested=1"><b-button variant="success" class="mr-2">
					Suggested Channels <b-badge variant="danger">{{ requestedChannel }}</b-badge>
				</b-button></b-link>
				<b-link v-else to="/channels"><b-button variant="success" class="mr-2">Active Channels</b-button></b-link>
				<b-button v-if="suggested == 0" variant="success" class="ml-2" @click="toggleAddChannel">Add Channel</b-button>
			</div>
		</template>
		<br>
		<div v-if="suggested == 0" class="search-form">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="Search by name..." @input="debounceInput" v-model.trim="searchQuery"/>
        <b-input-group-append>
          <b-button variant="primary" @click="getChannels(1)">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
		<br>

		<b-table v-if="!loading && count"  responsive="sm" :items="channels" :fields="fields" class="text-center" striped bordered >
			<template v-slot:cell(tag.name)="data">
				<b-link :href="getPublicUrl('/channel/'+data.item.tag.slug)" target="__blank">{{ data.item.tag.name }}</b-link>
			</template>
			<template v-slot:cell(tag.displayImageUrl)="data">
				<b-link :href="data.item.tag.displayImageUrl" target="__blank">
					<img v-if="data.item.tag.displayImageUrl" :src="data.item.tag.displayImageUrl" :alt="data.item.tag.name" class="img-fluid" style="max-height: 100px;">
					<svg style="max-height: 95px;" v-else aria-hidden="true" focusable="false"
							data-prefix="fas" data-icon="user" role="img"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
							class="svg-inline--fa fa-user fa-w-14">
							<path fill="currentColor"
									d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
									class="___class_+?20___"></path>
					</svg>
				</b-link>
			</template>
			<template v-slot:cell(tag.isPublic)="data">
				<div class="dashboard-action d-block">
					<div class="custom-control custom-switch">
						<input disabled="true" type="checkbox" class="custom-control-input" v-model="data.item.tag.isPublic"  :id="data.item.tag.id">
						<label class="custom-control-label" :for="data.item.tag.id"  @click="chnageShowStatus(data.item.tag.id, !data.item.tag.isPublic)" ></label>
					</div>
				</div>
			</template>
			<template v-slot:cell(tag.links)="data">
				<div class="w-100px">
					<b-link class="ml-3px mt-3px d-inline-block" v-for="link in data.item.tag.links" :href="link.url" target="__blank" :key="link.id">
						<brand-icon :icon="link.type" />
					</b-link>
					<!--Add link-->
					<b-link class="ml-3px mt-3px input-icon btn btn-success" @click="toggleAddLink(data.item.tag)">
						<feather-icon icon="PlusIcon"  size="16" />
					</b-link>
					<b-link v-if="data.item.tag.links.length > 0" class="ml-3px mt-3px input-icon btn btn-primary" @click="toggleEditLinks(data.item.tag)">
						<feather-icon icon="EditIcon"  size="16" />
					</b-link>
				</div>

			</template>
			<template v-slot:cell(subscribe)="data">
				<div>
					<b-button v-if="channelSubscription(data.item)" variant="success" class="mb-1 text-nowrap">
						{{ subEndDate(data.item) }}
					</b-button>
					<b-button v-if="channelSubscription(data.item)" variant="success" class="mb-1 text-nowrap" @click="feedSubscribe(data.item,'subscribe', true)">
						<feather-icon icon="BellOffIcon" size="18"/>
						Renew
					</b-button>
					<b-button v-if="!channelSubscription(data.item)" variant="success" class="mb-1 text-nowrap" @click="feedSubscribe(data.item,'subscribe')">
						<feather-icon icon="BellIcon" size="18"/>
						Subscribe
					</b-button>
					<b-button v-else variant="success" class="mb-1 text-nowrap" @click="feedSubscribe(data.item,'unsubscribe')">
						<feather-icon icon="BellOffIcon" size="18"/>
						Unsubscribe
					</b-button>
					<b-button variant="success" class="mb-1 text-nowrap" @click="toggleCheckSubStatus(data.item)">
						<feather-icon icon="EyeIcon" size="18"/>
						Check Status
					</b-button>
				</div>
			</template>

			<template v-slot:cell(tag.actions)="data">
				<div class="d-flex justify-content-center">
					<b-button variant="primary" class="mr-min btn-icon" @click="toggleUpdateChannel(data.item.tag)">{{ suggested ? 'Approve' : 'Edit' }}</b-button>
					<b-button variant="danger" class="mr-min btn-icon" @click="deleteChannel(data.item.tag.id)">
						<feather-icon icon="TrashIcon" size="18"/>
					</b-button>
					<b-button class="mr-min btn-icon"  variant="primary" @click="toggleActivity(data.item.tag)">
						<feather-icon icon="ActivityIcon" size="18"/>
					</b-button>
					<action-button :tagId="data.item.tag.id" />
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="loading">loading...</span>
			<span v-else>No channels found</span>
		</div>

		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="count" :limit="limit" :page="page"/>
		</template>


    <!-- channels user activities -->
    <b-modal
      v-model="userActivityOnSelectedChannel.show"
      ok-only
      ok-title="Close"
      size="lg"
      :title="'User Activities - ' + userActivityOnSelectedChannel.title"
    >
      <b-table
				v-if="!userActivityOnSelectedChannel.loading && userActivityOnSelectedChannel.count"
				responsive
				:items="userActivityOnSelectedChannel.activities"
				:fields="userActivityOnSelectedChannel.fields"
				class="text-center"
				:busy="userActivityOnSelectedChannel.loading"
				striped bordered
			>
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ new Date(Number(data.item.createdAt)).toLocaleString() }}
          </span>
        </template>

        <template #cell(person)="data">
          <span class="text-nowrap">
            {{ (data.item.user) ? data.item.user.name : (data.item.channel) ? data.item.channel.tag.name : '' }}
          </span>
        </template>

        <template #cell(details)="data">
          <div @click="toggleActivityCollapse(data.item.id)" class="activity-details text-center" :class="data.item.collapse ? 'activity-details-collapse': ''">
          <p >
            {{ data.item.details }}
          </p>
          <feather-icon v-if="data.item.details" class="icon" icon="ChevronDownIcon"
                size="18"/>
          </div>
        </template>
      </b-table>
      <div v-else class="p-2 text-center">
				<span v-if="userActivityOnSelectedChannel.loading">loading...</span>
				<span v-else>No activities found</span>
			</div>
    </b-modal>
    <!--  channels user activities end -->

		<!-- add tag -->
		<b-modal
			v-model="addChannel.show"
			ok-title="Add"
			size="sm"
			title="Add Channel"
			@ok="addChannelValidation"
			:ok-disabled="addChannel.loading"
		>

		<validation-observer ref="tagRole">
			<b-form>
				<b-row>
					<!--Type-->
					<b-col>
						<b-form-group
							label="Type"
							label-for="type"
						>
							<validation-provider
								rules="required"
								v-slot="{ errors }"
							>
								<b-form-select
									id="type"
									v-model="addChannel.input.type"
									:options="[ { text: 'YouTube', value: 'youtube' }, { text: 'Others', value: 'others' } ]"
								></b-form-select>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12" v-if="addChannel.input.type == 'youtube'">
						<b-form-group
							label="YouTube Channel ID"
							label-for="youtubeChannelId"
						>
							<validation-provider
								rules="required"
								v-slot="{ errors }"
								name="YouTube Channel ID"
							>
								<b-form-input
									id="youtubeChannelId"
									v-model="addChannel.input.youtubeChannelId"
									placeholder="Enter YouTube Channel ID"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-row>
							<b-col cols="12">
								<b-form-group
									label="Name"
									label-for="name"
								>
									<validation-provider
										#default="{ errors }"
										name="name"
										rules="required|regex:^[a-zA-Z0-9 ]+$"
									>
										<b-form-input
											id="name"
											v-model="addChannel.input.name"
											:state="errors.length > 0 ? false:null"
											placeholder="Channel name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group
									label="Bangla Name"
									label-for="nameBn"
								>
									<validation-provider
										#default="{ errors }"
										name="nameBn"
										rules=""
									>
										<b-form-input
											id="nameBn"
											v-model="addChannel.input.nameBn"
											:state="errors.length > 0 ? false:null"
											placeholder="Channel Bangla name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group
									label="Active Year"
									label-for="activeYear"
								>

									<validation-provider
										#default="{ errors }"
										name="activeYear"
										rules="between:1800,9999"
									>
										<b-form-input
											id="activeYear"
											v-model="addChannel.input.activeYear"
											class="form-control"
											type="number"
											min="1900" max="9999" step="1"
											:state="errors.length > 0 ? false:null"
											placeholder="Active Year(YYYY)"
										/>
										<small v-if="errors.length > 0 " class="text-danger">
											Year must be between 1800 and 9999
										</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group
									label="Information"
									label-for="info"
								>
									<b-form-textarea
										rows="6"
										id="info"
										v-model="addChannel.input.info"
										placeholder="Information.."
									/>
								</b-form-group>
							</b-col>

						</b-row>

						<!--image-->
						<div class="form-group">
							<label for="image">Image</label>
							<input type="file" class="form-control tag-image" id="image"
									v-on:change="handleChange" :input="selectedFile" />
							<div class="text-center">
								<img v-if="addChannelImageUrl" :src="addChannelImageUrl" class=" img-fluid mt-2 mb-2" />
							</div>

						</div>




					</b-col>
				</b-row>
			</b-form>
		</validation-observer>

		</b-modal>


		<!-- update tag -->
		<b-modal
			v-model="updateChannel.show"
			:ok-title=" suggested? 'Aprrove' : 'Update'"
			size="sm"
			:title="(suggested ? 'Aprrove' : 'Update') + ' Channel'"
			@ok="updateChannelValidation"
			:ok-disabled="updateChannel.loading"
		>

		<validation-observer ref="tagRoleUpdate">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-row>
							<b-col cols="12">
								<b-form-group
									label="Name"
									label-for="name"
								>
									<validation-provider
										#default="{ errors }"
										name="name"
										rules="required|regex:^[a-zA-Z0-9 ]+$"
									>
										<b-form-input
											id="name"
											v-model="updateChannel.input.name"
											:state="errors.length > 0 ? false:null"
											placeholder="Channel name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group
									label="Bangla Name"
									label-for="nameBn"
								>
									<validation-provider
										#default="{ errors }"
										name="nameBn"
										rules=""
									>
										<b-form-input
											id="nameBn"
											v-model="updateChannel.input.nameBn"
											:state="errors.length > 0 ? false:null"
											placeholder="Channel Bangla name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<b-form-group
									label="Active Year"
									label-for="activeYear"
								>

									<validation-provider
										#default="{ errors }"
										name="activeYear"
										rules="between:1800,9999"
									>
										<b-form-input
											id="activeYear"
											v-model="updateChannel.input.activeYear"
											class="form-control"
											type="number"
											min="1900" max="9999" step="1"
											:state="errors.length > 0 ? false:null"
											placeholder="Active Year(YYYY)"
										/>
										<small v-if="errors.length > 0 " class="text-danger">
											Year must be between 1800 and 9999
										</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group
									label="Information"
									label-for="info"
								>
									<b-form-textarea
										rows="6"
										id="info"
										v-model="updateChannel.input.info"
										placeholder="Information.."
									/>
								</b-form-group>
							</b-col>
						</b-row>

						<!--image-->
						<div class="form-group">
							<label for="image">Image</label>
							<input type="file" class="form-control tag-image" id="image"
									v-on:change="handleChange" :input="selectedFile" />
							<div class="text-center">
								<img v-if="addChannelImageUrl" :src="addChannelImageUrl" class=" img-fluid mt-2 mb-2" />
							</div>

						</div>




					</b-col>
				</b-row>
			</b-form>
		</validation-observer>

		</b-modal>

		<b-modal
			v-model="tagAddLink.show"
			ok-title="Add"
			size="lg"
			title="Add Link"
			@ok="tagAddLinkValidation"
			:ok-disabled="tagAddLink.loading"
		>

		<validation-observer ref="tagAddLinkRole">
			<b-form @submit="tagAddLinkValidation">
				<b-row>

					<b-col cols="12">
						<b-form-group
							label="Type"
							label-for="type"
						>
							<validation-provider
								#default="{ errors }"
								name="Type"
								rules="required"
							>
								<b-form-select
									id="type"
									v-model="tagAddLink.input.type"
									:options="tagAddLink.types"
									placeholder="Link Type"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group
							label="Url"
							label-for="url"
						>
							<validation-provider
								#default="{ errors }"
								name="url"
								rules="required"
							>
								<b-form-input
									id="url"
									v-model="tagAddLink.input.url"
									:state="errors.length > 0 ? false:null"
									placeholder="url"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>


					</b-col>

				</b-row>
			</b-form>
		</validation-observer>

		</b-modal>


		<!-- add tag -->
		<b-modal
		v-model="tagEditLink.show"
		ok-title="Update"
		size="lg"
		title="Edit Links"
		@ok="tagEditLinkValidation"
		:ok-disabled="tagEditLink.loading"
		>

		<validation-observer ref="tagEditLinkRole">
		<b-form @submit="tagEditLinkValidation">
			<b-row>

				<!-- domain -->
				<b-col cols="12" v-for="(link, index) in tagEditLink.inputs" :key="index">
					<b-row>
						<b-col md="3">
							<b-form-group>
								<validation-provider
									#default="{ errors }"
									name="Type"
									rules="required"
								>
									<b-form-select
										id="type"
										v-model="tagEditLink.inputs[index].type"
										:options="tagAddLink.types"
										placeholder="Link Type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="5">
							<b-form-group>
								<validation-provider
								#default="{ errors }"
								name="Url"
								rules="required"
								>
								<b-form-input
									v-model="tagEditLink.inputs[index].url"
									:state="errors.length > 0 ? false:null"
									placeholder="Url"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="4">
							<b-button variant="danger" @click="linkDelete(link)">Remove</b-button>
						</b-col>

					</b-row>
				</b-col>
			</b-row>
		</b-form>
		</validation-observer>

		</b-modal>

		<!-- subscription area -->
		<b-modal
			v-model="channelSubscriptionStatus.show"
			title="Subscription Status"
		>
		<div class="show-after-2s" >
			<iframe v-if="channelSubscriptionStatus.youtubeChannelId" width="100%" height="315"
					:src="'https://pubsubhubbub.appspot.com/subscription-details?hub.callback='+getAPIUrl()+'%2Fapi%2Fyoutube&hub.topic=https%3A%2F%2Fwww.youtube.com%2Fxml%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D'+ channelSubscriptionStatus.youtubeChannelId + '&hub.secret='"
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen></iframe>
		</div>
		</b-modal>
  </b-card>

</template>

<script>
import {
	facebookRegx,
	twitterRegx,
	instagramRegx,
	snapchatRegx,
	youtubeRegx,
	linkedinRegx,
	wikipediaRegx,
	validLInkRegx
} from '../helpers/regex'
import {  BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import ActionButton from '@/components/tags/Action.vue'

export default {
  data() {
    return {
			page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 10,
			searchQuery: '',
			fields: [
				{ key: 'tag.name', label: 'Name' },
				{ key: 'tag.nameBn', label: 'Bangla Name' },
				{ key: 'tag.slug', label: 'Slug' },
				{ key: 'tag.activeYear', label: 'Active Year' },
				{ key: 'tag.info', label: 'Information' },
				{ key: 'tag.displayImageUrl', label: 'Image' },
				{ key: 'tag.isPublic', label: 'Public' },
				{ key: 'tag.links', label: 'Links' },
				{ key: 'tag.lastUpdatedBy.name', label: 'Last Updated By' },
				{ key: 'subscribe', label: 'Subscribe' },
				{ key: 'tag.actions', label: 'Actions' },
			],
			userActivityOnSelectedChannel:{
				show: false,
				loading: false,
				title: '',
				activities: [],
				tag: {},
				page: 1,
				limit: 20,
				fields: [
					{ key: 'createdAt', label: 'Date' },
					{ key: 'activity', label: 'Activity' },
					{ key: 'childSlug', label: 'Episode' },
					{ key: 'person', label: 'User/Channel' },
					{ key: 'details', label: 'Details' },
				],
			},
			addChannel: {
				show: false,
				loading: false,
				input: {
					name: "",
					nameBn: "",
					type: "youtube",
					thumbImageUrl: "",
					activeYear: "",
					info: "",
				}
			},
			channelCustomInputs: [
				{
					id: "",
					key: "",
					info: ""
				}
			],
			channelRequiredInputs: [
				{
					id: "",
					key: "",
					info: ""
				}
			],
			addChannelImageUrl: null,
			addChannelImageUrl: null,
			selectedFile: null,
			updateChannel: {
				show: false,
				loading: false,
				tagId: "",
				input: {
					name: "",
					nameBn: "",
					type: "channel",
					thumbImageUrl: "",
					activeYear: "",
					info: "",
				}
			},
			tagAddLink: {
				show: false,
				loading: false,
				types: [
					{ value: '', text: 'Select a Type' },
					{ value: 'facebook', text: 'Facebook' },
					{ value: 'twitter', text: 'Twitter' },
					{ value: 'instagram', text: 'Instagram' },
					{ value: 'linkedin', text: 'LinkedIn' },
					{ value: 'youtube', text: 'Youtube' },
					{ value: 'snapchat', text: 'Snapchat' },
					{ value: 'wikipedia-w', text: 'Wikipedia' },
				],
				input: {
					url: "",
					type: "",
					tagId: ""
				}
			},
			tagEditLink: {
				show: false,
				loading: false,
				types: [
					{ value: '', text: 'Select a Type' },
					{ value: 'facebook', text: 'Facebook' },
					{ value: 'twitter', text: 'Twitter' },
					{ value: 'instagram', text: 'Instagram' },
					{ value: 'linkedin', text: 'LinkedIn' },
					{ value: 'youtube', text: 'Youtube' },
					{ value: 'snapchat', text: 'Snapchat' },
					{ value: 'wikipedia-w', text: 'Wikipedia' },
				],
				inputs: [],
				tagId: ""
			},
			channelSubscriptionStatus: {
				show: false,
				youtubeChannelId: "",
			},
    }
  },
	computed: {
    ...mapGetters('channels', ['channels', 'count', 'loading']),
		...mapGetters('notifications', ['requestedChannel']),
		suggested() {
			return this.$route.query?.suggested * 1 || 0
		}
	},
	components: {
		ActionButton
	},
  mounted() {
    this.initData()
  },
  watch: {
    page () {
      this.getChannels()
    },
		suggested () {
			this.getChannels()
		}
  },
	methods: {
		initData() {
    	this.getChannels()
		},
		linkGen(page) {
			return {
				name: 'channels',
				query: {
					page,
					limit: this.limit,
					suggested: this.suggested,
				},
			}
		},
		toggleEditLinks(tag = []) {
			this.tagEditLink.tagId = tag?.id
			this.tagEditLink.show = !this.tagEditLink.show
			this.tagEditLink.inputs = tag?.links
		},
		tagEditLinkValidation(e) {
			e.preventDefault();

			this.$refs.tagEditLinkRole.validate().then(success => {
				if(success) {
					//this.linkBulkUpdate()
				}
			})
		},
		toggleAddLink(tag) {
			this.tagAddLink.show = !this.tagAddLink.show
			this.tagAddLink.input.tagId = tag.id
		},
		tagAddLinkValidation(e) {
			e.preventDefault();

			this.$refs.tagAddLinkRole.validate().then(success => {
				if(success) {

					if(!this.customLinkValidation(this.tagAddLink.input.url, this.tagAddLink.input.type)) {
						this.$refs.tagAddLinkRole.setErrors({url: 'Invalid URL'});
						return
					}
					this.addLinkToTag()
				}
			})
		},
		customLinkValidation(url, type) {
			if (type === "") {
					return true;
			} else if (type === "facebook") {
					return facebookRegx.test(url);
			} else if (type === "twitter") {
					return twitterRegx.test(url);
			} else if (type === "instagram") {
					return instagramRegx.test(url);
			} else if (type === "snapchat") {
					return snapchatRegx.test(url);
			} else if (type === "youtube") {
					return youtubeRegx.test(url);
			} else if (type === "linkedin") {
					return linkedinRegx.test(url);
			} else if (type === "wikipedia-w") {
					return wikipediaRegx.test(url);
			}
		},
		chnageShowStatus(id, status) {
			this.confirmDialog().then((value) => {
				if(value) {
					this.$store.dispatch("channels/changeShowStatus", {
						id,
						status
					})
				}
			})
		},
		addLinkToTag() {
			this.tagAddLink.loading = true
			axios
				.post("/graphql", {
						operationName: "",
						query: `
						mutation($tagId: ID!, $type: String!, $url: String!) {
								linkCreate(input: {tagId: $tagId, type: $type, url: $url}) {
										id,
										tagId,
										type,
										url
								}
						}
						`,
						variables: this.tagAddLink.input
				})
				.then(response => {
						if (response.data.data.linkCreate) {
								// commit link update
								this.$store.commit("channels/addLink", {
										id: this.tagAddLink.input.tagId,
										link: response.data.data.linkCreate,
								});
								this.successToast("Link added successfully");
								this.tagAddLink.input = {
										tagId: "",
										type: "",
										url: ""
								};
						} else {
								this.errorToast("Something went wrong");
						}
				}).finally(() => {
					this.tagAddLink.loading = false
					this.tagAddLink.show = false
				});
		},

		linkBulkUpdate() {
			this.tagEditLink.loading = true
            axios
                .post("/graphql", {
                    operationName: "",
                    query: `
            mutation($inputs:[LinkUpdateInput]!) {
                linkBulkUpdate(inputs: $inputs) {
                    id,
                    type,
                    url
                }
            }
            `,
                    variables: { inputs: this.tagEditLink.inputs }
                })
                .then(response => {
                    if (response.data.data.linkBulkUpdate) {
                        for (let updatedLink of response.data.data
                            .linkBulkUpdate) {
                            this.$store.commit("channels/updateLink", {
																tagId: this.tagEditLink.tagId,
																link: updatedLink
														});
                        };

                        this.successToast("Links updated successfully");
												this.toggleEditLinks()
                    } else {
                        this.errorToast("Something went wrong");
                    }
                })
								.catch(error => {
									console.log(error)
									this.errorToast("Something went wrong");
								})
								.finally(() => {
									this.tagEditLink.loading = false
								});
        },

		linkDelete(link) {
			console.log(link)
			const id = link.id
			const tagId = link.tagId
			this.confirmDialog().then(value => {
				if(value) {
					axios
							.post("/graphql", {
									operationName: "",
									query: `
										mutation($id: ID!) {
												linkDelete(id: $id)
										}
									`,
									variables: { id: id }
							})
							.then(response => {
									if (response.data.data.linkDelete) {
											// commit link update
											this.$store.commit("channels/deleteLink", {
													id: tagId,
													linkId: id,
											});
											// update links
											this.tagEditLink.inputs = this.tagEditLink.inputs.filter(link => link.id !== id)

											this.successToast("Link deleted successfully");
									} else {
											this.errorToast("Something went wrong");
									}
							});
				}
			})
		},
		debounceInput: debounce(function (e) {
			this.getChannels(1)
		}, 500),
		getChannels(page) {
			if(page) {
				this.page = page
			}
			if(this.suggested) {
				this.$store.dispatch("channels/getSugestedChannels", {
					skip: (this.page - 1) * this.limit,
					limit: this.limit * 1,
				});
			} else {
				this.$store.dispatch('channels/getChannels', {
					skip: (this.page - 1) * this.limit,
					limit: this.limit * 1,
					query: this.searchQuery,
				})
			}
		},
		toggleAddChannel() {
			this.addChannel.show = !this.addChannel.show
			this.selectedFile = null
			this.addChannelImageUrl = null
			this.addChannel.input = {
				name: "",
				nameBn: "",
				type: "channel",
				thumbImageUrl: "",
				activeYear: "",
				info: "",
				youtubeChannelId: "",
			}
		},
		addChannelValidation(e) {
			e.preventDefault();

			this.$refs.tagRole.validate().then(success => {
				if (success) {
					if (this.selectedFile) {
						const reader = new FileReader();
						reader.readAsDataURL(this.selectedFile);
						reader.onload = async () => {
							this.addChannel.input.thumbImageUrl = await reader.result;
							this.addChannelRequest();
						};
					} else {
						this.addChannelRequest();
					}
				}
			})
		},
		addChannelRequest() {
			this.addChannel.loading = true
			const input = this.addChannel.input
			this.$store.dispatch('channels/addChannel', input).then((res) => {
				this.addChannel.show = false
				this.successToast('Channel added successfully')
			}).catch((err) => {
				this.errorDialog(err.message)
			}).finally(() => {
				this.addChannel.loading = false
			})
		},
		updateChannelValidation(e) {
			e.preventDefault();

			this.$refs.tagRoleUpdate.validate().then(success => {
				if (success) {
					const channelRequiredInputsNames = [
                "Active Year"
            ];

            channelRequiredInputsNames.forEach(n => {
                if (
                    this.channelRequiredInputs.find(item => item.key == n) ==
                    undefined
                ) {
                    this.channelRequiredInputs.push({
                        id: "",
                        key: n,
                        info: ""
                    });
                }
            });
            this.channelRequiredInputs.map(item => {
                if (
                    item.key == "Active Year" &&
                    this.updateChannel.input.activeYear
                )
                    item.info = this.updateChannel.input.activeYear;
            });
            let channelRequiredInputs = this.channelRequiredInputs.filter(
                input => input.info != ""
            );

            let profileUpdateInput = this.channelCustomInputs.concat(
                channelRequiredInputs
            );

            this.updateChannel.input.profileUpdateInput = profileUpdateInput;
            if (this.selectedFile) {
                const reader = new FileReader();
                reader.readAsDataURL(this.selectedFile);
                reader.onload = async () => {
                    this.updateChannel.input.thumbImageUrl = await reader.result;
										this.updateChannelRequest();
								};
						} else {
								this.updateChannelRequest();
						}
				}
			})
		},
		updateChannelRequest() {
			let channelInput = this.updateChannel.input
			if(this.suggested) {
				channelInput.isPublic = true
				channelInput.isActive = true
			}
			axios
			.post("/graphql", {
					operationName: "",
					query: `
			mutation($id: ID!, $input: TagUpdateInput!) {
					tagUpdate(id : $id, input: $input) {
						id
						name
						nameBn
						thumbImageUrl
						displayImageUrl
						type
						slug
						birthPlace
						birthday
						birthYear
						nationality
						activeYear
						lastUpdatedBy{
								id
								name
								email
						}
						profiles{
							id
							tagId
							key
							info
						}
						tagAliases{
								id
								aliasName
						}
						info
						links{
							id
							url
							type
						}
					}
			}
			`,
				variables: {
						id: this.updateChannel.tagId,
						input: channelInput
				}
			})
			.then(response => {
					if (response?.data?.data?.tagUpdate) {
						this.updateChannel.show = false

						if (this.suggested) {
							this.$store.commit('channels/removeSuggestedChannel', response.data.data.tagUpdate.id)
							this.successToast('Channel approved successfully')
						} else {
							// commit update to store
							this.$store.commit('channels/updateChannel', response.data.data.tagUpdate)

							this.successToast('Channel updated successfully')
						}
					} else {
							this.errorToast('Something went wrong')
					}
			});
		},
		toggleUpdateChannel(tag) {
			this.updateChannel.show = !this.addChannel.show
			this.selectedFile = null
			this.addChannelImageUrl = tag.displayImageUrl
			this.updateChannel.input = {
				name: tag.name,
				nameBn: tag.nameBn,
				type: tag.type,
				thumbImageUrl: tag.thumbImageUrl,
				displayImageUrl: tag.displayImageUrl,
				activeYear: tag.activeYear,
				info: tag.info,
				tagAliases: tag.tagAliases || [],
			}
			this.updateChannel.tagId = tag.id

			let lTagP = tag.profiles;

			this.channelCustomInputs = lTagP
					? lTagP.filter(info => {
								if (
										info.key != "Active Year"
								) {
										return info;
								}
								return null;
						})
					: [];
			this.channelRequiredInputs = lTagP
					? lTagP.filter(info => {
								if (
										info.key == "Active Year"
								) {
										return info;
								}
								return null;
						})
					: [];
		},
		toggleActivity(tag) {
			this.userActivityOnSelectedChannel.show = true
			this.userActivityOnSelectedChannel.loading = true
			this.userActivityOnSelectedChannel.title = tag.name
			this.userActivityOnSelectedChannel.tag = tag
			this.getActivities()
		},
		getActivities() {
			this.userActivityOnSelectedChannel.loading = true
			this.$store.dispatch('tags/getTagActivities', {
				id: this.userActivityOnSelectedChannel.tag?.id,
			}).then(({activities, count}) => {
				this.userActivityOnSelectedChannel.activities = activities
				this.userActivityOnSelectedChannel.count = count
				this.userActivityOnSelectedChannel.loading = false
			}).catch((err) => {
				this.userActivityOnSelectedChannel.loading = false
			})
		},
		deleteChannel(id) {
			this.confirmDialog({
        text: "Yes, remove this channel."
      }).then(value => {
        if (value) {
          this.$store.dispatch('channels/deleteChannel', { id, success: this.successToast, error: this.errorToast })
        }
      });
		},
		toggleActivityCollapse(id) {
		this.userActivityOnSelectedChannel.activities = this.userActivityOnSelectedChannel.activities.map(activity => {
			if(activity.id === id) {
			activity.collapse = activity.collapse ? false : true
			}
			return activity
		})
		},
		addNewAlias() {
			this.addChannel.input.tagAliases.push({
				aliasName: '',
			})
		},
		removeAlias(index) {
			this.addChannel.input.tagAliases.splice(index, 1)
		},
		addNewAliasUpdate() {
			this.updateChannel.input.tagAliases.push({
				aliasName: '',
			})
		},
		removeAliasUpdate(index) {
			this.updateChannel.input.tagAliases.splice(index, 1)
		},
		addCustomField() {
			this.addChannel.input.profileInput.push({
				id: "",
				key: "",
				info: ""
			});
		},
		deleteCustomInput(index) {
			this.addChannel.input.profileInput.splice(index, 1);
		},
		handleChange(e) {
			this.addChannelImageUrl = "";

			this.selectedFile = e.target.files[0];
			this.addChannelImageUrl = URL.createObjectURL(this.selectedFile);
		},
		toggleCheckSubStatus(channel, show = true) {
			this.channelSubscriptionStatus.show = show
			if(channel) {
				this.channelSubscriptionStatus.youtubeChannelId = channel.youtubeChannelId
			} else {
				this.channelSubscriptionStatus.youtubeChannelId = ''
			}
		},
		subEndDate(channel) {
			let options = { year: "numeric", month: "long", day: "numeric" };
			let date = new Date(Number(channel.expiredAt));

			return date.toLocaleDateString("en-US", options);
		},
		channelSubscription(channel) {
			if (channel.feedSubscribed) {
				if (channel.expiredAt && channel.subscribedAt) {
					return new Date().getTime() >=
							new Date(Number(channel.expiredAt)).getTime()
							? false
							: true;
				}
			} else {
				return false;
			}
		},
		feedSubscribe(channel, mode, renew) {
			this.$store.dispatch('channels/feedSubscribe', {
				channel,
				mode,
				renew,
				success: this.successToast,
				error: this.errorToast,
			})
		}
	},




}
</script>

<style>
.modal-body{
	overflow: unset;
}
.input-icon {
	border-radius: 6px;
	padding: 2px;
	line-height: 17px;
}
.outline-checkbox {
	border-right: 0px!important;
}
.outline-checkbox .checkbox {
	margin: 6px;
}

#convertShowSuggestionModal .modal-dialog .modal-body{
	overflow: unset;
}


</style>
