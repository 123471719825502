import axios from "axios"

export default {
	namespaced: true,
	state: {
		showSuggestions: {
			loading: false,
			rows: [],
			count: 0,
		},
		tagSuggestions: {
			loading: false,
			rows: [],
			count: 0,
		},
		starSuggestions: {
			loading: false,
			rows: [],
			count: 0,
		},
		videoSuggestions: {
			loading: false,
			rows: [],
			count: 0,
		},
		reports: {
			loading: false,
			rows: [],
			count: 0,
		},
	},
	getters: {
		videoSuggestions: state => state.videoSuggestions,
		showSuggestions: state => state.showSuggestions,
		tagSuggestions: state => state.tagSuggestions,
		starSuggestions: state => state.starSuggestions,
		reports: state => state.reports,
	},
	mutations: {
		setVideoSuggestions(state, { rows, loading = false, count }) {
			if (rows)
				state.videoSuggestions.rows = rows

			if (count)
				state.videoSuggestions.count = count

			state.videoSuggestions.loading = loading
		},
		setShowSuggestions(state, { rows, loading = false, count }) {
			if (rows)
				state.showSuggestions.rows = rows

			if (count)
				state.showSuggestions.count = count

			state.showSuggestions.loading = loading
		},
		removeShowSuggestion(state, { id }) {
			state.showSuggestions.rows = state.showSuggestions.rows.filter(row => row.id !== id)
		},
		removeTagSuggestion(state, { id }) {
			state.tagSuggestions.rows = state.tagSuggestions.rows.filter(row => row.id !== id)
		},
		setTagSuggestions(state, { rows, loading = false, count }) {
			if (rows)
				state.tagSuggestions.rows = rows

			if (count)
				state.tagSuggestions.count = count

			state.tagSuggestions.loading = loading
		},
		setStarSuggestions(state, { rows, loading = false, count }) {
			if (rows)
				state.starSuggestions.rows = rows

			if (count)
				state.starSuggestions.count = count

			state.starSuggestions.loading = loading
		},
		setReports(state, { rows, loading = false, count }) {
			if (rows)
				state.reports.rows = rows

			if (count)
				state.reports.count = count

			state.reports.loading = loading
		},
		removeReport(state, { id }) {
			state.reports.rows = state.reports.rows.filter(row => row.id !== id)
			state.reports.count = state.reports.count - 1
		},
		removeVideoSuggestion(state, { id }) {
			state.videoSuggestions.rows = state.videoSuggestions.rows.filter(row => row.id !== id)
			state.videoSuggestions.count = state.videoSuggestions.count - 1
		},
		removeStarSuggestion(state, { id }) {
			state.starSuggestions.rows = state.starSuggestions.rows.filter(row => row.id !== id)
			state.starSuggestions.count = state.starSuggestions.count - 1
		}
	},
	actions: {
		async fetchVideoSuggestions({ commit }, { page, limit }) {
			commit('setVideoSuggestions', { loading: true })
			axios
				.post("/graphql", {
					query: `
					query($first: Int, $skip: Int){
						videoSuggestions(first: $first, skip: $skip){
						count
						rows{
							id
							type
							value
							count
							video{
								id
								youtubeVideoId
								videoEmbeddable
								show{
									title
									url
									youtubeVideoId
									videoEmbeddable
									thumbImageUrl
								}
							}
							suggestions{
								user{
									name
								}
							}
						}
						}
					}
				`,
					variables: {
						first: limit,
						skip: (page - 1) * limit,
					},
				})
				.then((res) => {
					commit('setVideoSuggestions', {
						rows: res.data.data?.videoSuggestions?.rows || [],
						count: res.data.data?.videoSuggestions?.count || 0,
					})
				})
		},
		async fetchShowSuggestions({ commit }, { page, limit }) {
			commit('setShowSuggestions', { loading: true })
			axios
				.post("/graphql", {
					operationName: "",
					query: `
				query($first: Int,$skip: Int){
					showSuggestions(first: $first, skip: $skip){
					count,
					rows{
						id
						show{
							id
							title
							slug
							thumbImageUrl
							thumbImageUrlLg
							youtubeVideoId
							videoEmbeddable
							url
							releasedYear
							autoApproveEpisode
							isPublic
						}
						tag{
							name
							url
						}
						alias{
							aliasName
						}
						shows_tag {
							tagConnectType
							tag{
								name
								url
							}
							alias{
								aliasName
							}
						}
						video{
							slug
							youtubeVideoId
							videoEmbeddable
							thumbImageUrlLg
						}
						suggestions{
							user{
								name
							}
						}
						type
						count
						name
						storyPlot
						description
						value
					}
				}
			}`,
					variables: {
						first: limit,
						skip: (page - 1) * limit,
					},
				})
				.then((response) => {
					commit('setShowSuggestions', {
						rows: response.data.data?.showSuggestions?.rows || [],
						count: response.data.data?.showSuggestions?.count || 0,
					})
				});
		},
		async fetchTagSuggestions({ commit }, { page, limit }) {
			commit('setTagSuggestions', { loading: true })
			axios
				.post("/graphql", {
					operationName: "",
					query: `
				query($first: Int,$skip: Int){
					tagSuggestions(first: $first, skip: $skip){
						count,
						rows{
							id
							tag{
									id
									name
									thumbImageUrl
									type
									slug
							}
							suggestions{
								user{
									name
								}
							}
							type
							value
							count
							name
							description
							birthday
							birthYear
							birthPlace
							nationality
							activeYear
							thumbImageUrl
						}
					}
				}
			`,
					variables: {
						first: limit,
						skip: (page - 1) * limit,
					},
				})
				.then((response) => {
					commit('setTagSuggestions', {
						rows: response.data.data?.tagSuggestions?.rows || [],
						count: response.data.data?.tagSuggestions?.count || 0,
					})
				});
		},
		async fetchStarSuggestions({ commit }, { page, limit }) {
			commit('setStarSuggestions', { loading: true })
			axios
				.post("/graphql", {
					operationName: "",
					query: `
					query($first: Int,$skip: Int){
						newStarSuggestions(first: $first, skip: $skip){
							count,
							rows{
								id
								type
								thumbImageUrl
								count
								name
								description
								birthday
								birthYear
								birthPlace
								nationality
								activeYear
								suggestions{
									user{
										name
									}
								}
							}
						}
					}
				`,
					variables: {
						first: limit,
						skip: (page - 1) * limit,
					},
				})
				.then((response) => {
					commit('setStarSuggestions', {
						rows: response.data.data?.newStarSuggestions?.rows || [],
						count: response.data.data?.newStarSuggestions?.count || 0,
					})
				});
		},
		async fetchReports({ commit }, { page, limit }) {
			commit('setReports', { loading: true })
			axios
				.post("/graphql", {
					operationName: "",
					query: `
			query($first: Int,$skip: Int) {
				allreports(first:$first, skip: $skip)  {
					count
					rows {
						id
						showsTagsId
						type
						name
						value
						user{
							name
						}
						showstag{
							tagId
							aliasId
							id
							tag {
								name
								url
							}
							show {
								title
									slug
									url
								}
								alias {
									aliasName
								}
							}
						}
					}
				}
			`,
					variables: {
						first: limit,
						skip: (page - 1) * limit,
					},
				})
				.then((response) => {
					if (response.data.data?.allreports) {
						commit('setReports', {
							rows: response.data.data?.allreports?.rows || [],
							count: response.data.data?.allreports?.count || 0,
						})
					}
				});
		},
		async rejectShowSuggestion({ commit }, { input, success = console.log, error = console.log, type = 'show' }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $reason: String, $addAsShow: Boolean, $convertAsShow: Boolean) {
								deleteSuggestion(id: $id, reason: $reason, addAsShow: $addAsShow, convertAsShow: $convertAsShow )
						}
					`,
					variables: input,
				})
				.then((response) => {
					if (response.data.data?.deleteSuggestion) {
						if (type == 'show') {
							commit("removeShowSuggestion", input);
							commit('notifications/updateShowInfoSuggNotification', -1, { root: true })
						} else if (type == 'tag') {
							commit("removeTagSuggestion", input);
							commit('notifications/updateStarChannelInfoSuggNotification', -1, { root: true })
						}
						if(input.convertAsShow) {
							success('Successfully converted as show');
						} else {
							success('Successfully rejected');
						}
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async approveReport({ commit }, { input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation reportAction($input: reportAction!) {
						reportAction(reportInput: $input)
					}
				`,
					variables: { input: { id: input.id, approve: true } },
				})
				.then((response) => {
					if (response.data.data?.reportAction) {
						commit("removeReport", input);
						commit('notifications/updateReportNotification', -1, { root: true })
						success('Successfully approved');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async rejectReport({ commit }, { input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation reportAction($input: reportAction!) {
							reportAction(reportInput: $input)
						}
				`,
					variables: { input: { id: input.id, approve: false } },
				})
				.then((response) => {
					if (response.data.data?.reportAction) {
						commit("removeReport", input);
						commit('notifications/updateReportNotification', -1, { root: true })
						success('Successfully rejected');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async approveVideo({ commit }, { input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation($id: ID!, $input: VideoSuggestionInput!){
						approveVideoSuggestion(id: $id, input: $input)
					}`,
					variables: {
						id: input.id,
						input: {
							slug: input.slug,
						}
					},
				})
				.then((response) => {
					if (response.data.data?.approveVideoSuggestion) {
						commit("removeVideoSuggestion", input);
						commit('notifications/updateVideoInfoSuggNotification', -1, { root: true })
						success('Successfully approved');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async rejectVideo({ commit }, { input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $reason: String, $addAsShow: Boolean) {
								deleteSuggestion(id: $id, reason: $reason, addAsShow: $addAsShow)
						}
					`,
					variables: {
						id: input.id,
						reason: input.reason,
						addAsShow: input.addAsShow
					},
				})
				.then((response) => {
					if (response.data.data?.deleteSuggestion) {
						commit("removeVideoSuggestion", input);
						commit('notifications/updateVideoInfoSuggNotification', -1, { root: true })
						success('Successfully rejected');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async rejectStarSuggestion({ commit }, { input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $reason: String, $addAsShow: Boolean) {
								deleteSuggestion(id: $id, reason: $reason, addAsShow: $addAsShow)
						}
					`,
					variables: {
						id: input.id,
						reason: input.reason,
						addAsShow: false
					},
				})
				.then((response) => {
					if (response.data.data?.deleteSuggestion) {
						commit("removeStarSuggestion", input);
						commit('notifications/updateNewStarSuggNotification', -1, { root: true })
						success('Successfully rejected');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async approveStarSuggestion({ commit }, { tagId, input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $input: NewStarApproveInput) {
								approveNewStarSuggestion(id : $id, input: $input)
						}
					`,
					variables: {
						id: tagId,
						input: input,
					},
				})
				.then((response) => {
					if (response.data.data?.approveNewStarSuggestion) {
						commit("removeStarSuggestion", input);
						commit('notifications/updateNewStarSuggNotification', -1, { root: true })
						success('Successfully approved');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async approveShowSuggestion({ commit }, { suggestion, success = console.log, error = console.log }) {
			let ShowSuggestionApproveInput = {
				id: suggestion.id,
				value:
					(suggestion.type == "description"
						? suggestion.description
						: suggestion.type == "storyPlot"
							? suggestion.storyPlot
							: suggestion.value) || "",
				slug:
					suggestion.type === "title" ||
						suggestion.type === "releasedYear"
						? suggestion.show.slug
						: "",
			};
			if (suggestion.type === "episode") {
				ShowSuggestionApproveInput.autoApproveEpisode =
					suggestion.autoApproveEpisode || false;

				if (suggestion.slug) {
					ShowSuggestionApproveInput.value = suggestion.slug;
				}
			}
			if (
				ShowSuggestionApproveInput.value === "custom" &&
				suggestion.type === "type"
			) {
				ShowSuggestionApproveInput.value = this.customShowType;
			}
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($input: ShowSuggestionApproveInput!) {
							approveShowSuggestion( input: $input)
						}
					`,
					variables: {
						input: ShowSuggestionApproveInput
					},
				})
				.then((response) => {
					if (response.data.data?.approveShowSuggestion) {
						commit("removeShowSuggestion", suggestion);
						commit('notifications/updateShowSuggNotification', -1, { root: true })
						success('Successfully approved');
					} else {
						error(response.data.errors[0].message);
					}
				});
		},
		async approveTagSuggestion({ commit }, { input, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $value: String , $type: String) {
								approveTagSuggestion(id: $id, value: $value , type: $type)
						}
					`,
					variables: input,
				})
				.then((response) => {
					if (response.data.data?.approveTagSuggestion) {
						commit("removeTagSuggestion", input);
						commit('notifications/updateStarChannelInfoSuggNotification', -1, { root: true })
						success('Successfully approved');
					} else {
						error(response.data.errors[0].message);
					}
				})
		}



	}
}
