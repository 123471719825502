// const isNameValid = helpers.regex('isNameValid', /^[a-zA-Z. ]*$/)
// const isNameValid2 = helpers.regex('isNameValid', /^[^0-9\!\@\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]*$/)
const facebookRegx = /(?:https:\/\/)(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const twitterRegx = /(?:https:\/\/)(?:www\.)?twitter\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const instagramRegx = /(?:https:\/\/)(?:www\.)?instagram\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const snapchatRegx = /(?:https:\/\/)(?:www\.)?snapchat\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const youtubeRegx = /(?:https:\/\/)(?:www\.)?youtube\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const linkedinRegx = /(?:https:\/\/)(?:www\.)?linkedin\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const wikipediaRegx = /(?:https:\/\/)(?:www\.)?en.wikipedia\.org\/wiki\/([\w%]+)/
const validLInkRegx = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;

export {
	facebookRegx,
	twitterRegx,
	instagramRegx,
	snapchatRegx,
	youtubeRegx,
	linkedinRegx,
	wikipediaRegx,
	validLInkRegx
}
