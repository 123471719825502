<template>
  <b-card>
		<edit-show :input="editShow.input" :show="editShow.show" :approve="editShow.approve" @close="toggleEditShow()" />
		<template v-slot:header>
			<h3 @click="getShows" title="click to refresh shows" class="text-white">Shows</h3>
			<div class="d-flex">
				<b-link  v-if="suggested == 0" to="/shows?suggested=1"><b-button variant="success" class="mr-2">REQUESTED SHOWS <b-badge variant="danger">{{ newShowSuggNotification }}</b-badge></b-button></b-link>
				<b-link v-else to="/shows"><b-button variant="success" class="mr-2">ACTIVE SHOWS</b-button></b-link>
				<add-show @showAdded="showCreate" />
			</div>
		</template>

		<div v-if="suggested == 0" class="search-form mt-1">
      <b-input-group>
        <b-input-group-prepend>
          <b-button v-if="searchQuery != ''" @click="searchQuery = ''" variant="outline-primary">
            <feather-icon  icon="XIcon" />
          </b-button>

          <b-button v-else  variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="Search by name..." @input="debounceInput" v-model.trim="searchQuery"/>
        <b-input-group-append>
					<!-- include requested tick-->
					<span class="outline-checkbox btn-outline-primary">
							<b-form-checkbox
								v-model="includeInactiveShows"
								class="checkbox"
							>
								<span class="pl-1">Include Requested</span>
							</b-form-checkbox>
					</span>

					<span class="outline-checkbox btn-outline-primary">
							<b-form-checkbox
								v-model="emptyBanglaTitle"
								class="checkbox"
							>
								<span class="pl-1">Empty Bangla Title</span>
							</b-form-checkbox>
					</span>

          <b-button variant="primary" @click="getShows(true)">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
			<br>
			<div>
				<p :key="tag . name || tag.aliasName" v-for="tag in selectedTags" data-role="tagsinput"
					class="badge badge-success p-1 mr-1">{{ tag . name || tag.aliasName }}
					<span class="m-2 mr-0">
						<feather-icon icon="XIcon" @click="removeSelectedTagsForQuery(tag)" class="pointer" />
					</span></p>
			</div>
			<vue-bootstrap-typeahead @hit="selectTagsForQuery" ref="selectTagsForQuery"
				:serializer="tag => tag.name" :data="allTags" v-model="select_tag_for_query" placeholder="Filter by tag.." >
				<template slot="suggestion" slot-scope="{ data, htmlText }">
						<div class="d-flex align-items-center">
						<img
								class=""
								:src="data.thumbImageUrl"
								style="width: 40px; height: 40px;" />

						<span class="ml-4" v-html="htmlText"></span>
						</div>
				</template>
			</vue-bootstrap-typeahead>
    </div>
		<div class="page-action-buttons mt-1">
			<b-button v-if="suggested" variant="primary" @click="approveShowModal([], true)" class="mr-1">Approve Selected Shows <b-badge>{{ selectedShows.length }}</b-badge></b-button>
			<b-button v-if="suggested" variant="danger" @click="selectShowsForDelete([], true)">Delete Selected Shows <b-badge>{{ selectedShows.length }}</b-badge></b-button>
		</div>
		<br>
    <table class="table table-bordered table-striped bordered  table-hover text-center shows-table" style="width: 100%; overflow: auto;">
      <thead>
        <tr>
          <th> </th>
          <th>ID</th>
          <th>Title</th>
          <th>Type</th>
          <th>Thumbnail</th>
          <th>Slug</th>
          <th>Channel</th>
          <th v-if="suggested == 0">Public</th>
          <th v-if="suggested != 0">Stars</th>
          <th v-if="suggested != 0">Episodes</th>
          <th v-if="suggested != 0">Count</th>
          <th>Last Updated By</th>
          <th>Video</th>
          <th>Actions </th>
        </tr>
      </thead>
      <tr v-if="loading">
      <div class="table-spinner">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      </tr>

      <tbody>
        <tr v-for="show in shows" :key="show.id" :id="show.id">
          <th> <b-form-checkbox :selected="show.selected" @change="toggleSelectShow(show.id)"/> </th>
          <td>{{ show.id }}</td>
          <td>
            <b-link :href="(suggested == 0) ? getPublicUrl(show.url) :''" target="__blank">{{ show.title }}</b-link>
          </td>
          <td>{{ show.type }}</td>
          <td>
            <a :href="largeImage(show.thumbImageUrl)" target="_blank">
              <img class="show-thumbnail" :src="show.thumbImageUrl" :alt="show.title" width="170px">
            </a>
          </td>
          <td>{{ show.slug }}</td>
          <td>{{ show.channel.tag.name }}</td>
          <td v-if="suggested == 0">
            <div class="dashboard-action d-block">
              <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" v-model="show.isPublic"  :id="show.id">
                  <label class="custom-control-label" :for="show.id"  @click="chnageShowStatus(show, !show.isPublic)" ></label>
              </div>
            </div>
          </td>
          <td v-if="suggested != 0">
            <div class="tag-list">
                <p :key="tag . name || tag.aliasName" v-for="tag in uniqueTags(show)" data-role="tagsinput" class="badge badge-success">
                  {{ tag . name || tag.aliasName }}
                </p>
            </div>
          </td>
          <td v-if="suggested != 0">
            <div class="tag-list">
                <p v-if="show.episodeList" data-role="tagsinput" class="badge badge-success inline-block">
                  {{ show.episodeList }}
                </p>
                <p v-else data-role="tagsinput">{{ show.duration }}</p>
            </div>
          </td>
          <td v-if="suggested != 0">
            {{ suggestioncounts(show)}}
          </td>
          <td>{{show . lastUpdatedBy && show.lastUpdatedBy.name }}</td>
          <td>
            <div class="dashboard-action d-flex">
              <a v-if="show.videoEmbeddable" @click="videoPlay(show)">
                <feather-icon icon="PlayCircleIcon"
                size="18"/>
              </a>
              <a v-else :href="show.youtubeVideoId ? 'https://www.youtube.com/watch?v='+ show.youtubeVideoId : show.externalLink" target="_blank">
                <feather-icon icon="PlayCircleIcon"
                size="18"/>
              </a>
              <a v-if="show.youtubeVideoId" @click="copyToClipboard(show.youtubeVideoId)" title="copy youtube id">
                <feather-icon icon="ClipboardIcon"
                size="18"/>
              </a>
            </div>
          </td>
          <td>
            <div class="dashboard-action d-flex">
							<!--edit button-->
							<a @click="toggleEditShow(show)" title="Edit show details.">
								<feather-icon icon="EditIcon" />
							</a>
							<!--Episods-->
							<episodes :show="show"/>
              <!-- approve button-->
              <a v-if="suggested" @click="approveShowModal(show)" title="Approve show suggestion.">
                <feather-icon icon="CheckCircleIcon" />
              </a>

              <!-- convert show-->
              <a  v-if="suggested" @click="convertShowSuggestionModalM(show)" title="Convert show suggestion. It will open a modal.">
                <feather-icon icon="CopyIcon" />
              </a>

              <a class="trash" title="Delete this show." @click="selectShowsForDelete(show)">
                <feather-icon icon="TrashIcon" size="18"/>
              </a>
              <a  @click="toggleActivity(show)" title="View user activity on this show.">
                <feather-icon icon="ActivityIcon"
                size="18"/>
              </a>
              <a v-if="show.url" @click="copyToClipboard(getPublicUrl(show.url), 'Show url copied to clipboard.')" title="copy show url">
                <feather-icon icon="ClipboardIcon"
                size="18"/>
              </a>
            </div>
          </td>
        </tr>
				<tr v-if="shows && shows.length <= 0 && !loading">
					<td colspan="14" class="text-center">No shows found.</td>
				</tr>
      </tbody>

    </table>
		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="count" :limit="limit" :page="page"/>
		</template>

    <player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>

    <!-- show user activities -->
    <b-modal
      v-model="userActivityOnSelectedShow.show"
      ok-only
      ok-title="Close"
      size="lg"
      :title="'User Activities - ' + userActivityOnSelectedShow.title"
    >
      <b-table striped bordered  v-if="!userActivityOnSelectedShow.loading" responsive="sm" :items="userActivityOnSelectedShow.activities" :fields="userActivityOnSelectedShow.fields" class="text-center">
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ new Date(Number(data.item.createdAt)).toLocaleString() }}
          </span>
        </template>

        <template #cell(person)="data">
          <span class="text-nowrap">
            {{ (data.item.user) ? data.item.user.name : (data.item.channel) ? data.item.channel.tag.name : '' }}
          </span>
        </template>

        <template #cell(details)="data">
          <div @click="toggleActivityCollapse(data.item.id)" class="activity-details text-center" :class="data.item.collapse ? 'activity-details-collapse': ''">
          <p >
            {{ data.item.details }}
          </p>
          <feather-icon v-if="data.item.details" class="icon" icon="ChevronDownIcon"
                size="18"/>
          </div>
        </template>
      </b-table>
      <div v-else class="p-2 text-center">loading...</div>
    </b-modal>
    <!--  show user activities end -->

    <!--Video Delete confirm modal-->
    <b-modal
      id="modal-prevent-closing"
      ref="showDeleteModal"
      :title="'Delete Show '+ (suggested != 0 ? 'Suggestion' : '')"
      ok-title="Delete"
      cancel-variant="outline-secondary"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleShowDelete"
    >
      <div class="selectedshows">
        <div class="show-details text-center d-flex" style="margin-bottom:5px" v-for="show in showDeleteModalShows" :key="show.id">
          <div class="show-img">
              <img class="w-200px m-auto" :src="show.thumbImageUrl" :alt="show.title">
          </div>
          <h4 class="mt-2 mb-3">
            {{ show.title }}
          </h4>
        </div>
				<div class="form-group" v-if="suggested != 0">
					<label for="reason">Reason</label>
					<input type="text"
							v-model.trim="showDeleteModal.reason"
							class="form-control video-parent" :class="showDeleteModal.reason ? 'is-valid' : ''" id="reason">
				</div>

      </div>

      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          v-if="suggested == 0"
          label="Redirect URL"
          label-for="redirect-input"
          invalid-feedback="Redirect URL.."
        >
          <b-form-input
            id="redirect-input"
            v-model="showDeleteModal.redirect"
          />
        </b-form-group>
      </form>
    </b-modal>
		<b-modal
			id="approveShowModal"
			ref="approveShowModal"
			title="Are you sure you want to approve this show?"
			cancel-title="Close"
			cancel-variant="outline-secondary"
			ok-title="Approve"
			@ok="approveShows"
		>
				<div class="selectedshows">
					<div class="show-details text-center" style="margin-bottom:5px" v-for="show in showApproveModalShows" :key="show.id">
						<div class="show-img">
								<img class="w-200px m-auto" :src="show.thumbImageUrl" :alt="show.title">
						</div>
						<h4 class="mt-2 mb-1">
							{{ show.title }}
						</h4>
					</div>
				</div>
		</b-modal>

		<b-modal
			id="convertShowSuggestionModal"
			ref="convertShowSuggestionModal"
			title="Save As Episode"
			cancel-title="Close"
			cancel-variant="outline-secondary"
			ok-title="Save Changes"
			@ok="saveAsEpisode"
			class="overflow-unset"
			no-close-on-backdrop
		>
				<div class="selectedshows">
					<div class="show-details text-center" style="margin-bottom:5px">
						<div class="show-img">
								<img class="w-200px m-auto" :src="convertShowSuggestionShow.thumbImageUrl" :alt="convertShowSuggestionShow.title">
						</div>
						<h4 class="mt-2 mb-1">
							{{ convertShowSuggestionShow.title }}
						</h4>
					</div>
				</div>
				<p class="text-center" v-if="convertShowSuggestionModal.loadingEpisodes">Loading Episodes ..</p>
				<div class="form-group" v-if="convertShowSuggestionShow && convertShowSuggestionShow.episodes && convertShowSuggestionShow.episodes.length > 1">
						<label for="checkbox for convert all">convert all episode</label>
						<input type="checkbox"
								v-model="convertShowSuggestionModal.convertAll" id="checkbox for convert all">
				</div>
				<div class="select-show-form mt-1">

					<!--search input -->
					<label for="title">Select Show</label>
					<input type="text"
							v-model.trim="convertShowSuggestionModal.searchInput"
							v-on:keyup="searchShowsForSelect"
							class="form-control video-parent" :class="convertShowSuggestionModal.parent ? 'is-valid' : ''" id="title">
					<div class="result-show" v-if="convertShowSuggestionModal.shows.length">
						<div  v-for="showForSelect in convertShowSuggestionModal.shows" @click="selectParent(showForSelect)" class="d-flex align-items-center">
								<div>
									<img class="w-100px" :src="showForSelect.thumbImageUrl" alt=" showForSelect.title">
								</div>
								<div>
										<h5 class="p-1">
												{{
														showForSelect.title
												}}
										</h5>

								</div>

						</div>
					</div>
					<b-form-group
					v-if="!convertShowSuggestionModal.convertAll && convertShowSuggestionShow && convertShowSuggestionShow.episodes && convertShowSuggestionShow.episodes.length > 1"
            label="Select Episode"
            label-for="select-slugs"
            class="mb-2"
          >
            <v-select
						@input="updateSaveAsEpisodeInputSlug($event)"
              id="select-slugs"
              v-model="convertShowSuggestionModal.episodeSlug"
              multiple
              :options="convertShowSuggestionShow.episodes ? convertShowSuggestionShow.episodes.map((item) => item.slug) : []"
            />
          </b-form-group>
					<!--slug -->
					<label for="slug">Slug</label>
					<input type="text"
							v-model.trim="convertShowSuggestionModal.slug"
							class="form-control video-parent" :class="convertShowSuggestionModal.slug ? 'is-valid' : ''" id="slug">

				</div>
		</b-modal>
  </b-card>




</template>

<script>
import {  BFormCheckbox, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

import "vue-select/dist/vue-select.css";
import { mapGetters } from 'vuex'
import axios from 'axios'
import showsForAdminQuery from '@/graphql/query/shows/showsForAdmin'
import requestedShowsQuery from '@/graphql/query/shows/requestedShows'

import AddShow from '@/components/shows/AddShow.vue'
import EditShow from '@/components/shows/EditShow.vue'
import searchShowsQuery from '@/graphql/query/shows/searchShow'
import Episodes from '@/components/shows/Episodes.vue'

export default {
  data() {
    return {
			selected: ['foo'],
      select_tag_for_query: "",
      selectedShow: {},
			emptyBanglaTitle: false,
			includeInactiveShows: false,
      showVideoPlayer: false,
      showDeleteModal: {
        reason: null,
        redirect: null,
				reason: null,
      },
      showDeleteModalShows: [],
      userActivityOnSelectedShow: {
        show: false, // visibility of modal
        title: '',
        activities : [],
        fields: [
          { key: 'createdAt', label: 'Date' },
          { key: 'activity', label: 'Activity' },
          { key: 'childSlug', label: 'Episode' },
          { key: 'person', label: 'User/Channel' },
          { key: 'details', label: 'Details' },
        ],
        loading: false
      },
			editShow: {
				show: false,
				approve: false,
				input: {},
			},
			showApproveModalShows: [],
			convertShowSuggestionShow: {},
			convertShowSuggestionModal: {
				searchInput: '',
				slug: '',
				shows: [],
				parent: null,
				convertAll: false,
				episodeSlug: '',
				loadingEpisodes: false,
			},
			selectedTags: [],
			selectedTagsForQS: [],
    }
  },
  mounted() {
    this.getShows()
  },
  components: {
    BFormCheckbox,
		AddShow,
		EditShow,
		Episodes,
		BFormGroup,
		vSelect
  },
  computed: {
    ...mapGetters('shows', ['shows', 'count', 'loading', 'selectedShows']),
    ...mapGetters('tags', ['allTags']),
		...mapGetters('notifications', ['newShowSuggNotification']),
		suggested() {
			return this.$route.query?.suggested || 0
		},
		page: {
			get() {
				return (this.$route.query?.page || 1) * 1;
			},
			set(page) {
				if(page == this.page) return;
				this.$router.push({ query: { ...this.$route.query, page } })
			}
		},
		limit: {
			get() {
				return (this.$route.query?.limit || 10) * 1;
			},
			set(limit) {
				if(limit == this.limit) return;
				this.$router.push({ query: { ...this.$route.query, limit } })
			}
		},
		searchQuery: {
			get() {
				return this.$route.query?.searchQuery || '';
			},
			set(searchQuery) {
				if(searchQuery == this.searchQuery) return;
				this.$router.push({ query: { ...this.$route.query, searchQuery } })
			}
		},
  },
  watch: {
    page () {
      this.getShows()
    },
		suggested () {
			this.getShows()
		},
		searchQuery (newVal) {
			if (newVal == '') {
				this.getShows(true)
			}
		},
		emptyBanglaTitle() {
			this.getShows(true)
		},
		includeInactiveShows() {
			this.getShows(true)
		},
  },
  methods: {
		linkGen(page) {
			return {
				name: 'shows',
				query: {
					page,
					limit: this.limit,
					suggested: this.suggested,
					searchQuery: this.searchQuery,
				},
			}
		},
		debounceInput: debounce(function (e) {
			this.getShows(true)
		}, 500),
    getShows(pageReset = false) {
			if (pageReset) {
				this.page = 1
			}
			if(this.suggested == 0 && (
				 this.searchQuery != '' ||
				 this.emptyBanglaTitle ||
				 this.includeInactiveShows)
				 ){
					this.getSearchShows()
			} else {
				this.$store.commit('shows/setLoading', true)
				if(this.suggested == 1) {
					axios.post('/graphql', {
						query: requestedShowsQuery,
						variables: {
							first: this.limit,
							skip: (this.page - 1) * this.limit,
						},
					}).then(response => {
						const showsData = response.data.data.requestedShows
						this.$store.commit('shows/setShows', {page: this.page, ...showsData, suggested: true})
					})
				} else {
					axios.post('/graphql', {
						operationName: '',
						query: showsForAdminQuery,
						variables: {
							first: this.limit,
							skip: (this.page - 1) * this.limit,
							tags: this.selectedTagsForQS,
						},
					}).then((response) => {
						const showsData = response.data.data.showsForAdmin
						this.$store.commit('shows/setShows', {page: this.page, ...showsData})
					})
				}
			}


    },

		getSearchShows(pageReset = false) {
			this.selectedTagsForQS = []
			this.selectedTags = []
			// search show action
			this.$store.dispatch('shows/searchShows', {
				query: this.searchQuery,
				first: this.limit,
				skip: (this.page - 1) * this.limit,
				emptyBanglaTitle: this.emptyBanglaTitle,
				includeInactiveShows: this.includeInactiveShows,
			})
		},
    changePage(page) {
      this.page = page
      this.getShows()
    },
    suggestioncounts(show) {
      return show.suggestioncounts?.find(sugg => sugg.type === 'show')?.count
    },
    selectShow(show) {
      this.selectedShow = show
    },
    videoPlay(show = {}) {
      this.selectShow(show)
      this.showVideoPlayer = show.id ? true : false
    },
    chnageShowStatus(show, status) {
      const message = status
                    ? "Yes, Activate the show."
                    : "Yes, Deactivate the show."
      this.confirmDialog({
        text: message
      }).then(value => {
        if (value) {
          this.changeShowStatusMutation(show, status).then(() => {
            this.successToast('Show status changed successfully.')

						this.$store.commit('shows/updateIsPublic', {
							id: show.id,
							isPublic: status,
						})
          }).catch((error) => {
            this.errorToast('Something went wrong.', error)
          });
        }
      })
    },
    changeShowStatusMutation(show, status) {
      return axios.post("/graphql", {
        operationName: "",
        query: `
          mutation($showId: ID!, $status: Boolean!) {
              changeStatusOfShow(showId: $showId, status: $status){
                id
                title
                isPublic
              }
          }
          `,
        variables: { showId: show.id, status: status }
       })
    },
    resetModal() {
      this.showDeleteModal = {
        reason: null,
        redirect: null
      }
      this.showDeleteModalShows = []
    },
    handleShowDelete(bvModalEvt) {
      let redirectPath = null;
      if(this.showDeleteModal.redirect) {
        const validUrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+(\.bdshows)\.com(\/[a-z]*\/[a-zA-Z0-9\-]+)?$/;
        const match = this.showDeleteModal.redirect.match(validUrlRegex);

        if (match) {
            redirectPath = match[3].split("/")[2];
        } else {
            this.errorToast('Invalid Redirect URL.');
            bvModalEvt.preventDefault()
            return;
        }
      }

      // show ids to delete
      const showIds = this.showDeleteModalShows.map(show => show.id)

      if(showIds.length == 1) {
        this.$store.dispatch('shows/deleteShow', {id: showIds[0], redirect: this.showDeleteModal.redirect, reason: this.showDeleteModal.reason, success : this.successToast, error: this.errorToast})
      } else {
				this.$store.dispatch('shows/deleteShows', {ids: showIds, redirect: this.showDeleteModal.redirect, reason: this.showDeleteModal.reason, success : this.successToast, error: this.errorToast})
      }
    },
    selectShowsForDelete(show, multiple = false){
      this.$refs.showDeleteModal.show()
      if(!multiple) {
        this.showDeleteModalShows = [show]
      } else {
        this.showDeleteModalShows = this.selectedShows
      }
    },
    toggleSelectShow(id) {
        this.$store.commit('shows/toggleSelectShow', id)
    },
    toggleActivity(show = null) {
      this.userActivityOnSelectedShow.show = !this.userActivityOnSelectedShow.show
      this.userActivityOnSelectedShow.title = show.title
      this.userActivityOnSelectedShow.activities = []
      if(show && this.userActivityOnSelectedShow.show) {
        this.userActivityOnSelectedShow.loading = true
        this.$store.dispatch('shows/getUserActivityOnShow', {id: show.id, success : (activities) => {
          this.userActivityOnSelectedShow.activities = activities
          this.userActivityOnSelectedShow.loading = false
        }, error: this.errorToast})
      }
    },
    toggleActivityCollapse(id) {
      this.userActivityOnSelectedShow.activities = this.userActivityOnSelectedShow.activities.map(activity => {
        if(activity.id === id) {
          activity.collapse = activity.collapse ? false : true
        }
        return activity
      })
    },
		toggleEditShow(show = {}, approve = false) {
			this.editShow.show = !this.editShow.show
			this.editShow.input = show
			if(approve) {
				this.editShow.input.isPublic = true
			}
			this.editShow.approve = approve
		},
    approveShowModal(show, multiple = false) {
			// check length of selected shows
			if(show.id || this.selectedShows?.length) {
				if(!multiple) {
					this.showApproveModalShows = [show]
				} else {
					this.showApproveModalShows = this.selectedShows
				}

				this.$refs.approveShowModal.show()
			} else {
				this.warningToast('No show selected.')
			}
    },
		approveShows() {
			const showIds = this.showApproveModalShows.map(show => show.id)
			this.$store.dispatch('shows/approveShows', {ids: showIds, success : this.successToast, error: this.errorToast})
		},
		uniqueTags(show) {
			return show.tags
					? Array.from(
								new Set(
										show.tags
												.filter(
														tag =>
																tag.type != "channel" && tag.type != "tag"
												)
												.map(tag => tag.id)
								)
						).map(id => {
								return show.tags.find(tag => tag.id === id);
						})
					: [];
		},
		convertShowSuggestionModalM(show) {
			this.resetEpisodeConvertModal()
			this.$refs.convertShowSuggestionModal.show()
			this.convertShowSuggestionShow = show
			this.loadEpisodes(show.id)
		},
		loadEpisodes(id) {
			this.convertShowSuggestionModal.loadingEpisodes = true
			this.$store.dispatch('shows/getEpisodes', {id: id, success : (episodes) => {
				this.convertShowSuggestionShow.episodes = episodes.rows
				this.convertShowSuggestionModal.loadingEpisodes = false
			}, error: this.errorToast})
		},
		selectTagsForQuery(tag) {
			let index = this.selectedTags.indexOf(tag);
			if (index !== -1) return;
			this.selectedTags.push(tag);
			this.selectedTagsForQS = this.selectedTags.map(
					tag => tag.slug
			);
			this.includeInactiveShows = false;
			this.emptyBanglaTitle = false;
			setTimeout(() => {
					this.$refs.selectTagsForQuery.inputValue = "";
					this.searchQuery = "";
			}, 50);
			this.getShows(true);
		},
		removeSelectedTagsForQuery(tag) {
			let index = this.selectedTags.indexOf(tag);
			if (index === -1) return;
			this.selectedTags.splice(index, 1);
			this.selectedTagsForQS = this.selectedTags.map(
					tag => tag.slug
			);
			this.includeInactiveShows = false;
			this.emptyBanglaTitle = false;
			this.getShows(true);
		},
		searchShowsForSelect() {
			this.convertShowSuggestionModal.parent = null
			axios.post("/graphql", {
					operationName: "",
					query: searchShowsQuery,
					variables: {
						query: this.convertShowSuggestionModal.searchInput || "",
						first: 10,
						skip: 0
					}
				}).then(res => {
					res = res?.data?.data?.searchShow
					if (res) {
						this.convertShowSuggestionModal.shows = res.rows
					} else {
						this.convertShowSuggestionModal.shows = []
					}
				}).catch(err => {
					console.log(err)
					this.shows = []
				})
		},
		selectParent(showForSelect) {
			this.convertShowSuggestionModal.parent = showForSelect;
			this.convertShowSuggestionModal.shows = {};
			this.convertShowSuggestionModal.searchInput = showForSelect.title;
		},
		showCreate(show) {
			this.$store.commit('shows/showCreate', show)
			this.toggleEditShow(show, true)
		},
		saveAsEpisode() {
			this.$store.dispatch('shows/saveAsEpisode', {
				id: this.convertShowSuggestionShow?.id,
				episodeInput: {
					convertAll: this.convertShowSuggestionModal.convertAll,
					parentId: this.convertShowSuggestionModal.parent?.id,
					episodeSlug: this.convertShowSuggestionModal.episodeSlug,
					slug: this.convertShowSuggestionModal.slug,
				}, success : (message) => {
					this.getShows()
					this.successToast(message)
					this.resetEpisodeConvertModal()
				}, error: this.errorDialog})

		},
		updateSaveAsEpisodeInputSlug(e = []) {
			if(e.length == 1) {
				this.convertShowSuggestionModal.slug = e[0]
			} else {
				this.convertShowSuggestionModal.slug = ''
			}
		},
		resetEpisodeConvertModal() {
			this.convertShowSuggestionModal = {
						searchInput: '',
						slug: '',
						shows: [],
						parent: null,
						convertAll: false,
						episodeSlug: '',
						loadingEpisodes: false,
					},
			this.convertShowSuggestionShow = {}
		}


  },

}
</script>

<style>
.outline-checkbox {
	border-right: 0px!important;
}
.outline-checkbox .checkbox {
	margin: 6px;
}

#convertShowSuggestionModal .modal-dialog .modal-body{
	overflow: unset;
}
</style>
