
const allUsers = `
  query($first: Int, $skip: Int, $query: String){
    users(first: $first, skip: $skip, query: $query){
      count,
      rows{
        id
        uuid 
        name
        email
        type
        phone
        firebaseUid
        youtubeApiKey
        providerId
        providerUid
        displayImage
        imageId
        reviewCount
        createdAt
      }
    }
  }
`


export default allUsers