
const ownShowsTags = `
  query($first: Int, $skip: Int, $userId: ID){
    ownShowsTags(first: $first, skip: $skip, userId: $userId){
      count
      rows{
        ... on Show{
          id
          title
          isPublic
          thumbImageUrl
          thumbImageUrlLg
          youtubeVideoId
          url
        }
        ...on Tag{
          id
          thumbImageUrl
          name
          type
          url
        }
      }
    }
  }
`


export default ownShowsTags