export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Shows',
    route: 'shows',
    icon: 'FileIcon',
		tag: 'newShowSuggNotification'
  },
	{
		title: 'Unknown Show Suggestions',
		route: 'unknownShowSuggestions',
		icon: 'InfoIcon',
	},
	{
		title: 'Youtube Search',
		route: 'youtubeSearch',
		icon: 'YoutubeIcon',
	},
	{
		title: 'Reviews',
		route: 'reviews',
		icon: 'MessageCircleIcon',
	},
	{
		title: 'Suggestions',
		route: 'showSuggestions',
		icon: 'BellIcon',
		tag: 'infoSuggestionsCount'
	},
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
  },
	{
		title: 'User Activities',
		route: 'userActivities',
		icon: 'ActivityIcon',
	},
	{
		title: 'Tags',
		route: 'tags',
		icon: 'TagIcon',
	},{
		title: 'Stars',
		route: 'stars',
		icon: 'StarIcon',
		tag: 'newStarSuggNotification'
	},{
		title: 'Channels',
		route: 'channels',
		icon: 'TvIcon',
		tag: 'requestedChannel'
	},{
		title: 'Aliases',
		route: 'aliases',
		icon: 'LinkIcon',
	},
  {
    title: 'Websites',
    route: 'websites',
    icon: 'Link2Icon',
  },
	{
		title: 'Files',
		route: 'files',
		icon: 'FileIcon',
	},
	{
		title: 'Youtube Notifications',
		route: 'youtubeNotification',
		icon: 'YoutubeIcon',
		tag: 'pendingYoutubeNotification'
	}
]
