import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import users from './users'
import shows from './shows'
import reviews from './reviews'
import tags from './tags'
import stars from './stars'
import channels from './channels'
import websites from './websites'
import notifications from './notifications'
import files from './files'
import episodes from './episodes'
import aliases from './aliases'
import suggestions from './suggestions'
import youtubeNotification from './youtube-notifications'
import errorLogs from './error-logs'
import userActivities from './user-activities'
import youtubeSearch from './youtube-search'
import unknownShowSuggestions from './unknownShowSuggestions'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
		app,
		appConfig,
		verticalMenu,
		user,
		users,
		shows,
		reviews,
		tags,
		stars,
		channels,
		websites,
		notifications,
		files,
		episodes,
		aliases,
		suggestions,
		youtubeNotification,
		errorLogs,
		userActivities,
		youtubeSearch,
		unknownShowSuggestions,
  },
  strict: process.env.DEV,
})
