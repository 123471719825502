import entityActivities from '../../graphql/query/activities/entityActivities'

export default {
    namespaced: true,
    state: {
      loading: false,
      allTags: [],
      tags: [],
      count: 0,
      page: 1,
    },
    getters: {
      tags: state => state.tags,
      count: state => state.count,
      loading: state => state.loading,
      allTags: state => state.allTags,
      page: state => state.page,
      allStarTags: state =>
        state.allTags.filter(
          tag =>
            tag.type === "actor" ||
            tag.type === "director" ||
            tag.type === "producer" ||
            tag.type === "writer"
        ),
      allTextTags: state =>
        state.allTags.filter(
          tag =>
            tag.type != "actor" &&
            tag.type != "director" &&
            tag.type != "producer" &&
            tag.type != "writer" &&
            tag.type != "channel"

        )
    },
    mutations: {
      setTags (state, {rows, count, page}) {
        state.tags = rows
        state.count = count
        state.page = page
        state.loading = false
      },
      setLoading (state, loading) {
        state.loading = loading
      },
      setAllTags (state, tags) {
        state.allTags = tags
      },
			addTag(state, tag) {
				state.tags.unshift(tag)
				state.count++
			},
			updateTag(state, tag) {
				const index = state.tags.findIndex(t => t.id === tag.id)
				if (index !== -1) {
					state.tags.splice(index, 1, tag)
				}
			},
			removeTag (state, id) {
        state.tags = state.tags.filter(tag => tag.id !== id)
        state.count = state.count - 1
      },
    },
    actions: {
			async addTag ({commit, dispatch}, input) {
			 const addTag  = await axios
				.post("/graphql", {
						operationName: "",
						query: `
						mutation($input: TagInput!) {
							tagCreate(input: $input) {
								id
								name
								thumbImageUrl
								type
								slug
								tagAliases{
									id
									aliasName
								}
								lastUpdatedBy{
									name
								}
							}
						}
						`,
						variables: { input: input }
					})
				if(addTag.data.data?.tagCreate) {
					commit('addTag', addTag.data.data.tagCreate)
					return true
				} else {
					throw new Error(addTag.data.errors[0]?.message)
				}
			},
			async updateTag ({commit, dispatch}, input) {
				const updateTag = await axios
				.post("/graphql", {
						operationName: "",
						query: `
							mutation($id: ID!, $input: TagUpdateInput!) {
								tagUpdate(id : $id, input: $input) {
									name,
									nameBn
									thumbImageUrl,
									displayImageUrl,
									type,
									slug
									lastUpdatedBy{
										id
										name
										email
								}
									tagAliases{
										id
										aliasName
									}
								}
							}
						`,
						variables: {
							id: input.id,
							input: input.input,
						}
					})
				if(updateTag.data.data?.tagUpdate) {
					commit('updateTag', updateTag.data.data.tagUpdate)
					return true
				} else {
					throw new Error(updateTag.data.errors[0]?.message)
				}
			},
      async getAllTags ({commit}) {
        const res = await axios.post("/graphql", {
          operationName: "",
          query: `
            query{
              allTags{
                id,
                name,
                slug,
                type,
                url
                thumbImageUrl,
                displayImageUrl,
                tagAliases{
                  id
                  aliasName
                }
              }
            }
          `,
          variables: {}
        })
        commit('setAllTags', res.data.data.allTags)
      },
			async getTags ({commit, state}, {skip, limit, query}) {
				commit('setLoading', true)
				const res = await axios.post("/graphql", {
					operationName: "",
					query: `
					query($query: String! ,$first: Int,$skip: Int){
						searchTag(query: $query, first: $first, skip: $skip){
							count,
							rows{
								id,
								name,
								nameBn,
								slug,
								type,
								showCount
								lastUpdatedBy{
									id
									name
									email
								}
								tagAliases{
									id
									aliasName
								}
							}
						}
					}`,
					variables: {
						query: query,
						first: limit,
						skip: skip
					}
				})

				commit('setTags', {
					rows: res.data.data.searchTag?.rows || [],
					count: res.data.data.searchTag?.count || 0,
					page: skip/limit + 1
				})
			},
			async deleteTag({commit}, {id, success, error}) {
				await axios.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!) {
							tagDelete(id: $id )
					}
					`,
					variables: {
						id: id,
					}
				}).then(res => {
					if (res.data.data?.tagDelete) {
						commit('removeTag', id)
						success('Tag deleted successfully')
					} else if(res.data.errors) {
						throw new Error(res.data.errors[0].message)
					}
				}).catch(err => {
					error(err)
				})
			},
      async getTagActivities ({commit},  {id, skip, limit}) {
        const activities = await axios.post("/graphql", {
									operationName: "",
									query: entityActivities,
									variables: {
										entityId: id,
										entityType: "tag",
									}
								})
				return {
					activities: activities.data.data?.getActivities?.rows || [],
					count: activities.data.data?.getActivities?.count || 0,
				}
      },

		},
  }
