const entityActivities = `
  query($entityId: ID, $entityType: String, $channels: [ID], $first: Int, $skip: Int, $users: [ID]){
    getActivities(entityId: $entityId, entityType: $entityType, channels: $channels, first: $first, skip: $skip,users: $users){
      count
      rows{
        id
        activity
        details
        userId
        youtubeVideoId
        show{
          url
        }
        tag{
          url
        }
        user{
            name
        }
        channel{
            tag{
                name
            }
        }
        channelId
        entityId
        entityName
        entityType
        entitySlug
        childSlug
        createdAt
      }
    }
  }`


export default entityActivities