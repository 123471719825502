export default {
    namespaced: true,
    state: {
      loading: false,
      aliases: [],
      count: 0,
      page: 1,
    },
    getters: {
      aliases: state => state.aliases,
      count: state => state.count,
      loading: state => state.loading,
      page: state => state.page,
    },
    mutations: {
      setAliases (state, {rows, count}) {
        state.aliases = rows
        state.count = count
        state.loading = false
      },
      setLoading (state, loading) {
        state.loading = loading
      },
			deleteAlias (state, id) {
				state.aliases = state.aliases.filter(alias => alias.id !== id)
			},
			deleteConnections (state, {connections, alias}) {
				state.aliases = state.aliases.map(a => {
					if (a.id == alias.id) {
						let showstags = []
						connections.forEach(connection => {
							showstags = a.showstags.filter(st => st.id !== connection.id)
						})
						a.showstags = showstags
					}
					return a
				})
			},
    },
    actions: {
			async getAliases ({commit, state}, {skip, limit, query}) {
				commit('setLoading', true)
				axios
        .post("/graphql", {
          operationName: "",
          query: `
						query($query: String, $first: Int,$skip: Int) {
							orphanaliases(query: $query, first: $first, skip: $skip)  {
								count
								rows{
									id
									aliasName
									showstags {
											id
											showId
											tagId
											tagConnectType
											show {
												id
												youtubeVideoId
												slug
												title
												url
												thumbImageUrl
												videoEmbeddable
											}
											likeCount
											dislikeCount
											loveCount
									}
								}
							}
						}
						`,
          variables: {"query": query, "first": limit, "skip": skip},
        })
        .then((response) => {
          if (response.data.data?.orphanaliases) {
						commit('setAliases', {
							rows: response.data.data.orphanaliases.rows,
							count: response.data.data.orphanaliases.count,
						})
          }
          commit('setLoading', false)
        });
			},
			async deleteAlias ({commit, state}, {id, success = console.log, error = console.log}) {
				axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation($orphanAliasInput: tagAliasInput!) {
						deleteOrphanAlias(orphanAliasInput: $orphanAliasInput)
					}
					`,
					variables: { orphanAliasInput: { id: id } },
				})
				.then((response) => {
					if (response.data.data?.deleteOrphanAlias) {
						commit('deleteAlias', id)
						success('Alias deleted')
					}else{
						error(response.data.errors[0].message)
					}
				})
				.catch((err) => {
					console.log(err)
					error('Error deleting alias')
				});
			},
			async deleteConnections ({commit, state}, {connections, alias, success = console.log, error = console.log}) {
				axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($connectionInput: [connectionInput]!) {
							removeConnections(connections: $connectionInput)
						}
						`,
					variables: { connectionInput: connections },
				})
				.then((response) => {
					if (response.data.data?.removeConnections) {
						commit('deleteConnections', {connections, alias})
						success('Connections deleted.')
					} else {
						error(response.data.errors[0].message.toString())
					}
				});
			},
			async connectToExisting ({commit, state}, {starId, aliasId, success = console.log, error = console.log}) {
				axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($makeConnetionInput: makeConnetionInput!) {
								makeTagConnection(makeConnetionInput: $makeConnetionInput)
						}
					`,
					variables: {
						makeConnetionInput: { aliasId: aliasId, tagId: starId },
					},
				})
				.then((response) => {
					if (response.data.data.makeTagConnection) {
						commit('deleteAlias', aliasId)
						success('Alias has been connected!.')
					} else {
						error(response.data.errors[0].message.toString())
					}
				});
			}
		},
  }
