import entityActivities from '../../graphql/query/activities/entityActivities'
import favorites from '../../graphql/query/favorites'
import ownShowsTags from '../../graphql/query/users/ownShowsTags'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
      async getUserActivities({ commit }, params) {
          await axios.post("/graphql", {
              operationName: "",
              query: entityActivities,
              variables: {
                first: params.first || null,
                skip: params.skip || 0,
                users: params.users || [],
              }
            }).then(res => {
              if (res.data.data.getActivities) {
                params.success(res.data.data.getActivities.rows, res.data.data.getActivities.count)
              } else {
                params.error("Something went wrong.")
              }
            }).catch(err => {
              params.error("Something went wrong.")
            })
      },
      async getUserFavorites({ commit }, params) {
          await axios.post("/graphql", {
              operationName: "",
              query: favorites,
              variables: {
                first: params.first || null,
                skip: params.skip || 0,
                userId: params.userId,
              }
            }).then(res => {
              const data = {
                favoriteShows: [],
                favoriteTags: [],
                count: 0
              }
              if (res.data.data?.favorites) {
                  data.favoriteShows = res.data.data.favorites.rows.filter(
                      (row) => row.title
                    );
                    data.favoriteTags = res.data.data.favorites.rows.filter(
                      (row) => row.name
                    );
                    data.count = res.data.data.favorites.count;
              }
              params.success(data)
            }).catch(err => {
              params.error("Something went wrong.")
            })
      },
      async getUserOwnShowsTags({ commit }, params) {
        await axios.post("/graphql", {
            operationName: "",
            query: ownShowsTags,
            variables: {
              first: params.first || 10,
              skip: params.skip || 0,
              userId: params.userId,
            }
          }).then(res => {
            const data = {
              shows: [],
              tags: [],
              count: 0
            }
            if (res.data.data?.ownShowsTags) {
                data.shows = res.data.data.ownShowsTags.rows.filter(
                    (row) => row.title
                  );
                  data.tags = res.data.data.ownShowsTags.rows.filter(
                    (row) => row.name
                  );
                  data.count = res.data.data.ownShowsTags.count;
            }
            params.success(data)
          })
          .catch(err => {
            params.error("Something went wrong.")
          })
      }
    },
  }
  