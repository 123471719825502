<template>
  <b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 @click="getWebsites" title="click to refresh" class="text-white">Website Profiles</h3>
			<b-button variant="success" @click="toggleAddWebsite">ADD SITE</b-button>
		</template>
		<br>
		<template>
			<b-table striped bordered  responsive :items="profiles" :fields="fields">
				<template v-slot:cell(image)="data">
					<img class="w-200px" v-if="data.item.image" :src="data.item.image" alt="logo">
				</template>
				<template v-slot:cell(action)="data">
					<b-button variant="primary" class="mr-min" @click="toggleUpdateWebsite(data.item)">Edit</b-button>
					<b-button variant="danger" @click="deleteWebsite(data.item.id)">Delete</b-button>
				</template>
			</b-table>
		</template>
		<br>

		<b-pagination-nav
      v-model="page"
      :link-gen="linkGen"
      :number-of-pages="Math.ceil(count / limit) || 1"
      use-router
    ></b-pagination-nav>
		<show-count :count="count" :limit="limit" :page="page"/>



    <!-- add website-->
    <b-modal
      v-model="addWebsite.show"
      ok-title="Add"
			@ok="validationForm"
      size="sm"
      title="Add Website"
    >
			<!--errorMessage-->
			<div v-if="addWebsite.errorMessage" class="show-details text-center error text-danger">
				{{ addWebsite.errorMessage }}
			</div>
			<validation-observer ref="websiteRole">
				<b-form>
					<b-row>

						<!-- domain -->
						<b-col cols="12">
							<b-form-group
								label="Name"
								label-for="name"
							>
								<validation-provider
									#default="{ errors }"
									name="name"
									rules="required"
								>
									<b-form-input
										id="name"
										v-model="addWebsite.input.name"
										:state="errors.length > 0 ? false:null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<b-form-group
								label="Domain"
								label-for="domain"
							>
								<validation-provider
									#default="{ errors }"
									name="domain"
									rules="required"
								>
									<b-form-input
										id="domain"
										v-model="addWebsite.input.domain"
										:state="errors.length > 0 ? false:null"
										placeholder="Domain"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<div class="form-group">
									<label for="siteLogo">Logo</label>
									<input type="file" class="form-control tag-image" id="siteLogo"
											v-on:change="handleChange" :input="selectedFile" />
									<div class="text-center">
											<img v-if="siteLogoUrl" :src="siteLogoUrl" class="stars-image pt-2" />
									</div>

							</div>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
    </b-modal>
    <!-- add website end -->

    <!-- Edit website-->
    <b-modal
      v-model="updateWebsite.show"
      ok-title="Update"
			@ok="validationEditForm"
      size="sm"
      title="Edit Website"
    >
			<!--errorMessage-->
			<div v-if="updateWebsite.errorMessage" class="show-details text-center error text-danger">
				{{ updateWebsite.errorMessage }}
			</div>
			<validation-observer ref="websiteEditRole">
				<b-form>
					<b-row>

						<!-- domain -->
						<b-col cols="12">
							<b-form-group
								label="Name"
								label-for="name"
							>
								<validation-provider
									#default="{ errors }"
									name="name"
									rules="required"
								>
									<b-form-input
										id="name"
										v-model="updateWebsite.input.name"
										:state="errors.length > 0 ? false:null"
										placeholder="Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<b-form-group
								label="Domain"
								label-for="domain"
							>
								<validation-provider
									#default="{ errors }"
									name="domain"
									rules="required"
								>
									<b-form-input
										id="domain"
										v-model="updateWebsite.input.domain"
										:state="errors.length > 0 ? false:null"
										placeholder="Domain"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<div class="form-group">
									<label for="siteLogo">Logo</label>
									<input type="file" class="form-control tag-image" id="siteLogo"
											v-on:change="handleChange" :input="selectedFile" />
									<div class="text-center">
											<img v-if="siteLogoUrl" :src="siteLogoUrl" class="stars-image pt-2" />
									</div>

							</div>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
    </b-modal>
    <!-- Edit website end -->

  </b-card>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BInputGroup, } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BCardText,
		BRow,
		BCol,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver
  },
	data() {
		return {
      page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 20,
			fields: [
				{ key: 'id', label: 'ID' },
				{ key: 'name', label: 'Name' },
				{ key: 'domain', label: 'Domain' },
				{ key: 'image', label: 'Image' },
				{ key: 'action', label: 'Action' },
			],
			addWebsite: {
				show: false,
				input: {
					name: '',
					domain: '',
				},
				selectedImage: '',
				errorMessage: '',
			},
			updateWebsite: {
				show: false,
				website: {},
				input: {
					name: '',
					domain: '',
				},
				selectedImage: '',
				errorMessage: '',
			},
			siteLogoUrl: '',
			selectedFile: null,
		}
	},
	mounted() {
		this.getWebsites()
	},
	computed: {
    ...mapGetters('websites', ['profiles', 'count', 'loading']),
	},
	methods: {
		linkGen(page) {
			return {
				name: 'websites',
				params: { page },
			}
		},
		toggleAddWebsite() {
			this.addWebsite.show = !this.addWebsite.show
			// reset input
			this.addWebsite.input = {
				name: '',
				domain: '',
			}
			this.addWebsite.errorMessage = ''
			this.selectedFile = null
			this.siteLogoUrl = ''
		},
		toggleUpdateWebsite(website = {}) {
			this.updateWebsite.show = !this.updateWebsite.show
			if(this.updateWebsite.show){
				this.updateWebsite.website = website
				this.updateWebsite.input = {
					name: website.name,
					domain: website.domain,
				}
				this.updateWebsite.errorMessage = ''
				this.selectedFile = null
				this.siteLogoUrl =  website.image
			} else {
				this.updateWebsite.website = {}
				this.updateWebsite.input = {
					name: '',
					domain: '',
				}
				this.updateWebsite.errorMessage = ''
				this.selectedFile = null
				this.siteLogoUrl = ''
			}
		},
		getWebsites() {
			this.$store.dispatch('websites/getWebsites', {
				page: this.page,
				limit: this.limit,
			})
		},
		handleChange(e) {
				this.siteLogoUrl = ''

				this.selectedFile = e.target.files[0];
				if(this.selectedFile) {
						this.siteLogoUrl = URL.createObjectURL(this.selectedFile);
				} else {
						this.siteLogoUrl = ''
				}
		},
		validationForm(e) {
			e.preventDefault()
			this.$refs.websiteRole.validate().then(success => {
				if (success) {
					this.addWebsite.errorMessage = ''
					if (this.selectedFile) {
						const reader = new FileReader();
						reader.readAsDataURL(this.selectedFile);
						reader.onload = async () => {
								this.addWebsite.input.image = await reader.result;
								this.addWebsiteQ()
						}
					} else {
						this.addWebsiteQ()
					}
				}
			})
		},
		validationEditForm(e) {
			e.preventDefault()
			this.$refs.websiteEditRole.validate().then(success => {
				if (success) {
					this.updateWebsite.errorMessage = ''
					if (this.selectedFile) {
						const reader = new FileReader();
						reader.readAsDataURL(this.selectedFile);
						reader.onload = async () => {
								this.updateWebsite.input.image = await reader.result;
								this.updateWebsiteQ()
						}
					} else {
						this.updateWebsiteQ()
					}
				}
			})
		},
		addWebsiteQ() {

			const input = this.addWebsite.input
			this.$store.dispatch('websites/createWebsite', {
				input,
				success: () => {
					this.toggleAddWebsite()
					this.successToast('Website added successfully')
				},
				error: () => {
					this.errorToast('Something went wrong')
				},
			})
		},
		updateWebsiteQ() {
			const input = this.updateWebsite.input
			this.$store.dispatch('websites/updateWebsite', {
				id: this.updateWebsite.website.id,
				input,
				success: () => {
					this.toggleUpdateWebsite()
					this.successToast('Website updated successfully')
				},
				error: () => {
					this.errorToast('Something went wrong')
				},
			})
		},
		deleteWebsite(id) {
			this.confirmDialog({
        text: "Yes, remove profile."
      }).then(value => {
        if (value) {
          this.$store.dispatch('websites/deleteWebsite', { id, success: this.successToast, error: this.errorToast })
        }
      });
		},

	},
}
</script>

<style>

</style>
