
const userQuery = `
    query($firebaseUid: String!) {
        user(firebaseUid: $firebaseUid) {
            id
            name
            email
            type
            displayImage
        }
    }
`


export default userQuery