<template>
	<div class="text-center">
		Showing {{ limit*page-limit+1 }} to {{ (limit*page > count) ? count : limit*page }} of {{ count }} records
	</div>
</template>

<script>

export default {
	name: 'ShowCount',
	props: {
		count: {
			type: Number,
			default: 0
		},
		limit: {
			type: Number,
			default: 10
		},
		page: {
			type: Number,
			default: 1
		}
	}
}
</script>
