export default {
    namespaced: true,
    state: {
				items: [],
        loading: false,
        nextPageToken: null,
				prevPageToken: null,
    },
    getters: {
			items: state => state.items,
			nextPageToken: state => state.nextPageToken,
			prevPageToken: state => state.prevPageToken,
			loading: state => state.loading,
    },
    mutations: {
      setProfiles (state, {items, nextPageToken, prevPageToken}) {
        state.items = items
        state.nextPageToken = nextPageToken
        state.prevPageToken = prevPageToken
        state.loading = false
      },
      setLoading (state, loading) {
        state.loading = loading
      },
    },
    actions: {
			async search({commit, state}, {page, search}) {
				commit('setLoading', true)
				axios
        .post("/graphql", {
          operationName: "",
          query: `
						query($query: String!, $pageToken: String) {
							youTubeVideoSearch(query: $query, pageToken: $pageToken){
								nextPageToken
								prevPageToken
								items{
									id{
											videoId
									}
									existing
									snippet{
										title
										channelTitle
										thumbnails{
										default{
											url
											width
											height
										}
										medium{
											url
											width
											height
										}
										high{
											url
											width
											height
										}
									}
								}
							}
						}
					}
					`,
          variables: { query: search, pageToken: page },
        })
				.then(res => {
					if(res.data.data?.youTubeVideoSearch) {
						commit('setProfiles', res.data.data?.youTubeVideoSearch)
					}
				})
			}

    },
  }
