<template>
	<div>
		<b-table
			v-if="!videoSuggestions.loading && videoSuggestions.count"
			responsive="sm"
			:items="videoSuggestions.rows"
			:fields="fields"
			class="text-center"
			striped
			bordered
		>

			<template v-slot:cell(title)="data">
				<b-link :href="getPublicUrl(data.item.video.show.url)" target="__blank">{{ data.item.video.show.title }}</b-link>
			</template>

			<template v-slot:cell(username)="data">
				{{ data.item.suggestions &&
							data.item.suggestions.length > 0
									? data.item.suggestions[0].user.name
									: "" }}
			</template>

			<template v-slot:cell(tag-alias)="data">
				<div
					class="
						dashboard-action
						d-flex
						justify-content-center
					"
				>
						<a  @click="videoPlay(data.item.video)">
                <feather-icon icon="PlayCircleIcon"
                size="18"/>
            </a>
						<a
							@click="copyToClipboard(data.item.video.youtubeVideoId)"
							title="copy youtube id"
						>
							<feather-icon icon="CopyIcon" size="18" />
						</a>
				</div>
			</template>

			<template v-slot:cell(action)="data">
				<div class="dashboard-action d-flex m-auto">
					<a @click="approveVideo(data.item)" title="approve">
						<feather-icon icon="CheckCircleIcon"
						size="18"/>
					</a>
					<a @click="rejectSuggestionToggle(data.item, true)" title="reject" class="trash">
						<feather-icon icon="XCircleIcon"
						size="18"/>
					</a>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="videoSuggestions.loading">loading...</span>
			<span v-else>No suggestion found</span>
		</div>

		<br>
		<div>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(videoSuggestions.count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="videoSuggestions.count" :limit="limit" :page="page"/>
		</div>

		<b-modal
      v-model="approveVideoModal.show" ref="approveVideoModal" title="Approve Suggestion" @ok="approveSuggestionQ" ok-title="Approve" cancel-title="Cancel">
			<div class="show" v-if="approveVideoModal.item.video">
				<div class="show-details text-center" style="margin-bottom:5px">
					<div class="show-img">
							<img class="w-200px m-auto" :src="approveVideoModal.item.video.show.thumbImageUrl" :alt="approveVideoModal.item.video.show.title">
					</div>
					<h4 class="mt-2 mb-1">
						{{ approveVideoModal.item.video.show.title }}
					</h4>
				</div>
			</div>
			<div class="input">
				<b-form-group
          :label="'Suggetion(' + approveVideoModal.item.type +')'"
          label-for="slug-input"
          invalid-feedback="Slug ..."
        >
          <b-form-input
            id="slug-input"
            v-model="approveVideoModal.slug"
          />
        </b-form-group>
			</div>
		</b-modal>

		<b-modal
      v-model="rejectSuggestion.show" ref="rejectSuggestion" title="Delete Suggestion" @ok="rejectSuggestionQ" ok-title="Delete" cancel-title="Cancel">
			<p>Are you sure you want to delete this suggestion?</p>
			<div class="input">
				<b-form-checkbox
					v-model="rejectSuggestion.addAsShow"
				>
				Add as show suggestion
				</b-form-checkbox>
				<br>
				<b-form-group
          label="Reason"
          label-for="reason-input"
          invalid-feedback="Reason ..."
        >
          <b-form-input
            id="reason-input"
            v-model="rejectSuggestion.reason"
          />
        </b-form-group>
			</div>
		</b-modal>
    <player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>
	</div>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BInputGroup, } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			page: Number(this.$route.query.page) || 1,
			limit: Number(this.$route.query.limit) || 20,
			fields: [
				{ key: 'title', label: 'Show Title' },
				{ key: 'tag-alias', label: 'video' },
				{ key: 'type', label: 'Type' },
				{ key: 'value', label: 'Suggestion' },
				{ key: 'username', label: 'User' },
				{ key: 'count', label: 'Count' },
				{ key: 'action', label: 'Action' },
			],
			selectedShow: {},
			showVideoPlayer:false,
			rejectSuggestion: {
				show: false,
				reason: '',
				id: null,
			},
			approveVideoModal: {
				show: false,
				id: null,
				slug: '',
				item: {},
			}
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		...mapGetters('suggestions', ['videoSuggestions']),
	},
	watch: {
		page() {
			this.loadData()
		},
	},
	methods: {
		linkGen(page) {
			return {
				name: 'videoSuggestions',
				query: {
					page,
					limit: this.limit,
				},
			}
		},
		loadData() {
			this.$store.dispatch('suggestions/fetchVideoSuggestions', {
				page: this.page,
				limit: this.limit,
			})
		},
		approveVideo(video) {
			this.approveVideoModal.item = video
			this.approveVideoModal.id = video.id
			this.approveVideoModal.show = true
			this.approveVideoModal.slug = video.value
		},
		approveSuggestionQ() {
			this.$store.dispatch('suggestions/approveVideo', {input: this.approveVideoModal, success: this.successToast, error: this.errorToast})
		},
		rejectSuggestionToggle(video = {}, show = false) {
			this.rejectSuggestion.show = show
			this.rejectSuggestion.id = video.id
			this.rejectSuggestion.addAsShow = false
			this.rejectSuggestion.reason = ''
		},
		rejectSuggestionQ() {
			this.$store.dispatch('suggestions/rejectVideo', {input: this.rejectSuggestion, success: this.successToast, error: this.errorToast})
		},
    videoPlay(show = {}) {
      this.selectedShow = show
      this.showVideoPlayer = show.id ? true : false
    },
	},


}


</script>
