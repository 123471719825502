<template>
	<div>
		<b-table
			v-if="!starSuggestions.loading && starSuggestions.count"
			responsive="sm"
			:items="starSuggestions.rows"
			:fields="fields"
			class="text-center"
			striped
			bordered
		>

			<template v-slot:cell(username)="data">
				{{ data.item.suggestions &&
							data.item.suggestions.length > 0
									? data.item.suggestions[0].user.name
									: "" }}
			</template>

			<template v-slot:cell(thumbImageUrl)="data">
				<a :href="data.item.thumbImageUrl">
					<img style="max-height: 95px" v-if="data.item.thumbImageUrl" :src="data.item.thumbImageUrl" :alt="data.item.name">
					<svg
						style="max-height: 95px"
						v-else
						aria-hidden="true"
						focusable="false"
						data-prefix="fas"
						data-icon="user"
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
						class="
								svg-inline--fa
								fa-user fa-w-14
						"
				>
						<path
								fill="currentColor"
								d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
								class=""
						></path>
				</svg>
				</a>
			</template>
			<template v-slot:cell(action)="data">
				<div class="dashboard-action d-flex m-auto">
					<a @click="toggleUpdateStar(data.item, true)" title="approve">
						<feather-icon icon="CheckCircleIcon"
						size="18"/>
					</a>
					<a @click="rejectSuggestionToggle(data.item, true)" title="reject" class="trash">
						<feather-icon icon="XCircleIcon"
						size="18"/>
					</a>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="starSuggestions.loading">loading...</span>
			<span v-else>No suggestion found</span>
		</div>

		<br>
		<div>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(starSuggestions.count / limit) || 1"
				use-router
			></b-pagination-nav>

			<show-count :count="starSuggestions.count" :limit="limit" :page="page"/>
		</div>
		<b-modal
      v-model="rejectSuggestionModal.show" ref="rejectSuggestionModal" title="Delete Suggestion" @ok="rejectSuggestionQ" ok-title="Delete" cancel-title="Cancel">
			<p>Are you sure you want to delete this suggestion?</p>
			<div class="input">
				<br>
				<b-form-group
          label="Reason"
          label-for="reason-input"
          invalid-feedback="Reason ..."
        >
          <b-form-input
            id="reason-input"
            v-model="rejectSuggestionModal.reason"
          />
        </b-form-group>
			</div>
		</b-modal>



		<!-- update tag -->
		<b-modal
			v-model="updateStar.show"
			ok-title="Approve"
			size="lg"
			title="Approve Star"
			@ok="updateStarValidation"
			:ok-disabled="updateStar.loading"
		>

		<validation-observer ref="tagRoleUpdate">
			<b-form>
				<b-row>
					<b-col cols="12">
						<b-row>
							<b-col cols="4">
								<b-form-group
									label="Name"
									label-for="name"
								>
									<validation-provider
										#default="{ errors }"
										name="name"
										rules="required|regex:^[a-zA-Z0-9 ]+$"
									>
										<b-form-input
											id="name"
											v-model="updateStar.input.name"
											:state="errors.length > 0 ? false:null"
											placeholder="Star name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="4">
								<b-form-group
									label="Bangla Name"
									label-for="nameBn"
								>
									<validation-provider
										#default="{ errors }"
										name="nameBn"
										rules=""
									>
										<b-form-input
											id="nameBn"
											v-model="updateStar.input.nameBn"
											:state="errors.length > 0 ? false:null"
											placeholder="Star Bangla name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="4">
								<b-form-group
									label="Type"
									label-for="type"
								>
										<b-form-select
											id="type"
											v-model="updateStar.input.type"
											:options="starTypes"
										/>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="6">
								<b-row>
									<b-col cols="6">
										<b-form-group
											label="Birth Date"
											label-for="birthDate"
										>
										<flat-pickr
											id="birthDate"
											v-model="updateStar.input.birthday"
											class="form-control"
											placeholder="Birth Date"
											:config="{dateFormat: 'm-d'}"
										/>
										</b-form-group>
									</b-col>
									<b-col cols="6">
										<b-form-group
											label="Birth Year"
											label-for="birthYear"
										>

										<validation-provider
											#default="{ errors }"
											name="birthYear"
											rules="between:1800,9999"
										>
											<b-form-input
												id="birthYear"
												v-model="updateStar.input.birthYear"
												class="form-control"
												type="number" step="1"
												:state="errors.length > 0 ? false:null"
												placeholder="Birth Year(YYYY)"
											/>
											<small v-if="errors.length > 0 " class="text-danger">
												Year must be between 1800 and 9999
											</small>
										</validation-provider>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="6">
								<b-form-group
									label="Nationality"
									label-for="type"
								>
										<b-form-input
											id="type"
											v-model="updateStar.input.nationality"
											placeholder="Nationality"
										/>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group
									label="Birth Place"
									label-for="birthPlace"
								>
										<b-form-input
											id="birthPlace"
											v-model="updateStar.input.birthPlace"
											placeholder="Birth Place"
										/>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group
									label="Active Year"
									label-for="activeYear"
								>

									<validation-provider
										#default="{ errors }"
										name="activeYear"
										rules="between:1800,9999"
									>
										<b-form-input
											id="activeYear"
											v-model="updateStar.input.activeYear"
											class="form-control"
											type="number"
											min="1900" max="9999" step="1"
											:state="errors.length > 0 ? false:null"
											placeholder="Active Year(YYYY)"
										/>
										<small v-if="errors.length > 0 " class="text-danger">
											Year must be between 1800 and 9999
										</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group
									label="Information"
									label-for="info"
								>
									<b-form-textarea
										rows="6"
										id="info"
										v-model="updateStar.input.info"
										placeholder="Information.."
									/>
								</b-form-group>
							</b-col>


						</b-row>
						<!--image-->
						<div class="form-group">
							<label for="image">Image</label>
							<input type="file" class="form-control tag-image" id="image"
									v-on:change="handleChange" :input="selectedFile" />
							<div class="text-center">
								<img v-if="addStarImageUrl" :src="addStarImageUrl" class=" img-fluid mt-2 mb-2" />
							</div>

						</div>




					</b-col>
				</b-row>
			</b-form>
		</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroupAppend, BInputGroup, } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			page: Number(this.$route.query.page) || 1,
			limit: Number(this.$route.query.limit) || 20,
			fields: [
				{ key: 'name', label: 'Name' },
				{ key: 'type', label: 'Type' },
				{ key: 'birthday', label: 'Birth Day' },
				{ key: 'birthYear', label: 'Birth Year' },
				{ key: 'birthPlace', label: 'Birth Place' },
				{ key: 'nationality', label: 'Nationality' },
				{ key: 'activeYear', label: 'Active Year' },
				{ key: 'description', label: 'Description' },
				{ key: 'thumbImageUrl', label: 'Image' },
				{ key: 'count', label: 'Count' },
				{ key: 'username', label: 'User'},
				{ key: 'action', label: 'Action' },
			],
			starTypes: [
				{ value: 'actor', text: 'Actor' },
				{ value: 'writer', text: 'Writer' },
				{ value: 'director', text: 'Director' },
				{ value: 'producer', text: 'Producer' },
			],
			rejectSuggestionModal: {
				show: false,
				id: null,
				reason: '',
			},
			starCustomInputs: [
				{
					id: "",
					key: "",
					info: ""
				}
			],
			starRequiredInputs: [
				{
					id: "",
					key: "",
					info: ""
				}
			],
			selectedFile: null,
			addStarImageUrl: null,
			updateStar: {
				show: false,
				loading: false,
				tagId: "",
				input: {
					name: "",
					nameBn: "",
					type: "",
					thumbImageUrl: "",
					displayImageUrl: "",
					birthPlace: "",
					birthday: "",
					birthYear: "",
					nationality: "",
					activeYear: "",
					info: "",
					tagAliases: []
				}
			},
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		...mapGetters('suggestions', ['starSuggestions']),
	},
	watch: {
		page() {
			this.loadData()
		},
	},
	methods: {
		linkGen(page) {
			return {
				name: 'starSuggestions',
				query: {
					page,
					limit: this.limit,
				},
			}
		},
		loadData() {
			this.$store.dispatch('suggestions/fetchStarSuggestions', {
				page: this.page,
				limit: this.limit,
			})
		},
		rejectSuggestionToggle(item) {
			this.rejectSuggestionModal.show = !this.rejectSuggestionModal.show
			this.rejectSuggestionModal.id = item.id
			this.rejectSuggestionModal.reason = ''
		},
		rejectSuggestionQ() {
			this.$store.dispatch('suggestions/rejectStarSuggestion', {input: {
				id: this.rejectSuggestionModal.id,
				reason: this.rejectSuggestionModal.reason,
			}, success: this.successToast, error: this.errorDialog});
		},
		toggleUpdateStar(tag, approve = false) {
			this.updateStar.show = !this.updateStar.show
			this.updateStar.approve = approve
			this.selectedFile = null
			this.addStarImageUrl = tag.displayImageUrl || tag.thumbImageUrl
			this.updateStar.input = {
				name: tag.name,
				nameBn: tag.nameBn,
				type: tag.type,
				thumbImageUrl: tag.thumbImageUrl,
				displayImageUrl: tag.displayImageUrl,
				birthPlace: tag.birthPlace,
				birthday: tag.birthday,
				birthYear: tag.birthYear,
				nationality: tag.nationality,
				activeYear: tag.activeYear,
				info: tag.info,
				tagAliases: tag.tagAliases || [],
			}
			this.updateStar.tagId = tag.id

			let lTagP = tag.profiles;

			this.starCustomInputs = lTagP
					? lTagP.filter(info => {
								if (
										info.key != "Birth Date" &&
										info.key != "Birth Year" &&
										info.key != "Birth Place" &&
										info.key != "Nationality" &&
										info.key != "Active Year"
								) {
										return info;
								}
								return null;
						})
					: [];
			this.starRequiredInputs = lTagP
					? lTagP.filter(info => {
								if (
										info.key == "Birth Date" ||
										info.key == "Birth Year" ||
										info.key == "Birth Place" ||
										info.key == "Nationality" ||
										info.key == "Active Year"
								) {
										return info;
								}
								return null;
						})
					: [];
		},
		updateStarValidation(e) {
			e.preventDefault();

			this.$refs.tagRoleUpdate.validate().then(success => {
				if (success) {
					const starRequiredInputsNames = [
                "Birth Date",
                "Birth Year",
                "Birth Place",
                "Nationality",
                "Active Year"
            ];

            starRequiredInputsNames.forEach(n => {
                if (
                    this.starRequiredInputs.find(item => item.key == n) ==
                    undefined
                ) {
                    this.starRequiredInputs.push({
                        id: "",
                        key: n,
                        info: ""
                    });
                }
            });
            this.starRequiredInputs.map(item => {
							if (
									item.key == "Birth Date" &&
									this.updateStar.input.birthday
							)
									item.info = this.updateStar.input.birthday;
							if (
									item.key == "Birth Year" &&
									this.updateStar.input.birthYear
							)
									item.info = this.updateStar.input.birthYear;
							if (
									item.key == "Birth Place" &&
									this.updateStar.input.birthPlace
							)
									item.info = this.updateStar.input.birthPlace;
							if (
									item.key == "Nationality" &&
									this.updateStar.input.nationality
							)
									item.info = this.updateStar.input.nationality;
							if (
									item.key == "Active Year" &&
									this.updateStar.input.activeYear
							)
									item.info = this.updateStar.input.activeYear;
					});
					let starRequiredInputs = this.starRequiredInputs.filter(
							input => input.info != ""
					);

					let profileUpdateInput = this.starCustomInputs.concat(
							starRequiredInputs
					);

					this.updateStar.input.profileUpdateInput = profileUpdateInput;
					if (this.selectedFile) {
						const reader = new FileReader();
						reader.readAsDataURL(this.selectedFile);
						reader.onload = async () => {
								this.updateStar.input.thumbImageUrl = await reader.result;
								this.approveStarRequest();
						}
					} else {
						this.approveStarRequest();
					}
				}
			})
		},
		handleChange(e) {
			this.addStarImageUrl = "";

			this.selectedFile = e.target.files[0];
			this.addStarImageUrl = URL.createObjectURL(this.selectedFile);
		},
		approveStarRequest() {
			const approveInput = this.updateStar.input
			// remove aliaes input
			delete approveInput.tagAliases
			delete approveInput.profileUpdateInput

			const tagId =this.updateStar.tagId;
			this.$store.dispatch('suggestions/approveStarSuggestion', {tagId, input: approveInput, success:(message) => {
				this.successToast(message);
				this.toggleUpdateStar({})
			}, error: this.errorDialog});
		},
	},


}


</script>
