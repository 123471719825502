const favorites = `
query($first: Int, $skip: Int, $userId: ID){
  favorites(first: $first, skip: $skip, userId: $userId){
    count
    rows{
    ... on Show{
      id
      title
      isPublic
      thumbImageUrl
      thumbImageUrlLg
      youtubeVideoId
      videoEmbeddable
      url
    }
    ...on Tag{
      id
      thumbImageUrl
      name
      type
      url
    }
    }
  }
}
`


export default favorites