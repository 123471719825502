import entityActivities from '../../graphql/query/activities/entityActivities'
import searchShowsQuery from '@/graphql/query/shows/searchShow'

import rows from '@/graphql/query/shows/rows'

export default {
	namespaced: true,
	state: {
		shows: [],
		types: [
			{ title: 'Series', value: 'series' },
			{ title: 'Serial', value: 'serial' },
			{ title: 'Shortfilm', value: 'shortfilm' },
			{ title: 'Telefilm', value: 'telefilm' },
			{ title: 'Natok', value: 'natok' },
			{ title: 'Film', value: 'film' },
			{ title: 'Webfilm', value: 'webfilm' },
			{ title: 'Web Series', value: 'webseries' },
			{ title: 'Movie', value: 'movie' },
			{ title: 'Cinema', value: 'cinema' },
			{ title: 'Music', value: 'music' },
			{ title: 'Other', value: 'show' },
			{ title: 'Custom', value: 'custom' },
		],
		loading: false,
		count: 0,
		page: 1,
		suggested: false,
	},
	getters: {
		shows: state => state.shows,
		types: state => state.types,
		count: state => state.count,
		loading: state => state.loading,
		selectedShows: state => state.shows?.filter(show => show.selected) || [],
	},
	mutations: {
		setShows(state, { rows, count, page, suggested = false }) {
			state.shows = rows
			state.count = count
			state.page = page
			state.loading = false
			state.suggested = suggested
		},
		setLoading(state, loading) {
			state.loading = loading
		},
		romoveShow(state, id) {
			state.shows = state.shows.filter(show => show.id !== id)
			state.count = state.count - 1
		},
		deleteShows(state, ids) {
			state.shows = state.shows.filter(show => !ids.includes(show.id))
			state.count = state.count - ids.length
		},
		toggleSelectShow(state, id) {
			state.shows = state.shows.map(show => {
				if (show.id === id) {
					show.selected = !show.selected
				}
				return show
			})
		},
		updateShow(state, show) {
			state.shows = state.shows.map(s => {
				if (s.id === show.id) {
					return show
				}
				return s
			})
		},
		updateIsPublic(state, { id, isPublic }) {
			state.shows = state.shows.map(show => {
				if (show.id === id) {
					show.isPublic = isPublic
				}
				return show
			})
		},
		addEpisodes(state, { id, episodes }) {
			state.shows = state.shows.map(show => {
				if (show.id === id) {
					show.episodes = episodes
				}
				return show
			})
		},
		showCreate(state, show) {
			state.shows = [show, ...state.shows]
			state.count = state.count + 1
		}
	},
	actions: {
		deleteShow({ commit, state }, { id, reason, redirect, success = console.log, error = console.log }) {
			axios.post("/graphql", {
				operationName: "",
				query: `
						mutation($id: ID!, $redirectInput: String, $reason: String) {
							showDelete(id: $id, redirectInput: $redirectInput, reason: $reason)
						}
					`,
				variables: {
					id: id,
					redirectInput: redirect,
					reason: reason
				}
			}).then(res => {
				if (res.data.data.showDelete) {
					commit('romoveShow', id)

					if (state.suggested) {
						commit('notifications/updateShowSuggNotification', -1, { root: true })
					}
					success("Show deleted.")
				} else {
					error("Something went wrong.")
				}
			}).catch(err => {
				error("Something went wrong.")
			})
		},
		getUserActivityOnShow({ commit }, { id, success = console.log, error = console.log }) {
			axios.post("/graphql", {
				operationName: "",
				query: entityActivities,
				variables: {
					entityId: id,
					entityType: "show"
				}
			}).then(res => {
				if (res.data.data.getActivities) {
					success(res.data.data.getActivities.rows)
				} else {
					error("Something went wrong.")
				}
			}).catch(err => {
				error("Something went wrong.")
			})
		},
		approveShows({ commit }, { ids, success = console.log, error = console.log }) {
			axios.post("/graphql", {
				operationName: "",
				query: `
						mutation($input: [ID!]!) {
							approveBulkShow(showIds: $input) {
								id
								title
								isPublic
							}
						}
					`,
				variables: {
					input: ids
				}
			}).then(res => {
				res = res?.data?.data?.approveBulkShow
				if (res) {
					// for each
					res.forEach(show => {
						commit('romoveShow', show.id)
						commit('notifications/updateShowSuggNotification', -1, { root: true })

						success(`Show (${show.title}) approved.`)
					})
				} else {
					error("Something went wrong.")
				}
			}).catch(err => {
				console.log(err)
				error("Something went wrong.")
			})
		},
		searchShows({ commit, state }, { query, first, skip, emptyBanglaTitle, includeInactiveShows }) {
			commit('setLoading', true)
			axios.post("/graphql", {
				operationName: "",
				query: searchShowsQuery,
				variables: {
					query: query,
					first: first,
					skip: skip,
					showsNullTitleBn: emptyBanglaTitle,
					includeInactive: includeInactiveShows
				}
			}).then(res => {
				res = res?.data?.data?.searchShow
				if (res) {
					commit('setShows', {
						rows: res.rows,
						count: res.count,
						page: skip + 1,
					})
				} else {
					commit('setShows', {
						rows: [],
						count: 0,
						page: 1,
					})
				}
			}).catch(err => {
				console.log(err)
				commit('setShows', {
					rows: [],
					count: 0,
					page: 1,
				})
			})
		},
		getEpisodes({ commit, state }, { id, isActive = true, success = console.log, error = console.log }) {
			console.log('shows')
			axios
				.post("/graphql", {
					operationName: "",
					query: `query($showId: ID!, $isActive: Boolean!,) {
						getEpisodes(showId: $showId, isActive: $isActive,) {
							count
							rows {
								id
								slug
								showId
								youtubeTitle
								description
								youtubeVideoId
								videoEmbeddable
								thumbImageUrl
								thumbImageUrlLg
								duration
								videoPublishedAt
								externalLink
								isActive
								isPublic
							}
						}
				}`,
					variables: { showId: id, isActive }
				}).then(res => {
					success(res.data.data.getEpisodes)
				}).catch(err => {
					error("Something went wrong.")
				})
		},
		saveAsEpisode({ commit, state }, { id, episodeInput, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
				mutation($id: ID!, $episodeInput: episodeInput!) {
						showConvert(id: $id, episodeInput: $episodeInput){
						${rows}
						episodes{
								id
								slug
								showId
								youtubeTitle
								description
								youtubeVideoId
								videoEmbeddable
								thumbImageUrl
								thumbImageUrlLg
								duration
								videoPublishedAt
								externalLink
								isActive
								isPublic
						}
						defaultEpisode
					}
				}
				`,
					variables: { id: id, episodeInput: episodeInput }
				})
				.then(response => {
					if (!response.data.errors || !response.data.errors.length) {
						success(
							response.data.data.showConvert == null
								? "The Show has been converted as episode!"
								: "The Episode has been transfer to another show!"
						);
					} else {
						console.log();
						error(
							response.data.errors &&
								response.data.errors[0].message
								? response.data.errors[0].message
								: "Failed to convert!"
						);
					}
				});
		},
		deleteShows({ commit, state }, { ids, reason, redirect, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation($input: [ID!]!, $reason: String) {
						deleteSuggestedShows(input: $input, reason: $reason)
					}
					`,
					variables: { input: ids, reason: reason, redirectInput: redirect }
				})
				.then(response => {
					if (!response.data.errors || !response.data.errors.length) {
						commit("deleteShows", ids);
						success("The Shows has been deleted!");
					} else {
						error(
							response.data.errors &&
								response.data.errors[0].message
								? response.data.errors[0].message
								: "Failed to delete!"
						);
					}
				})
				.catch(err => {
					error("Something went wrong.");
				});
		}
	},
}
