import rows from './rows'

const showsForAdminQuery = `
query($first: Int, $skip: Int, $tags : [String]){
  showsForAdmin(first: $first, skip: $skip, tags: $tags){
    count
    rows{
      ${rows}
    }
  }
}`


export default showsForAdminQuery
