export default {
	namespaced: true,
	state: {
		loading: false,
		channels: [],
		allChannels: [],
		count: 0,
		page: 1,
	},
	getters: {
		channels: state => state.channels,
		allChannels: state => state.allChannels,
		count: state => state.count,
		loading: state => state.loading,
		page: state => state.page,
	},
	mutations: {
		setChannels(state, { rows, count, page }) {
			state.channels = rows
			state.count = count
			state.page = page
			state.loading = false
		},
		setAllChannels(state, channels) {
			state.allChannels = channels
			state.loading = false
		},
		setLoading(state, loading) {
			state.loading = loading
		},
		addChannel(state, tag) {
			state.channels.unshift(tag)
			state.count++
		},
		updateChannel(state, tag) {
			const index = state.channels.findIndex(t => t.tag?.id == tag.id)
			if (index !== -1) {
				state.channels[index].tag = tag
			}
		},
		removeChannel(state, id) {
			state.channels = state.channels.filter(tag => tag.id !== id)
			state.count = state.count - 1
		},
		removeSuggestedChannel(state, id) {
			state.channels = state.channels.filter(channel => channel?.tag.id !== id)
			state.count = state.count - 1
		},
		addLink(state, { id, link }) {
			const index = state.channels.findIndex(t => t.tag?.id === id)
			if (index !== -1) {
				state.channels[index].tag.links.unshift(link)
			}
		},
		updateLink(state, { tagId, link }) {
			const index = state.channels.findIndex(t => t.tag?.id == tagId)


			if (index !== -1) {
				const linkIndex = state.channels[index].tag.links.findIndex(l => l.id == link.id)

				if (linkIndex !== -1) {
					state.channels[index].tag.links[linkIndex].type = link.type
					state.channels[index].tag.links[linkIndex].url = link.url
				}
			}
		},

		deleteLink(state, { id, linkId }) {
			const index = state.channels.findIndex(t => t.tag?.id == id)
			if (index !== -1) {
				state.channels[index].tag.links = state.channels[index].tag.links.filter(link => link.id !== linkId)
			}
		},

		changeShowStatus(state, { id, status }) {
			const index = state.channels.findIndex(t => t.tag?.id == id)
			if (index !== -1) {
				state.channels[index].tag.isPublic = status
			}
		},
		updateChannelSubInfo(state, { id, data }) {
			const index = state.channels.findIndex(t => t?.id == id)
			if (index !== -1) {
				state.channels[index].feedSubscribed = data.feedSubscribed
				state.channels[index].subscribedAt = data.subscribedAt
				state.channels[index].expiredAt = data.expiredAt
			}
		}
	},
	actions: {
		async addChannel({ commit, dispatch }, input) {
			const addChannel = await axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation($input: channelInput!) {
						channelCreate(input: $input) {
							id
							youtubeEtag
							youtubeChannelId
							tag{
									id
									slug
									name
									nameBn
									type
									isPublic
									displayImageUrl
									thumbImageUrl
									birthday
									birthPlace
									activeYear
									info
									links {
											id
											url
											type
									}
									profiles{
											id
											key
											info
									}
							}
						}
					}
					`,
					variables: { input: input }
				})
			if (addChannel.data.data?.tagCreate) {
				commit('addChannel', addChannel.data.data.tagCreate)
				return true
			} else {
				throw new Error(addChannel.data.errors[0]?.message)
			}
		},
		async updateChannel({ commit, dispatch }, input) {
			const updateChannel = await axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $input: TagUpdateInput!) {
							tagUpdate(id : $id, input: $input) {
								id
								name
								nameBn
								thumbImageUrl
								displayImageUrl
								type
								slug
								birthPlace
								birthday
								birthYear
								nationality
								activeYear
								lastUpdatedBy{
									id
									name
									email
								}
								profiles{
									id
									tagId
									key
									info
								}
								tagAliases{
								id
								aliasName
								}
							info
							links{
								id
								url
								type
							}
						}
					}
					`,
					variables: {
						id: input.id,
						input: input.input,
					}
				})
			if (updateChannel.data.data?.tagUpdate) {
				commit('updateChannel', updateChannel.data.data.tagUpdate)
				return true
			} else {
				throw new Error(updateChannel.data.errors[0]?.message)
			}
		},
		async getChannels({ commit, state }, { skip, limit, query, activeType = true }) {
			commit('setLoading', true)
			const res = await axios.post("/graphql", {
				operationName: "",
				query: `
								query($query: String! ,$first: Int,$skip: Int, $type: String){
									searchChannel(query: $query, first: $first, skip: $skip, type: $type){
										count,
										rows{
											id
											youtubeEtag
											youtubeChannelId
											feedSubscribed
											subscribedAt
											expiredAt
											tag{
												id
												slug
												name
												nameBn
												type
												displayImageUrl
												thumbImageUrl
												birthday
												birthPlace
												nationality
												activeYear
												info
												isPublic
												lastUpdatedBy{
													id
													name
													email
												}
												profiles {
													id
													key
													info
													}
												links {
													id
													url
													type
												}
											}
										}
									}
								}
				`,
				variables: {
					query: query,
					first: limit,
					skip: skip,
					type: activeType ? "approved" : "requested"
				}
			})

			commit('setChannels', {
				rows: res.data.data.searchChannel?.rows || [],
				count: res.data.data.searchChannel?.count || 0,
				page: skip / limit + 1
			})
		},
		async getSugestedChannels({ commit, state }, { skip, limit }) {
			commit('setLoading', true)
			const res = await axios.post("/graphql", {
				operationName: "",
				query: `
                query($first: Int, $skip: Int,$type: String){

                channelsForAdmin(first: $first, skip: $skip, type: $type){
                  count,
                   rows{
                    id
                    youtubeEtag
                    youtubeChannelId
                    feedSubscribed
                    subscribedAt
                    expiredAt
                    tag{
                        id
                        slug
                        name
                        nameBn
                        isPublic
                        type
                        displayImageUrl
                        thumbImageUrl
                        birthday
                        birthPlace
                        activeYear
                        info
                        suggestioncounts{
                            id
                            suggestions{
                                user{
                                    name
                                }
                            }
                        }
                        lastUpdatedBy{
                            id
                            email
                            name
                        }
                        links {
                            id
                            url
                            type
                        }
                        profiles{
                            id
                            key
                            info
                        }
                      }
                  }

                }
							}`,
				variables: {
					first: this.itemPerPage,
					skip: skip,
					type: "requested"
				}
			})
			commit('setChannels', {
				rows: res.data.data.channelsForAdmin?.rows || [],
				count: res.data.data.channelsForAdmin?.count || 0,
				page: skip / limit + 1
			})

		},
		async deleteChannel({ commit }, { id, success, error }) {
			await axios.post("/graphql", {
				operationName: "",
				query: `
					mutation($id: ID!) {
						tagDelete(id: $id )
				}
				`,
				variables: {
					id: id,
				}
			}).then(res => {
				if (res.data.data?.tagDelete) {
					commit('removeChannel', id)
					success('Channel deleted successfully')
				} else if (res.data.errors) {
					throw new Error(res.data.errors[0].message)
				}
			}).catch(err => {
				error(err)
			})
		},
		async changeShowStatus({ commit }, { id, status }) {
			await axios.post("/graphql", {
				operationName: "",
				query: `
					mutation($id: ID!, $status: Boolean!) {
						changeStatusOfTag(id: $id, status: $status)
				}
				`,
				variables: {
					id: id,
					status: status
				}
			}).then(res => {
				if (res.data.data?.changeStatusOfTag) {
					commit('changeShowStatus', { id, status })
				} else if (res.data.errors) {
					throw new Error(res.data.errors[0].message)
				}
			}).catch(err => {
				throw new Error(err)
			})
		},
		async feedSubscribe({ commit }, { channel, mode, renew = false, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
						mutation($channelId: String!, $mode: String!) {
							feedSubscribe(channelId : $channelId, mode: $mode)
						}
						`,
					variables: {
						channelId: channel.youtubeChannelId,
						mode: mode
					}
				})
				.then(response => {
					if (
						response.data.data &&
						response.data.data.feedSubscribe
					) {
						const temp = {}
						if (mode === "subscribe") {
							temp.feedSubscribed = true;
							temp.subscribedAt = new Date().getTime();
							temp.expiredAt = new Date(
								new Date().getTime() + 864000 * 1000
							);
							if (renew) {
								success("Successfully Renewed!");
							} else {
								success("Successfully Subscribed!");
							}
						} else {
							temp.feedSubscribed = false;
							success("Successfully Unsubscribed!");
						}
						commit("updateChannelSubInfo", { id: channel.id, data: temp });
					} else {
						error(`Failed to ${mode}!`);
					}
				});
		},
	},
}
