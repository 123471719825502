export default {
	namespaced: true,
	state: {
		activities: [],
		summaries: [],
		loading: false,
		count: 0,
		page: 1,
	},
	getters: {
		activities: state => state.activities,
		count: state => state.count,
		loading: state => state.loading,
		summaries: state => state.summaries,
	},
	mutations: {
		setActivities(state, { rows = [], count, page, summaries }) {
			state.activities = []

			rows.forEach((row) => {
				// check if the activity has a parent entity on activities (userId, entityType, entityId)
				const foundActivity = state.activities.find((activity) => {
					return activity.userId === row.userId && activity.entityType === row.entityType && activity.entityId === row.entityId;
				});

				if (foundActivity) {
					foundActivity.children.push(row);
					foundActivity.length = foundActivity.children.length;
				} else {
					state.activities.push({
						...row,
						children: [row],
						length: 1,
						collapse: false,
					});
				}
			});


			state.summaries = summaries || []
			state.count = count
			state.page = page
			state.loading = false
		},
		setLoading(state, loading) {
			state.loading = loading
		},
		toggleActivityCollapse(state, id) {
			state.activities.find((activity) => {
				if (activity.id == id) {
					activity.collapse = !activity.collapse
					return true
				}
			})
		},
	},
	actions: {
		async fetchActivities({ commit, state }, { userIds = [], channelIds = [], startDate = "", endDate = "", summary = false, page, limit }) {
			let variables = {};
			variables.skip = (page - 1) * limit || 0;
			variables.first = limit;
			variables.startDate = startDate;
			variables.endDate = endDate;
			variables.summary = summary;

			if (userIds.length > 0) {
				variables.users = userIds;
			} else {
				variables.summary = false
			}
			if (channelIds.length > 0) {
				variables.channels = channelIds;
			}

			commit('setLoading', true)
			axios
				.post("/graphql", {
					operationName: "",
					query: `query($first: Int, $skip: Int, $users: [ID], $channels: [ID], $startDate: String, $endDate: String, $summary: Boolean) {
            getActivities(first: $first, skip: $skip, users: $users, channels: $channels, startDate: $startDate, endDate: $endDate , summary: $summary){
                count
                summaries {
                  user {
                    name
                    displayImage
                  }
                  show_created
                  show_contributed
                  tag_created
                  tag_contributed
                  total
                  last_activity_time
									grouped_data
								}
                rows{
                id
                activity
                details
                userId
                youtubeVideoId
                show{
                  url
                }
                tag{
                  url
                }
                user{
                    name
                }
                channel{
                    tag{
                        name
                    }
                }
                channelId
                entityId
                entityName
                entityType
                entitySlug
                childSlug
                createdAt

                }

            }
          }
            `,
					variables
				})
				.then((response) => {
					if (response.data && response.data.data?.getActivities) {
						commit('setActivities', {
							rows: response.data.data.getActivities.rows,
							count: response.data.data.getActivities.count,
							summaries: response.data.data.getActivities.summaries,
							page,
						})
					}
				})
		}

	},
}
