<template>
	<b-card>
		<template v-slot:header class="bg-primary flex">
			<h3
				@click="getActivities"
				title="click to refresh activities"
				class="text-white"
			>
				User Activities
			</h3>
		</template>
		<br />
		<div class="row">
			<div class="col-6">
				<div class="col-12">
					<p
						v-for="user in selectedUsersForQuery"
						data-role="tagsinput"
						:key="user.id"
						class="badge badge-success mr-1 p-1"
					>
						{{ `${user.name} (${user.email}) ` }}
						<span>
							<feather-icon
								@click="removeSelectedUserForQuery(user)"
								icon="XCircleIcon"
								class="feather pointer"
								size="18"
							></feather-icon>
						</span>
					</p>
				</div>

				<div class="col-12 p-0">
					<vue-bootstrap-typeahead
						@hit="selectUserForQuery"
						ref="selectUserForQuery"
						:serializer="(user) => `${user.name} (${user.email})`"
						:data="allUsers"
						v-model="selectedUser"
						placeholder="Filter by user.."
					>
					</vue-bootstrap-typeahead>
				</div>
			</div>
			<div class="col-6">
				<div class="col-12">
					<p
						v-for="channel in selectedChannelsForQuery"
						data-role="tagsinput"
						:key="channel.id"
						class="badge badge-success p-1 mr-1"
					>
						{{ channel.tag.name }}
						<span>
							<feather-icon
								@click="removeSelectedChannelForQuery(user)"
								icon="XCircleIcon"
								class="feather pointer"
								size="18"
							></feather-icon>
						</span>
					</p>
				</div>
				<div class="col-12 p-0">
					<vue-bootstrap-typeahead
						@hit="selectChannelForQuery"
						ref="selectChannelForQuery"
						:serializer="(channel) => `${channel.tag.name}`"
						:data="allChannels"
						v-model="selectedChannel"
						placeholder="Filter by channel.."
					>
					</vue-bootstrap-typeahead>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<!-- filter date select form -->
				<div class="form-inline">
					<div class="form-group mb-2 mt-2 mr-2">
						<input
							type="date"
							class="form-control"
							id="filterDateInput"
							v-model="startDate"
							@change="filterByDate"
						/>
					</div>
					<div class="form-group m-2">
						<input
							type="date"
							class="form-control"
							id="filterEndDateInput"
							v-model="endDate"
							@change="filterByDate"
						/>
					</div>
					<div class="form-check m-2" v-if="selectedUsersForQuery.length">
						<input
							class="form-check-input"
							type="checkbox"
							id="includeSummary"
							@change="filterByDate"
							v-model="summary"
						/>
						<label class="form-check-label" for="includeSummary">
							Include Summary
						</label>
					</div>
					<!-- reset button -->
					<button
						type="button"
						class="btn btn-secondary m-2"
						@click="resetDateFilter"
					>
						Reset
					</button>
				</div>
			</div>
		</div>
		<br />
		<summaries
			class="mt-3"
			v-if="!loading && summary && selectedUser && summaries.length"
			:summaries="summaries"
		></summaries>
		<br />
		<template>
			<b-table
				striped
				bordered
				responsive
				:items="activities"
				:fields="fields"
				class="text-center"
			>
				<template #cell(toggle)="row">
					<b-form-checkbox
						v-model="row.detailsShowing"
						plain
						class="vs-checkbox-con"
						@change="row.toggleDetails"
						v-if="row.item.length > 1"
					>
						<span class="vs-checkbox">
							<span class="vs-checkbox--check">
								<i class="vs-icon feather icon-check" />
							</span>
						</span>
						<span class="vs-label">{{
							row.detailsShowing ? "Collapse" : "Expand"
						}}</span>
					</b-form-checkbox>
				</template>
				<template v-slot:cell(name)="data">
					<a
						v-if="data.item.show || data.item.tag"
						:href="
							getPublicUrl(
								data.item.show
									? data.item.show.url
									: data.item.tag
									? data.item.tag.url
									: null
							)
						"
						target="__blank"
						>{{ data.item.entityName }}</a
					>
					<span v-else>{{ data.item.entityName }}</span>
				</template>
				<template v-slot:cell(video)="data">
					<div class="dashboard-action d-flex m-auto">
						<a v-if="data.item.youtubeVideoId" @click="videoPlay(data.item)">
							<feather-icon icon="PlayCircleIcon" size="18" />
						</a>
						<a
							v-if="data.item.youtubeVideoId"
							@click="copyToClipboard(data.item.youtubeVideoId)"
							title="copy youtube id"
						>
							<feather-icon icon="ClipboardIcon" size="18" />
						</a>
					</div>
				</template>
				<template v-slot:cell(user-channel)="data">
					{{
						data.item.user
							? data.item.user.name
							: data.item.channel
							? data.item.channel.tag.name
							: ""
					}}
				</template>
				<template v-slot:cell(createdAt)="data">
					{{ new Date(Number(data.item.createdAt)).toLocaleString() }}
				</template>
				<template #cell(activityDetails)="data">
					<div
						@click="toggleActivityCollapse(data.item.id)"
						class="activity-details text-center"
						:class="data.item.collapse ? 'activity-details-collapse' : ''"
					>
						<p>
							{{ data.item.details }}
						</p>
						<feather-icon
							v-if="data.item.details"
							class="icon"
							icon="ChevronDownIcon"
							size="18"
						/>
					</div>
				</template>
				<template #row-details="data">
					<div class="timeline-wrapper">
						<div class="">
							<app-timeline>
								<app-timeline-item
									v-for="(item, index) in data.item.children"
									:key="index"
									:title="item.activity"
									:subtitle="item.details"
									:time="new Date(Number(item.createdAt)).toLocaleString()"
									variant="success"
								/>
							</app-timeline>
						</div>
					</div>
				</template>
			</b-table>
		</template>
		<br />

		<b-pagination-nav
			v-model="page"
			:link-gen="linkGen"
			:number-of-pages="Math.ceil(count / limit) || 1"
			use-router
		></b-pagination-nav>
		<show-count :count="count" :limit="limit" :page="page" />

		<player-modal
			:showVideoPlayer="showVideoPlayer"
			:selectedShow="selectedShow"
			:videoPlay="videoPlay"
		></player-modal>
	</b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Summaries from "@/components/Summaries.vue";
export default {
	data() {
		return {
			fields: [
				{ key: "toggle", label: "#" },
				{ key: "name", label: "Name/Title" },
				{ key: "entityType", label: "Type" },
				{ key: "user-channel", label: "User/Channel" },
				{ key: "length", label: "Total Activity" },
				{ key: "activity", label: "Activity" },
				{ key: "childSlug", label: "Episode" },
				{ key: "createdAt", label: "Date" },
				{ key: "video", label: "Video" },
				{ key: "activityDetails", label: "Details" },
			],
			selectedUser: "",
			selectedChannel: "",
			startDate: "",
			endDate: "",
			selectedUsersForQuery: [],
			selectedChannelsForQuery: [],
			summary: false,
			selectedShow: {},
			showVideoPlayer: false,
		};
	},
	components: {
		Summaries,
	},
	mounted() {
		this.getActivities();
	},
	computed: {
		...mapGetters("userActivities", [
			"activities",
			"count",
			"loading",
			"summaries",
		]),
		...mapGetters("user", ["allUsers"]),
		...mapGetters("channels", ["allChannels"]),
		page: {
			get() {
				return (this.$route.query?.page || 1) * 1;
			},
			set(page) {
				if (page == this.page) return;
				this.$router.push({ query: { ...this.$route.query, page } });
			},
		},
		limit: {
			get() {
				return (this.$route.query?.limit || 40) * 1;
			},
			set(limit) {
				if (limit == this.limit) return;
				this.$router.push({ query: { ...this.$route.query, limit } });
			},
		},
	},
	watch: {
		page() {
			this.getActivities();
		},
	},
	methods: {
		linkGen(page) {
			return {
				name: "userActivities",
				query: {
					page,
					limit: this.limit,
				},
			};
		},
		videoPlay(show = {}) {
			this.selectedShow = show;
			this.showVideoPlayer = show.youtubeVideoId ? true : false;
		},
		toggleActivityCollapse(id) {
			this.$store.commit("userActivities/toggleActivityCollapse", id);
		},
		getActivities() {
			const userIds = this.selectedUsersForQuery.map((user) => user.id) || [];
			const channelIds =
				this.selectedChannelsForQuery.map((channel) => channel.id) || [];
			this.$store.dispatch("userActivities/fetchActivities", {
				page: this.page,
				limit: this.limit,
				userIds: userIds,
				channelIds: channelIds,
				summary: this.summary,
				startDate: this.startDate,
				endDate: this.endDate,
			});
		},
		selectUserForQuery(user) {
			this.selectedUsersForQuery.push(user);
			setTimeout(() => {
				this.$refs.selectUserForQuery.inputValue = "";
			}, 100);
			this.page = 1;
			this.getActivities();
		},
		removeSelectedUserForQuery(user) {
			let index = this.selectedUsersForQuery.indexOf(user);
			if (index != -1) {
				this.selectedUsersForQuery.splice(index, 1);
				this.page = 1;
				this.getActivities();
			}
		},
		selectChannelForQuery(channel) {
			this.selectedChannelsForQuery.push(channel);
			setTimeout(() => {
				this.$refs.selectChannelForQuery.inputValue = "";
			}, 100);
			this.page = 1;
			this.getActivities();
		},
		removeSelectedChannelForQuery(channel) {
			let index = this.selectedChannelsForQuery.indexOf(channel);
			if (index != -1) {
				this.selectedChannelsForQuery.splice(index, 1);
				this.page = 1;
				this.getActivities();
			}
		},
		filterByDate() {
			this.page = 1;
			this.getActivities();
		},
		resetDateFilter() {
			this.startDate = "";
			this.endDate = "";
			this.page = 1;
			this.summary = false;
			this.getActivities();
		},
	},
};
</script>

<style>
.timeline-wrapper {
	margin: 20px;
	text-align: initial;
}
</style>
