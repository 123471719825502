<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutUnauthorize = () => import('@/layouts/unauthorize/LayoutUnauthorize.vue')


// query
import userQuery from '@/graphql/query/user'


import firebase from 'firebase/compat/app';

import { mapGetters } from 'vuex'

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutUnauthorize,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'unauthorize') return 'layout-unauthorize'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    ...mapGetters('user', ['isLoggedIn', 'userLoading']),
  },
  watch: {
    userLoading() {
      const appLoading = document.getElementById('loading-bg')
      if (this.userLoading) {
        if (appLoading)
          appLoading.style.display = 'block'
      } else {
        appLoading.style.display = 'none'
      }
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  mounted() {
    this.$store.commit('user/setLoading', true)
    firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          this.$store.commit('user/setUser', user)
          this.setInterceptors()
          this.initialData()
        } else {
          this.$store.commit('user/setUser', null)
        }
    });

  },
  methods: {
    // set intercepetor
    setInterceptors() {
      window.axios.interceptors.request.use(async config => {
          if (this.isLoggedIn) {
              config.headers["Authorization"] =
                  "Bearer " + (await firebase.auth().currentUser.getIdToken());
          }
          return config;
      });
    },
    initialData() {
      this.getUser()
      this.loadRefreshableData()
      setInterval(() => {
        this.loadRefreshableData()

      }, 1000 * 60 * 5)
			this.getNotification()
			setInterval(() => {
				this.getNotification()
			}, 1000 * 60 * 1)

    },
    loadRefreshableData() {
      // load all tags
      this.$store.dispatch('tags/getAllTags')
			this.loadAllChannels()
			this.getAllUser()
    },
    getUser() {
      this.$store.commit('user/setLoading', true)
      axios.post('/graphql', {
        operationName: "",
        query: userQuery,
        variables: {
          firebaseUid: this.$store.state.user?.data?.uid
        }
      }).then((e) => {
        this.$store.commit('user/setUser', e.data.data.user)
      }).catch((e) => {
				//firebase.auth().signOut()
        console.log(e)
      }).finally(() => {
        this.$store.commit('user/setLoading', false)
      })
    },
		getNotification() {
			this.$store.dispatch('notifications/getNotification')
		},
		loadAllChannels() {
				let query = `
				query{
				channelsForAdmin{
					count,
						rows{
						id
						tag{
							id
							name
							}
					}

				}
				}`;
				axios.post('/graphql', {
					operationName: "",
					query: query,
					variables: {}
				}).then((e) => {
					this.$store.commit('channels/setAllChannels', e.data.data.channelsForAdmin.rows)
				}).catch((e) => {
					console.log(e)
				})
		},
		getAllUser() {
      axios
        .post("/graphql", {
          operationName: "",
          query: `query{
            users{
              count,
              rows{
                id
                name
                email
              }
            }
          }
            `,
          variables: {},
        })
        .then((response) => {
          if (response.data && response.data.data.users) {
						this.$store.commit('user/setAllUsers',  response.data.data.users.rows)
          }
        });
    },
  }
}
</script>
