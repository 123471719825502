<template>
	<div>
		<b-table
			v-if="!showSuggestions.loading && showSuggestions.count"
			responsive="sm"
			:items="showSuggestions.rows"
			:fields="fields"
			class="text-center"
			striped
			bordered
		>
			<template v-slot:cell(title)="data">
				<b-link
					:href="
						data.item.show.isPublic ? getPublicUrl(data.item.show.url) : ''
					"
					target="__blank"
					>{{ data.item.show.title }}</b-link
				>
			</template>

			<template v-slot:cell(type)="data">
				{{
					data.item.type == "aliasNameChange"
						? data.item.shows_tag.tagConnectType + " name change"
						: data.item.type
				}}
			</template>

			<template v-slot:cell(suggestion)="data">
				<div v-if="data.item.type == 'aliasNameChange'" class="m-auto">
					{{
						data.item.alias && data.item.alias.aliasName
							? data.item.shows_tag.alias.aliasName +
							  " --> " +
							  data.item.alias.aliasName
							: ""
					}}
				</div>
				<div
					v-else
					class="text-truncate m-auto"
					:title="
						data.item.tag && data.item.tag.name
							? data.item.tag.name
							: data.item.description
							? data.item.description
							: data.item.storyPlot
					"
				>
					{{
						(data.item.alias && data.item.alias.aliasName) ||
						(data.item.video && data.item.video.slug) ||
						data.item.description ||
						data.item.storyPlot ||
						data.item.value
					}}
					<a
						v-if="data.item.tag"
						:href="getPublicUrl(data.item.tag.url)"
						target="_blank"
						>{{ data.item.tag.name }}</a
					>
				</div>
			</template>
			<template v-slot:cell(username)="data">
				{{
					data.item.suggestions && data.item.suggestions.length > 0
						? data.item.suggestions[0].user.name
						: ""
				}}
			</template>
			<template v-slot:cell(video)="data">
				<div class="dashboard-action d-flex m-auto">
					<a
						@click="
							videoPlay(data.item.video ? data.item.video : data.item.show)
						"
					>
						<feather-icon icon="PlayCircleIcon" size="18" />
					</a>
					<a
						@click="
							copyToClipboard(
								data.item.video
									? data.item.video.youtubeVideoId
									: data.item.show.youtubeVideoId
							)
						"
						title="copy youtube id"
					>
						<feather-icon icon="ClipboardIcon" size="18" />
					</a>
				</div>
			</template>
			<template v-slot:cell(action)="data">
				<div class="dashboard-action d-flex m-auto">
					<a @click="approveSuggestion(data.item)" title="approve">
						<feather-icon icon="CheckCircleIcon" size="18" />
					</a>
					<a @click="rejectSuggestion(data.item)" title="reject" class="trash">
						<feather-icon icon="XCircleIcon" size="18" />
					</a>
					<a
						v-if="data.item && data.item.type == 'video'"
						@click="convertToShow(data.item, true)"
						title="Convert to show suggestion"
					>
						<feather-icon icon="CopyIcon" size="18" />
					</a>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="showSuggestions.loading">loading...</span>
			<span v-else>No suggestion found</span>
		</div>

		<br />
		<div>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(showSuggestions.count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="showSuggestions.count" :limit="limit" :page="page" />
		</div>
		<player-modal
			:showVideoPlayer="showVideoPlayer"
			:selectedShow="selectedShow"
			:videoPlay="videoPlay"
		></player-modal>

		<!--Episode approve modal-->
		<b-modal
			id="modal-prevent-closing"
			ref="showEpisodeApproveModal"
			title="Approve Show Suggestion."
			ok-title="Approve"
			cancel-variant="outline-secondary"
			centered
			@show="resetEpisodeApproveModal"
			@hidden="resetEpisodeApproveModal"
			@ok="hadleEpisodeApprove"
		>
			<div class="d-flex align-items-center mb-2">
				<div>
					<img
						class="w-200px"
						:src="showEpisodeApproveModal.show.thumbImageUrl"
						alt=" showForSelect.title"
					/>
				</div>
				<div>
					<h5 class="p-1">
						{{ showEpisodeApproveModal.show.title }}
					</h5>
				</div>
			</div>

			<form ref="form" @submit.stop.prevent="hadleEpisodeApprove">
				<b-form-group
					label="Suggetion(Episode)"
					label-for="reason-input"
					invalid-feedback="slug"
				>
					<b-form-input
						id="reason-input"
						v-model="showEpisodeApproveModal.slug"
					/>
				</b-form-group>

				<!--Auto Approve Episode-->
				<b-form-group
					label="Auto Approve Episode"
					label-for="auto-approve-episode"
					invalid-feedback="Auto Approve Episode"
				>
					<b-form-checkbox
						id="auto-approve-episode"
						v-model="showEpisodeApproveModal.autoApproveEpisode"
					/>
				</b-form-group>
			</form>
		</b-modal>

		<!-- Show suggestion approve modal-->
		<b-modal
			id="modal-prevent-closing"
			ref="showSugApproveModal"
			title="Approve Show Suggestion."
			ok-title="Approve"
			cancel-variant="outline-secondary"
			centered
			@show="resetSugApproveModal"
			@hidden="resetSugApproveModal"
			@ok="handleShowSugApprove"
		>
			<div class="d-flex align-items-center mb-2">
				<div>
					<img
						v-if="showSugApproveModal.show.thumbImageUrl"
						class="w-200px"
						:src="showSugApproveModal.show.thumbImageUrl"
						:alt="showSugApproveModal.show.title"
					/>
				</div>
				<div>
					<h5 class="p-1">
						{{ showSugApproveModal.show.title }}
					</h5>
				</div>
			</div>

			<form ref="form" @submit.stop.prevent="hadleEpisodeApprove">
				<b-form-group
					:label="`Suggetion(${showSugApproveModal.type})`"
					label-for="reason-input"
					invalid-feedback="slug"
				>
					<b-form-input
						id="reason-input"
						v-model="showSugApproveModal.value"
						v-if="showSugApproveModal.type != 'storyPlot'"
					/>
					<b-form-textarea
						id="reason-input"
						v-model="showSugApproveModal.storyPlot"
						v-else
					/>
				</b-form-group>
			</form>
		</b-modal>

		<!--Video Delete confirm modal-->
		<b-modal
			id="modal-prevent-closing"
			ref="showDeleteModal"
			title="Delete Show Suggestion."
			ok-title="Delete"
			cancel-variant="outline-secondary"
			centered
			@show="resetModal"
			@hidden="resetModal"
			@ok="handleShowDelete(showDeleteModal.addAsShow)"
		>
			<p>You won't be able to revert this!</p>

			<form
				ref="form"
				@submit.stop.prevent="handleShowDelete(showDeleteModal.addAsShow)"
			>
				<b-form-checkbox v-model="showDeleteModal.addAsShow" class="mb-2">
					Add as show suggestion
				</b-form-checkbox>
				<b-form-group
					label="Reason"
					label-for="reason-input"
					invalid-feedback="Reason ..."
				>
					<b-form-input id="reason-input" v-model="showDeleteModal.reason" />
				</b-form-group>
			</form>
		</b-modal>
	</div>
</template>

<script>
import {
	BCard,
	BCardText,
	BRow,
	BCol,
	BForm,
	BFormGroup,
	BFormInput,
	BFormTextarea,
	BFormCheckbox,
	BInputGroupAppend,
	BInputGroup,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver,
		BFormCheckbox,
	},
	data() {
		return {
			page: Number(this.$route.query.page) || 1,
			limit: Number(this.$route.query.limit) || 20,
			fields: [
				{ key: "title", label: "Title" },
				{ key: "type", label: "Type" },
				{ key: "suggestion", label: "Suggestion" },
				{ key: "username", label: "User" },
				{ key: "count", label: "Count" },
				{ key: "video", label: "Video" },
				{ key: "action", label: "Action" },
			],
			selectedShow: {},
			showVideoPlayer: false,
			showDeleteModal: {
				id: null,
				reason: "",
				addAsShow: false,
			},
			showEpisodeApproveModal: {
				suggestion: {},
				autoApproveEpisode: false,
				slug: "",
				show: {},
			},
			showSugApproveModal: {
				show: {},
			},
		};
	},
	mounted() {
		this.loadData();
	},
	computed: {
		...mapGetters("suggestions", ["showSuggestions"]),
	},
	watch: {
		page() {
			this.loadData();
		},
	},
	methods: {
		linkGen(page) {
			return {
				name: "showSuggestions",
				query: {
					page,
					limit: this.limit,
				},
			};
		},
		loadData() {
			this.$store.dispatch("suggestions/fetchShowSuggestions", {
				page: this.page,
				limit: this.limit,
			});
		},
		approveSuggestion(suggestion) {
			if (suggestion.type == "episode") {
				this.$refs.showEpisodeApproveModal.show();
				this.showEpisodeApproveModal.suggestion = suggestion;
				this.showEpisodeApproveModal.slug = suggestion?.video?.slug;
				this.showEpisodeApproveModal.show = suggestion?.show;
				return;
			} else if (
				suggestion.type == "storyPlot" ||
				suggestion.type == "description" ||
				suggestion.type == "title" ||
				suggestion.type == "titleBn" ||
				suggestion.type == "titleEn" ||
				suggestion.type == "type" ||
				suggestion.type == "releasedYear"
			) {
				this.$refs.showSugApproveModal.show();
				this.showSugApproveModal = { ...suggestion };
				return;
			}

			this.confirmDialog({
				title: "Approve Suggestion",
				text: "Are you sure you want to approve this suggestion?",
			}).then((result) => {
				if (result) {
					this.$store.dispatch("suggestions/approveShowSuggestion", {
						suggestion,
						success: this.successToast,
						error: this.errorDialog,
					});
				}
			});
		},
		handleShowSugApprove() {
			const suggestion = this.showSugApproveModal;
			console.log(suggestion);
			this.$store.dispatch("suggestions/approveShowSuggestion", {
				suggestion,
				success: this.successToast,
				error: this.errorDialog,
			});
			return;
		},
		resetEpisodeApproveModal() {
			this.showEpisodeApproveModal = {
				suggestion: {},
				autoApproveEpisode: false,
				slug: "",
				show: {},
			};
		},
		resetSugApproveModal() {
			this.showSugApproveModal = {
				show: {},
			};
		},
		hadleEpisodeApprove() {
			const { suggestion, autoApproveEpisode, slug } =
				this.showEpisodeApproveModal;
			suggestion.autoApproveEpisode = autoApproveEpisode;
			suggestion.slug = slug;
			this.$store.dispatch("suggestions/approveShowSuggestion", {
				suggestion,
				success: this.successToast,
				error: this.errorDialog,
			});
			return;
		},
		rejectSuggestion({ id }) {
			this.$refs.showDeleteModal.show();
			this.showDeleteModal.id = id;
		},
		convertToShow(item, convertAsShow = false) {
			this.showDeleteModal.id = item.id;

			// get confirmation
			this.confirmDialog({
				title: "Convert to show suggestion",
				text: "Are you sure you want to convert this suggestion to show?",
			}).then((result) => {
				if (result) {
					this.handleShowDelete(convertAsShow);
				} else {
					this.showDeleteModal.id = null;
				}
			});
		},
		handleShowDelete(convertAsShow = false) {
			if (!this.showDeleteModal.id) {
				this.errorDialog({
					title: "Error",
					text: "Something went wrong. Please try again later.",
				});
				return;
			} else {
				let input = this.showDeleteModal;
				input.convertAsShow = convertAsShow;
				this.$store.dispatch("suggestions/rejectShowSuggestion", {
					input,
					success: this.successToast,
					error: this.errorDialog,
				});
			}
		},
		resetModal() {
			this.showDeleteModal = {
				id: null,
				reason: "",
				addAsShow: false,
			};
		},
		videoPlay(show = {}) {
			this.selectedShow = show;
			this.showVideoPlayer = show.youtubeVideoId ? true : false;
		},
	},
};
</script>
