import rows from './rows'

const searchShowsQuery = `
query($query: String ,$first: Int,$skip: Int, $includeInactive: Boolean, $showsNullTitleBn: Boolean){
	searchShow(query: $query, first: $first, skip: $skip, includeInactive: $includeInactive, showsNullTitleBn: $showsNullTitleBn){
    count
    rows{
      ${rows}
    }
  }
}`


export default searchShowsQuery
