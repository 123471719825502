<template>
    <layout-full>
        <router-view />
    </layout-full>
</template>
  
<script>
  import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
  
  import { mapGetters } from 'vuex'

  export default {
    components: {
      LayoutFull,
    },
    computed: {
      ...mapGetters('user', ['isLoggedIn']),
    },
    watch: {
      isLoggedIn() {
        if (this.isLoggedIn) {
          // redirect to back previous route
          if(this.$route.query.redirect) {
            this.$router.push({ name: this.$route.query.redirect, query: this.$route.query.query })
          } else {
            this.$router.push({ name: 'home' })
          }
        }
      },
    },
  }
</script>
  