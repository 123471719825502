export default {
  namespaced: true,
  state: {
    loading: false,
    data: null,
    error: null,
		allUsers: [],
  },
  getters: {
    isLoggedIn: state => !!state.data,
    user: state => state.data,
    isAdmin: state => state.data && state.data.type === 'admin',
    userLoading: state => state.loading,
    name: state => (state.data?.name || state.data?.displayName),
    role: state => state.data?.type,
    displayImage: state => state.data?.displayImage,
    email: state => state.data?.email,
		allUsers: state => state.allUsers,
  },
  mutations: {
    setUser (state, user) {
      state.data = user
      state.loading = false
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setError (state, error) {
      state.error = error
      state.loading = false
    },
		setAllUsers(state, users) {
			state.allUsers = users
		}
  },
  actions: {},
}
