<template>
	<b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white cursor-pointer">Youtube Search</h3>
		</template>
		<br>
		<div class="search-form">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
				<vue-autosuggest
					:suggestions="filteredOptions"
					:input-props="inputProps"
					@selected="onSelected"
					v-model="searchQuery"
					:limit="10"
					@input="onInputChange"
				>
					<template slot-scope="{suggestion}">
						<div class="d-flex align-items-center">
							<b-avatar :src="suggestion.item.thumbImageUrl" />
							<div class="detail ml-50">
								<b-card-text class="mb-0">
									{{ suggestion.item.name }}
								</b-card-text>
								<small class="text-muted">
									{{ suggestion.item.time }}
								</small>
							</div>
						</div>
					</template>
				</vue-autosuggest>
        <b-input-group-append>
          <b-button variant="primary" @click="getResult()">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
		<br>

		<b-table v-if="!loading && items.length" :tbody-tr-class="rowClass"  responsive="sm" :items="items" :fields="fields" class="text-center" striped bordered >
			<template v-slot:cell(title)="data">
				{{ data.item.snippet.title }}
			</template>
			<template v-slot:cell(thumbnail)="data">
				<img
					:src="data.item.snippet.thumbnails.default.url"
					:alt="data.item.snippet.title"
				/>
			</template>

			<template v-slot:cell(channel)="data">
				{{ data.item.snippet.channelTitle }}
			</template>

			<template v-slot:cell(actions)="data">
				<div class="d-flex justify-content-center dashboard-action">
					<a v-if="data.item.id.videoId"  @click="videoPlay(data.item.id.videoId)">
						<feather-icon icon="PlayCircleIcon"
						size="18"/>
					</a>
					<a v-if="data.item.id.videoId" @click="copyToClipboard(data.item.id.videoId)" title="copy youtube id">
						<feather-icon icon="ClipboardIcon"
						size="18"/>
					</a>
					<a v-if="data.item.id.videoId" @click="addShowDialog(data.item.id.videoId)" title="add show">
						<feather-icon icon="PlusCircleIcon"
						size="18"/>
					</a>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="loading">loading...</span>
			<span v-else>No result found</span>
		</div>

		<template v-slot:footer>
			<button
				v-if="prevPageToken"
				@click="getResult(prevPageToken)"
				class="btn btn-success m-2"
			>
				Prev
			</button>
			<button
				v-if="nextPageToken"
				@click="getResult(nextPageToken)"
				class="btn btn-success m-2"
			>
				Next
			</button>
		</template>


    <player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>

		<edit-show :input="editShow.input" :show="editShow.show" :approve="editShow.approve" @close="toggleEditShow()" />
  </b-card>

</template>

<script>
import { mapGetters } from 'vuex'

// show row
import rows from '@/graphql/query/shows/rows.js'

import EditShow from '@/components/shows/EditShow.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { BAvatar, BCardText } from 'bootstrap-vue'

export default {
  data() {
    return {
			searchQuery: '',
			filteredOptions: [],

      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Type tags to search",
      },
			fields: [
				{ key: 'title', label: 'Title' },
				{ key: 'thumbnail', label: 'Thumbnail' },
				{ key: 'channel', label: 'Channel' },
				{ key: 'actions', label: 'Actions' },
			],
			selectedShow: {},
			showVideoPlayer: false,
			editShow: {
				show: false,
				approve: false,
				input: {},
			},
    }
  },
	computed: {
    ...mapGetters('youtubeSearch', ['items', 'nextPageToken', 'prevPageToken', 'loading']),
		...mapGetters('tags', ['allTags']),
	},
	components: {
		EditShow,
		VueAutosuggest,
		BAvatar,
		BCardText,
	},
	methods: {
    videoPlay(videoId) {
			if(videoId) {
				this.selectedShow = {
					youtubeVideoId: videoId
				}
			} else {
				this.selectedShow = {}
			}
      this.showVideoPlayer = videoId ? true : false
    },
		debounceInput: debounce(function (e) {
			this.getResult()
		}, 500),
		onSelected(option) {
      this.searchQuery = option.item.name
			this.getResult()
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }

      const filteredData = this.allTags.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
		getResult(nextPage = '') {
			if(!this.searchQuery) {
				return
			}
			this.$store.dispatch('youtubeSearch/search', {
				search: this.searchQuery,
				page: nextPage
			})
		},
		addShowDialog(youtubeIds) {
			this.confirmDialog({
				title: 'Add Show',
				text: 'Are you sure you want to add this show?',
			}).then((result) => {
				if (result) {
					this.addShow(youtubeIds)
				}
			})
		},
		addShow(youtubeIds) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation($input: ShowInput!) {
							showCreate(input: $input) {
									${rows}
									episodes{
										id
										slug
										showId
										youtubeVideoId
										videoEmbeddable
										thumbImageUrl
										thumbImageUrlLg
										duration
										videoPublishedAt
										externalLink
										isActive
										isPublic
									}
							}
					}
					`,
					variables: { input: {youtubeVideoId: youtubeIds} }
				})
				.then((response) => {
					if(response.data.data?.showCreate) {
						this.toggleEditShow(response.data.data.showCreate, true)
					} else {
						this.errorDialog(response.data.errors[0].message);
					}
				})
		},
		toggleEditShow(show = {}, approve = false) {
			this.editShow.show = !this.editShow.show
			this.editShow.input = show
			if(approve) {
				this.editShow.input.isPublic = true
			}
			this.editShow.approve = approve
		},
		rowClass(item, type) {
			const colorClass = 'table-success'
			if (!item || type !== 'row') { return }

			if (item.existing) { return colorClass }
		},
	},




}
</script>

<style>
.modal-body{
	overflow: unset;
}
.input-icon {
	border-radius: 6px;
	padding: 2px;
	line-height: 17px;
}

</style>
