<template>
	<div>
		<div
			class="row justify-content-between"
			v-for="summary in summaries"
			:key="summary.user.id"
		>
			<div class="col-md-6 mb-3">
				<div class="card card-s">
					<div class="row g-0">
						<div class="col-md-8">
							<div class="card-body">
								<h5 class="card-title text-center">Summary</h5>
								<table class="table table-border">
									<tr>
										<td>
											<p class="card-text">
												<strong>Total Activities</strong>
											</p>
										</td>
										<td>
											<p class="card-text">
												<strong>{{ summary.total }}</strong>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="card-text">
												<strong>Shows Created</strong>
											</p>
										</td>
										<td>
											<p class="card-text">
												<strong>{{ summary.show_created }}</strong>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="card-text">
												<strong>Shows Contributed</strong>
											</p>
										</td>
										<td>
											<p class="card-text">
												<strong>{{ summary.show_contributed }}</strong>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="card-text">
												<strong>Tags Created</strong>
											</p>
										</td>
										<td>
											<p class="card-text">
												<strong>{{ summary.tag_created }}</strong>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p class="card-text">
												<strong>Tags Contributed</strong>
											</p>
										</td>
										<td>
											<p class="card-text">
												<strong>{{ summary.tag_contributed }}</strong>
											</p>
										</td>
									</tr>
								</table>
								<p class="card-text">
									<small class="text-muted"
										>Last activity on
										{{
											summary.last_activity_time
												? new Date(
														Number(summary.last_activity_time)
												  ).toLocaleString()
												: ""
										}}</small
									>
								</p>
							</div>
						</div>
						<div class="col-md-4 pl-0">
							<a
								v-if="
									summary.user.displayImage &&
									summary.user.displayImage != '/img/default.png'
								"
								:href="summary.user.displayImage"
								target="_blank"
							>
								<img :src="summary.user.displayImage" class="w-100" />
							</a>
							<svg
								style="max-height: 95px"
								v-else
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="user"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 448 512"
								class="svg-inline--fa fa-user fa-w-14"
							>
								<path
									fill="currentColor"
									d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
									class="___class_+?20___"
								></path>
							</svg>
							<div>
								<h4 class="m-2 text-center">{{ summary.user.name }}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 mb-3">
				<b-nav tabs fill>
					<b-nav-item :active="tableView" @click="toggleTableView">
						<feather-icon title="Table view" icon="ListIcon" />
					</b-nav-item>
					<b-nav-item :active="!tableView" @click="toggleTableView">
						<feather-icon title="Contribution History" icon="BarChartIcon" />
					</b-nav-item>
				</b-nav>
				<b-card v-if="!tableView">
					<vue-apex-charts
						type="line"
						height="360"
						:options="getChartData(summary.grouped_data).chartOptions"
						:series="getChartData(summary.grouped_data).series"
					/>
				</b-card>
				<b-card v-if="tableView">
					<b-table
						style="text-align: center"
						striped
						:items="getTableData(summary.grouped_data)"
						:fields="[
							{ key: 'date', label: 'Date' },
							{ key: 'show_created', label: 'Show Created' },
							{ key: 'tag_created', label: 'Tag Created' },
							{ key: 'show_contributed', label: 'Show Contributed' },
							{ key: 'tag_contributed', label: 'Tag Contributed' },
							{ key: 'total', label: 'Total' },
						]"
					></b-table>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { BCard, BNav, BNavItem } from "bootstrap-vue";

import { $themeColors } from "@themeConfig";
const $trackBgColor = "#e9ecef";
export default {
	props: {
		summaries: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			tableView: false,
			tableData: [],
		};
	},
	components: {
		VueApexCharts,
		BCard,
		BNav,
		BNavItem,
	},
	methods: {
		toggleTableView() {
			this.tableView = !this.tableView;
		},
		getChartData(groupedData) {
			groupedData = JSON.parse(groupedData);

			const charData = {
				series: [
					{
						name: "Show Created",
						data: [],
					},
					{
						name: "Tag Created",
						data: [],
					},
					{
						name: "Show Contributed",
						data: [],
					},
					{
						name: "Tag Contributed",
						data: [],
					},
					{
						name: "Total",
						data: [],
					},
				],
				dates: [],
			};

			for (const date in groupedData) {
				charData.dates.push(date);
				charData.series[0].data.push(groupedData[date].show_created);
				charData.series[1].data.push(groupedData[date].tag_created);
				charData.series[2].data.push(groupedData[date].show_contributed);
				charData.series[3].data.push(groupedData[date].tag_contributed);
				charData.series[4].data.push(groupedData[date].total);
			}

			const chartOptions = {
				chart: {
					type: "line",
					stacked: false,
					height: 320,
					zoom: {
						type: "x",
						enabled: true,
						autoScaleYaxis: true,
					},
					toolbar: {
						autoSelected: "zoom",
					},
				},
				colors: ["#77B6EA", "#545454", "#ef5350", "#66bb6a", "#ffeb3b"],
				dataLabels: {
					enabled: true,
				},
				stroke: {
					curve: "smooth",
				},
				title: {
					text: "",
					align: "left",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					categories: charData.dates,
					title: {
						text: "Dates",
					},
				},
				yaxis: {
					title: {
						text: "Contributions",
					},
					min: 0,
				},
				legend: {
					position: "top",
					horizontalAlign: "right",
					floating: true,
					offsetY: -25,
					offsetX: -5,
				},
			};

			return {
				series: charData.series,
				chartOptions,
			};
		},
		getTableData(groupedData) {
			groupedData = JSON.parse(groupedData);
			const tableData = [];

			for (const date in groupedData) {
				tableData.push({
					date: date,
					show_created: groupedData[date].show_created,
					tag_created: groupedData[date].tag_created,
					show_contributed: groupedData[date].show_contributed,
					tag_contributed: groupedData[date].tag_contributed,
					total: groupedData[date].total,
				});
			}

			return tableData;
		},
	},
};
</script>

<style scoped>
.border-left-1px {
	border-left: 1px solid #d1d1d1;
}
.border-bottom-1px {
	border-bottom: 1px solid #d1d1d1;
}
.card-s {
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

.top-right-button {
	width: 30px;
	height: 30px;
}
</style>
