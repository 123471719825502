<template>
  <b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 @click="getSuggestions" title="click to refresh" class="text-white">Unknown Show Suggestions</h3>
		</template>
		<br>
		<template>
			<b-table striped bordered  responsive :items="unknownShowSuggestions" :fields="fields">
				<template v-slot:cell(action)="data">
					<!-- <b-button variant="primary" class="mr-min" @click="addAsShow(data.item)">Add As Show</b-button> -->
					<b-button variant="danger" @click="deleteSuggestion(data.item.id)">Delete</b-button>
				</template>
			</b-table>
		</template>
		<br>

		<b-pagination-nav
      v-model="page"
      :link-gen="linkGen"
      :number-of-pages="Math.ceil(count / limit) || 1"
      use-router
    ></b-pagination-nav>
		<show-count :count="count" :limit="limit" :page="page"/>

  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
	data() {
		return {
      page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 20,
			fields: [
				{ key: 'id', label: 'ID' },
				{ key: 'title', label: 'Title' },
				{ key: 'tags', label: 'Tags' },
				{ key: 'releasedYear', label: 'Released Year' },
				{ key: 'externalLink', label: 'External Link' },
				{ key: 'action', label: 'Action' },
			],
		}
	},
	mounted() {
		this.getSuggestions()
	},
	computed: {
    ...mapGetters('unknownShowSuggestions', ['unknownShowSuggestions', 'count', 'loading']),
	},
	methods: {
		linkGen(page) {
			return {
				name: 'unknown-show-suggestions',
				params: { page },
			}
		},
		getSuggestions() {
			this.$store.dispatch('unknownShowSuggestions/getSuggestions', {
				page: this.page,
				limit: this.limit,
			})
		},
		addAsShow(unknownShowSuggestion) {
			//
		},
		deleteSuggestion(id) {
			this.confirmDialog()
				.then(() => {
					this.$store.dispatch('unknownShowSuggestions/deleteSuggestion', {id, success: this.successToast, error: this.errorDialog})
				})
		},
	},
}
</script>

<style>

</style>
