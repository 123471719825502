import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/shows',
      name: 'shows',
      component: () => import('@/views/ShowsPage.vue'),
      meta: {
        pageTitle: 'Shows',
        breadcrumb: [
          {
            text: 'Shows',
            active: true,
          },
        ],
      },
    },
		{
			path: '/unknown-show-suggestions',
			name: 'unknownShowSuggestions',
			component: () => import('@/views/UnknownShowSuggestionsPage.vue'),
			meta: {
				pageTitle: 'Unknown Show Suggestions',
				breadcrumb: [
					{
						text: 'Unknown Show Suggestions',
						active: true,
					}
				]
			}
		},
		{
			path: '/youtube-search',
			name: 'youtubeSearch',
			component: () => import('@/views/YoutubeSearchPage.vue'),
			meta: {
				pageTitle: 'Youtube Search',
				breadcrumb: [
					{
						text: 'Youtube Search',
						active: true,
					},
				],
			},
		},
		{
			path: '/reviews',
			name: 'reviews',
			component: () => import('@/views/ReviewsPage.vue'),
			meta: {
				pageTitle: 'Reviews',
				breadcrumb: [
					{
						text: 'Reviews',
						active: true,
					},
				],
			},
		},
		{
			path: '/suggestions',
      name: 'suggestions',
      component: () => import('@/views/SuggestionPage.vue'),
			children: [
				{
					path: '',
					name: 'showSuggestions',
					component: () => import('@/components/suggestions/Shows.vue'),
				},
				{
					path: 'stars',
					name: 'starSuggestions',
					component: () => import('@/components/suggestions/Stars.vue'),
				},
				{
					path: 'channels',
					name: 'channelSuggestions',
					component: () => import('@/components/suggestions/Channels.vue'),
				},
				{
					path: 'star-channel',
					name: 'starChannelSuggestions',
					component: () => import('@/components/suggestions/StarChannel.vue'),
				},
				{
					path: 'videos',
					name: 'videoSuggestions',
					component: () => import('@/components/suggestions/Videos.vue'),
				},
				{
					path: 'reports',
					name: 'reportSuggestions',
					component: () => import('@/components/suggestions/Reports.vue'),
				},
			],
      meta: {
        pageTitle: 'Suggestions',
        breadcrumb: [
          {
            text: 'Suggestions',
            active: true,
          },
        ],
      },
		},
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/UsersPage.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
		{
			path: '/user-activities',
			name: 'userActivities',
			component: () => import('@/views/UserActivitiesPage.vue'),
			meta: {
				pageTitle: 'User Activities',
				breadcrumb: [
					{
						text: 'User Activities',
						active: true,
					},
				],
			},
		},
		{
			path: '/tags',
			name: 'tags',
			component: () => import('@/views/TagsPage.vue'),
			meta: {
				pageTitle: 'Tags',
				breadcrumb: [
					{
						text: 'Tags',
						active: true,
					},
				],
			}
		},{
			path: '/stars',
			name: 'stars',
			component: () => import('@/views/StarsPage.vue'),
			meta: {
				pageTitle: 'Stars',
				breadcrumb: [
					{
						text: 'Stars',
						active: true,
					},
				],
			}
		},{
			path: '/channels',
			name: 'channels',
			component: () => import('@/views/ChannelsPage.vue'),
			meta: {
				pageTitle: 'Channels',
				breadcrumb: [
					{
						text: 'Channels',
						active: true,
					},
				],
			}
		},{
			path: '/aliases',
			name: 'aliases',
			component: () => import('@/views/AliasesPage.vue'),
			meta: {
				pageTitle: 'Aliases',
				breadcrumb: [
					{
						text: 'Aliases',
						active: true,
					},
				],
			}
		},
		{
      path: '/websites',
      name: 'websites',
      component: () => import('@/views/WebsitesPage.vue'),
      meta: {
        pageTitle: 'Websites',
        breadcrumb: [
          {
            text: 'Websites',
            active: true,
          },
        ],
      },
    },
		{
			path: '/files',
			name: 'files',
			component: () => import('@/views/FilesPage.vue'),
			meta: {
				pageTitle: 'Files',
				breadcrumb: [
					{
						text: 'Files',
						active: true,
					},
				],
			}
		},
		{
			path: '/youtube-notifications',
			name: 'youtubeNotification',
			component: () => import('@/views/YoutubeNotificationsPage.vue'),
			meta: {
				pageTitle: 'Youtube Notifications',
				breadcrumb: [
					{
						text: 'Youtube Notifications',
						active: true,
					},
				],
			}
		},
		{
			path: '/error-logs',
			name: 'errorLogs',
			component: () => import('@/views/ErrorLogsPage.vue'),
			meta: {
				pageTitle: 'Error Logs',
				breadcrumb: [
					{
						text: 'Error Logs',
						active: true,
					},
				],
			}
		},
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'unauthorize',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  //
})

export default router
