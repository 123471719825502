export default {
	namespaced: true,
	state: {
		episodes: [],
		activeEpisodes: [],
		activeEpisodePage: 1,
		activeEpisodeCount: 0,
		activeEpisodesLoading: false,
		suggestedEpisodes: [],
		requestedEpisodePage: 1,
		requestedEpisodeCount: 0,
		requestedEpisodesLoading: false,
		loading: false,
		showId: null,
		episodesToDelete: [],
		defaultEpisode: null,
		limit: 20,
	},
	getters: {
		episodes: state => state.episodes,
		activeEpisodes: state => state.activeEpisodes,
		activeEpisodePage: state => state.activeEpisodePage,
		activeEpisodeCount: state => state.activeEpisodeCount,
		activeEpisodesLoading: state => state.activeEpisodesLoading,
		suggestedEpisodes: state => state.suggestedEpisodes,
		requestedEpisodePage: state => state.requestedEpisodePage,
		requestedEpisodeCount: state => state.requestedEpisodeCount,
		requestedEpisodesLoading: state => state.requestedEpisodesLoading,
		loading: state => state.loading,
		episodesToDelete: state => state.episodesToDelete,
		showId: state => state.showId,
		defaultEpisode: state => state.defaultEpisode,
		limit: state => state.limit,
	},
	mutations: {
		setEpisodes(state, { episodes, active = false }) {
			if (active) {
				state.activeEpisodes = episodes.rows || []
				state.activeEpisodeCount = episodes.count || 0
				state.activeEpisodesLoading = false
			} else {
				state.suggestedEpisodes = episodes.rows || []
				state.requestedEpisodeCount = episodes.count || 0
				state.requestedEpisodesLoading = false
			}
		},
		setActiveEpisodes(state, episodes) {
			state.activeEpisodes = episodes || []
			state.loading = false
		},
		setActiveEpisodePage(state, page) {
			state.activeEpisodePage = page
		},
		setActiveEpisode(state, { episode, youtubeVideoId, approve }) {
			state.activeEpisodes = state.activeEpisodes.map(ep => {
				if (ep.youtubeVideoId == youtubeVideoId) {
					ep.slug = episode.slug
					ep.youtubeVideoId = episode.youtubeVideoId
					ep.youtubeTitle = episode.youtubeTitle
					ep.description = episode.description
					ep.videoEmbeddable = episode.videoEmbeddable
					ep.thumbImageUrl = episode.thumbImageUrl
					ep.thumbImageUrlLg = episode.thumbImageUrlLg
					ep.duration = episode.duration
					if (approve) {
						ep.isActive = episode.isActive
					} else {
						ep.videoPublishedAt = new Date(episode.videoPublishedAt).getTime()
					}
				}

				return ep
			})
		},
		setEpisode(state, { episode, youtubeVideoId, approve }) {
			state.episodes = state.episodes.map(ep => {
				if (ep.youtubeVideoId == youtubeVideoId) {
					ep.slug = episode.slug
					ep.youtubeVideoId = episode.youtubeVideoId
					ep.youtubeTitle = episode.youtubeTitle
					ep.description = episode.description
					ep.videoEmbeddable = episode.videoEmbeddable
					ep.thumbImageUrl = episode.thumbImageUrl
					ep.thumbImageUrlLg = episode.thumbImageUrlLg
					ep.duration = episode.duration
					if (approve) {
						ep.isActive = episode.isActive
					} else {
						ep.videoPublishedAt = new Date(episode.videoPublishedAt).getTime()
					}
				}

				return ep
			})
		},

		setLoading(state, { loading, active = false }) {
			if (active) {
				state.activeEpisodesLoading = loading
			} else {
				state.requestedEpisodesLoading = loading
			}
		},
		setSlug(state, { index, slug, isActive = false }) {
			if (isActive) {
				state.activeEpisodes[index].slug = slug
			} else {
				state.suggestedEpisodes[index].slug = slug
			}
		},
		setYoutubeVideoId(state, { index, youtubeVideoId, isActive = false }) {
			if (isActive) {
				state.activeEpisodes[index].youtubeVideoId = youtubeVideoId
			} else {
				state.suggestedEpisodes[index].youtubeVideoId = youtubeVideoId
			}
		},
		setDuration(state, { index, duration, isActive = false }) {
			if (isActive) {
				state.activeEpisodes[index].duration = duration
			} else {
				state.suggestedEpisodes[index].duration = duration
			}
		},
		setVideoPublishedAt(state, { index, videoPublishedAt, isActive }) {
			if (isActive) {
				state.activeEpisodes[index].videoPublishedAt = videoPublishedAt
			} else {
				state.suggestedEpisodes[index].videoPublishedAt = videoPublishedAt
			}
		},
		setExternalLink(state, { index, externalLink, isActive = false }) {
			if (isActive) {
				state.activeEpisodes[index].externalLink = externalLink
			} else {
				state.suggestedEpisodes[index].externalLink = externalLink
			}
		},
		setVideoEmbeddable(state, { id, videoEmbeddable, isActive }) {
			if (isActive) {
				state.activeEpisodes = state.activeEpisodes.map(e => {
					if (e.id == id) {
						e.videoEmbeddable = videoEmbeddable
					}
					return e
				})
			} else {
				state.suggestedEpisodes = state.suggestedEpisodes.map(e => {
					if (e.id == id) {
						e.videoEmbeddable = videoEmbeddable
					}
					return e
				})
			}

		},
		toggleVideoEmbeddable(state, { index, isActive }) {
			if (isActive) {
				state.activeEpisodes[index].videoEmbeddable = !state.activeEpisodes[index].videoEmbeddable
			} else {
				state.suggestedEpisodes[index].videoEmbeddable = !state.suggestedEpisodes[index].videoEmbeddable
			}
		},
		toggleIsPublic(state, { index, isActive }) {
			if (isActive) {
				state.activeEpisodes[index].isPublic = !state.activeEpisodes[index].isPublic
			} else {
				state.suggestedEpisodes[index].isPublic = !state.suggestedEpisodes[index].isPublic
			}
		},
		toggleDefault(state, slug) {
			if (state.defaultEpisode == slug) {
				state.defaultEpisode = null
			} else {
				state.defaultEpisode = slug
			}
		},
		setDefault(state, { id, isDefault }) {
			state.episodes = state.episodes.map(e => {
				if (e.id == id) {
					e.isDefault = isDefault
				}
				return e
			})
		},
		setPublic(state, { id, isPublic }) {
			state.episodes = state.episodes.map(e => {
				if (e.id == id) {
					e.isPublic = isPublic
				}
				return e
			})
		},
		setShowId(state, showId) {
			state.showId = showId
		},
		setDefaultEpisode(state, defaultEpisode) {
			state.defaultEpisode = defaultEpisode
		},
		setActiveEpisodePage(state, page) {
			state.activeEpisodePage = page
		},
		setRequestedEpisodePage(state, page) {
			state.requestedEpisodePage = page
		},

		deleteEpisode(state, { index, isActive = false }) {
			console.log(index)
			if (isActive) {
				const id = state.activeEpisodes[index].id
				const slug = state.activeEpisodes[index].slug
				if (id) {
					state.episodesToDelete.push(slug)
				}
				state.activeEpisodes.splice(index, 1)
			} else {
				const id = state.episodes[index].id
				const slug = state.episodes[index].slug
				if (id) {
					state.episodesToDelete.push(slug)
				}
				state.episodes.splice(index, 1)
			}
		},
		deleteSuggestion(state, id) {
			state.episodes = state.episodes.filter(e => {
				if (e.id == id) {
					return false
				}
				return true
			})
		},
		addNewEpisode(state) {
			state.activeEpisodes.push({
				id: '',
				slug: '',
				showId: state.showId,
				youtubeVideoId: '',
				youtubeTitle: '',
				description: '',
				videoEmbeddable: null,
				thumbImageUrl: null,
				thumbImageUrlLg: null,
				videoPublishedAt: null,
				duration: null,
				externalLink: null,
				isPublic: false,
				isActive: true,
			})
		},
		setLimit(state, limit) {
			state.limit = limit
		},

	},
	actions: {
		async getEpisodes({ commit, state }, { id, isActive, defaultEpisode }) {
			commit('setLoading', { loading: true, active: isActive })
			commit('setShowId', id)
			commit('setDefaultEpisode', defaultEpisode)
			let skip = 0;
			if (isActive) {
				skip = (state.activeEpisodePage - 1) * state.limit
			} else {
				skip = (state.requestedEpisodePage - 1) * state.limit
			}
			axios
				.post("/graphql", {
					operationName: "",
					query: `query($showId: ID!, $isActive: Boolean!, $first: Int, $skip: Int) {
						getEpisodes(showId: $showId, isActive: $isActive, first: $first, skip: $skip) {
							count
							rows {
								id
								slug
								showId
								youtubeTitle
								description
								youtubeVideoId
								videoEmbeddable
								thumbImageUrl
								thumbImageUrlLg
								duration
								videoPublishedAt
								externalLink
								isActive
								isPublic
							}
						}
				}`,
					variables: { showId: id, isActive, first: state.limit, skip }
				}).then(res => {
					commit('setEpisodes', { episodes: res.data.data.getEpisodes, active: isActive })
				}).catch(err => {
					console.log(err)
				}).finally(() => {
					commit('setLoading', { loading: false, active: isActive })
				})
		},
		async updateEpisodes({ commit, state }, { id = null, success = console.log, error = console.log }) {
			let inputData = [];
			if (id) {
				//concat the episode with the id
				let allEpisodes = [
					...state.activeEpisodes,
					...state.suggestedEpisodes
				]
				inputData = JSON.parse(JSON.stringify(allEpisodes))
				inputData = inputData.filter(e => e.id == id)

				inputData[0].videoPublishedAt = new Date(Number(inputData[0].videoPublishedAt)).toISOString()
			} else {
				// create a copy of the episodes array
				let allEpisodes = [
					...state.activeEpisodes,
					...state.suggestedEpisodes
				]
				inputData = JSON.parse(JSON.stringify(allEpisodes))

				//convert the videoPublishedAt to a date object
				inputData.forEach(e => {
					e.videoPublishedAt = new Date(Number(e.videoPublishedAt)).toISOString()
				})
			}
			axios
				.post("/graphql", {
					operationName: "",
					query: `
							mutation($showId: ID, $defaultEpisode: String, $episodeUpdateInputs: [episodeUpdateInputs]!, $episodesToDelete: [ID] ) {
								episodeBulkUpdate(showId: $showId, defaultEpisode: $defaultEpisode, episodeUpdateInputs: $episodeUpdateInputs,  episodesToDelete: $episodesToDelete) {
									defaultEpisode
									episodes{
									id
									slug
									showId
									youtubeTitle
									description
									youtubeVideoId
									videoEmbeddable
									videoPublishedAt
									thumbImageUrl
									thumbImageUrlLg
									duration
									externalLink
									isActive
									isPublic
								}
							}
						}
            `,
					variables: {
						showId: state.showId,
						defaultEpisode: state.defaultEpisode,
						episodeUpdateInputs: inputData,
						episodesToDelete: state.episodesToDelete
					}
				}).then(res => {
					if (res.data.errors) {
						error(res.data.errors[0].message)
					} else {
						success('Episodes Updated')
					}
				}).catch(err => {
					console.log(err)
				})
		},
		async reloadEpisode({ commit, state }, { index, youtubeVideoId, isActive, success = console.log, error = console.log }) {
			// episode from activeEpisodes array
			if (youtubeVideoId) {
				youtubeVideoId = youtubeVideoId
			} else {
				youtubeVideoId = isActive ? state.activeEpisodes[index].youtubeVideoId : state.suggestedEpisodes[index].youtubeVideoId
			}
			await axios.post("/graphql", {
				operationName: "",
				query: `
					query($youtubeVideoId: String) {
							loadFromYouTube(youtubeVideoId: $youtubeVideoId) {
									slug
									youtubeVideoId
									youtubeTitle
									description
									videoEmbeddable
									thumbImageUrl
									thumbImageUrlLg
									videoPublishedAt
									duration
							}
					}
					`,
				variables: { youtubeVideoId }
			}).then(res => {
				if (res.data.data?.loadFromYouTube) {
					if (isActive) {
						commit('setActiveEpisode', { youtubeVideoId, episode: res.data.data.loadFromYouTube })
					} else {
						commit('setEpisode', { youtubeVideoId, episode: res.data.data.loadFromYouTube })
					}
					commit('setEpisode', { youtubeVideoId, episode: res.data.data.loadFromYouTube })
					success('Episode Reloaded')
				} else {
					error('Episode Not Found')
				}
			}).catch(err => {
				console.log(err)
			})


		},
		async approveEpisode({ commit, state }, { episode, success = console.log, error = console.log }) {
			episode = JSON.parse(JSON.stringify(episode))
			// set date
			episode.videoPublishedAt = new Date(Number(episode.videoPublishedAt)).toISOString()
			axios
				.post("/graphql", {
					operationName: "",
					query: `
							mutation($SuggestedEpisodeInput: episodeUpdateInputs) {
								approveEpisode(SuggestedEpisodeInput: $SuggestedEpisodeInput) {
									id
									slug
									showId
									youtubeTitle
									description
									youtubeVideoId
									videoEmbeddable
									videoPublishedAt
									thumbImageUrl
									thumbImageUrlLg
									duration
									externalLink
									isActive
									isPublic
								}
							}
						`,
					variables: {
						SuggestedEpisodeInput: episode
					}
				}).then(res => {
					if (res.data.data?.approveEpisode) {
						commit('setEpisode', { youtubeVideoId: episode.youtubeVideoId, episode: res.data.data.approveEpisode, approve: true })
						success('Episode Approved')
					} else {
						error('Something went wrong')
					}
				}).catch(err => {
					console.log(err)
					error('Something went wrong')
				})
		},
		async deleteSuggestion({ commit, state }, { id, addAsShow, success = console.log, error = console.log }) {
			axios
				.post("/graphql", {
					operationName: "",
					query: `
							mutation($id: ID!, $addAsShow: Boolean) {
								deleteSuggestedEpisode(id: $id, addAsShow: $addAsShow)
							}
					`,
					variables: {
						id: id,
						addAsShow: addAsShow
					}
				})
				.then(response => {
					if (
						response.data &&
						response.data.data.deleteSuggestedEpisode
					) {
						commit('deleteSuggestion', id)
						success('Suggestion Deleted')
					} else {
						error('Something went wrong')
					}
				});
		}
	},
}
