<template>
  <div>
    <b-card title="Quick Links">
      <b-card-text>
				<b-button to="/shows" variant="primary" class="mr-1">Shows</b-button>
				<b-button to="/suggestions" variant="primary" class="mr-1">Suggestions</b-button>
				<b-button to="/stars" variant="primary" class="mr-1">Stars</b-button>
				<b-button to="/channels" variant="primary" class="mr-1">Channels</b-button>
			</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
