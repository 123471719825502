import Vue from 'vue'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.mixin({
	methods: {
		sayHello() {
			console.log('Hello from global method!')
		},
		appConfig() {
			return $themeConfig.app
		},
		getPublicUrl(url) {
			return process.env.VUE_APP_FRONTEND_URL + url
		},
		getAPIUrl() {
			return process.env.VUE_APP_GRAPHQL_ENDPOINT
		},
		largeImage(image) {
			return image?.replace("mqdefault", "maxresdefault") || image;
		},
		copyToClipboard(text, msg = "YouTube ID copied.") {
			navigator.clipboard.writeText(text);
			this.successToast(msg)
		},
		confirmDialog(options = {}) {
			const title = options.title || 'Are you sure?'
			const text = options.text || 'You won\'t be able to revert this!'
			const okVariant = options.okVariant || 'primary'
			const okTitle = options.okTitle || 'Yes'
			const cancelVariant = options.cancelVariant || 'outline-secondary'
			const cancelTitle = options.cancelTitle || 'No'
			const centered = options.centered || true
			const hideHeaderClose = options.hideHeaderClose || false
			const size = options.size || 'sm'
			return this.$bvModal.msgBoxConfirm(text, {
				title,
				okVariant,
				okTitle,
				cancelVariant,
				cancelTitle,
				centered,
				hideHeaderClose,
				size,
			})
		},
		successToast(msg) {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Success',
					icon: 'CheckIcon',
					variant: 'success',
					text: msg,
				},
				position: 'top-right',
				duration: 5000,
			})
		},
		errorToast(msg) {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Error',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					text: msg,
				},
				position: 'top-right',
				duration: 5000,
			})
		},
		errorDialog(msg, options = {}) {
			this.$bvModal.msgBoxOk(msg, {
				title: options.title || 'Error',
				okVariant: 'danger',
				centered: true,
				hideHeaderClose: false,
				size: 'sm',
			})
		},
		warningToast(msg) {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Warning',
					icon: 'AlertTriangleIcon',
					variant: 'warning',
					text: msg,
				},
				position: 'top-right',
				duration: 5000,
			})
		},
		safeTrim(str = null) {
			if (str == null || str == undefined || str == "") {
				return "";
			}
			return str.trim();
		},

	},
})
