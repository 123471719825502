<template>
	<b-card>

		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white cursor-pointer" @click="initData" title="Click to refresh aliases.">Orphan Alias</h3>
		</template>
		<br>
		<div class="search-form">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="Search by name..." @input="debounceInput" v-model.trim="searchQuery"/>
        <b-input-group-append>
          <b-button variant="primary" @click="getAliases(1)">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
		<br>

		<b-table striped bordered  v-if="!loading && count" responsive="sm" :items="aliases" :fields="fields" class="text-center">
			<template v-slot:cell(totalConnection)="data">
				{{ data.item.showstags.length }}
			</template>
			<template v-slot:cell(showCount)="data">
				{{ showsCount(data.item.showstags) }}
			</template>


			<template v-slot:cell(actions)="data">
				<div class="d-flex justify-content-center">
					<b-button variant="primary" class="mr-min btn-icon" @click="toggleAction(data.item)">Action</b-button>
					<b-button variant="danger" class="mr-min btn-icon" @click="deleteAlias(data.item.id)">
						<feather-icon icon="TrashIcon" size="18"/>
					</b-button>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="loading">loading...</span>
			<span v-else>No aliases found</span>
		</div>

		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
		</template>

		<!-- Action-->
    <b-modal
      v-model="actionModal.show"
			ok-title="close"
			ok-only
      size="lg"
      title="Action"
    >
			<div class="action">
				<div class="aliasname mt-1">
					<h4>Alias Name: {{ actionModal.alias.aliasName }}</h4>
				</div>
				<div v-if="actionModal.connectToTag" class="connectToTag my-1">
					<div class="row">
						<div class="col-8">
							<vue-bootstrap-typeahead
								@hit="selectedStar"
								:minMatchingChars="1"
								:serializer="(tag) => tag.name"
								:data="allStarTags">
									<template slot="suggestion" slot-scope="{ data, htmlText }">
										<div class="d-flex align-items-center">
											<img
													class="rounded-circle"
													:src="data.thumbImageUrl+'-30x30.jpg'"
													style="width: 30px; height: 30px;" />

											<span class="ml-4" v-html="htmlText"></span>
										</div>
								</template>
							</vue-bootstrap-typeahead>

						</div>
						<div class="col-4">
							<button
								type="button"
								@click="connectToExisting"
								class="btn btn-success"
							>
								Add
							</button>
						</div>
					</div>
				</div>
				<div class="connect-button">
					<b-button variant="primary" @click="(actionModal.connectToTag = !actionModal.connectToTag)">Connect To Existing Tag</b-button>
				</div>
			</div>
			<hr>

			<b-tabs>
				<b-tab
					active
					title="Shows Tags"
				>
					<b-table v-if="!loading && actionModal.alias.showstags && actionModal.alias.showstags.length > 0" responsive="sm" :items="actionModal.alias.showstags" :fields="showTagFields" class="text-center" striped bordered >
						<template v-slot:cell(title)="data">
							<b-link :href="getPublicUrl(data.item.show.url)" target="__blank">{{ data.item.show.title }}</b-link>
						</template>
						<template v-slot:cell(thumbImageUrl)="data">
							<img class="w-200px" v-if="data.item.show.thumbImageUrl" :src="data.item.show.thumbImageUrl" alt="Thumbnail">
						</template>
						<template v-slot:cell(actions)="data">
							<div class="d-flex justify-content-center">
								<b-button variant="primary" class="mr-min btn-icon" v-if="data.item.show.videoEmbeddable" @click="videoPlay(data.item.show)">
										<feather-icon icon="PlayCircleIcon"
										size="18"/>
								</b-button>

								<a v-else :href="'https://www.youtube.com/watch?v='+ data.item.show.youtubeVideoId" class="text-white" target="_blank">
									<b-button variant="primary" class="mr-min btn-icon" >
										<feather-icon icon="PlayCircleIcon"
										size="18"/>
									</b-button>
								</a>

								<b-button variant="danger" class="mr-min btn-icon" @click="deleteConnection([{id: data.item.id}])">
									<feather-icon icon="TrashIcon" size="18"/>
								</b-button>
							</div>
						</template>
					</b-table>
					<div v-else class="p-2 text-center">
						<span v-if="loading">loading...</span>
						<span v-else>No data found</span>
					</div>
				</b-tab>
				<b-tab title="Shows">
					<b-table v-if="!loading && makeUnique(actionModal.alias.showstags).length > 0" responsive="sm" :items="makeUnique(actionModal.alias.showstags)" :fields="showFields" class="text-center" striped bordered >
						<template v-slot:cell(title)="data">
							<b-link :href="getPublicUrl(data.item.url)" target="__blank">{{ data.item.title }}</b-link>
						</template>
						<template v-slot:cell(thumbImageUrl)="data">
							<img class="w-200px" v-if="data.item.thumbImageUrl" :src="data.item.thumbImageUrl" alt="Thumbnail">
						</template>
						<template v-slot:cell(actions)="data">
							<div class="d-flex justify-content-center">
								<b-button variant="primary" class="mr-min btn-icon" v-if="data.item.videoEmbeddable" @click="videoPlay(data.item)">
										<feather-icon icon="PlayCircleIcon"
										size="18"/>
								</b-button>

								<a v-else :href="'https://www.youtube.com/watch?v='+ data.item.youtubeVideoId" class="text-white" target="_blank">
									<b-button variant="primary" class="mr-min btn-icon" >
										<feather-icon icon="PlayCircleIcon"
										size="18"/>
									</b-button>
								</a>

								<b-button variant="danger" class="mr-min btn-icon" @click="deleteAllConnection(data.item.id)">
									<feather-icon icon="TrashIcon" size="18"/>
								</b-button>
							</div>
						</template>
					</b-table>
					<div v-else class="p-2 text-center">
						<span v-if="loading">loading...</span>
						<span v-else>No data found</span>
					</div>
				</b-tab>
			</b-tabs>

    </b-modal>
    <!-- Action end -->

		<player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedShow" :videoPlay="videoPlay"></player-modal>
  </b-card>

</template>

<script>
import {  BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
			page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 20,
			searchQuery: '',
			fields: [
				{ key: 'id', label: '#' },
				{ key: 'aliasName', label: 'Name' },
				{ key: 'totalConnection', label: 'Total Connection' },
				{ key: 'showCount', label: 'Shows' },
				{ key: 'actions', label: 'Actions' },
			],
			actionModal: {
				show: false,
				alias: {},
				connectToTag: false,
			},
			showTagFields: [
				{ key: 'title', label: 'Title' },
				{ key: 'thumbImageUrl', label: 'Thumbnail' },
				{ key: 'tagConnectType', label: 'Connection Type' },
				{ key: 'actions', label: 'Actions' },
			],
			showFields: [
				{ key: 'title', label: 'Title' },
				{ key: 'thumbImageUrl', label: 'Thumbnail' },
				{ key: 'actions', label: 'Actions' },
			],
			selectedShow: {},
			showVideoPlayer: false,
			selectedStarD: null
    }
  },
	computed: {
    ...mapGetters('aliases', ['aliases', 'count', 'loading']),
		...mapGetters('tags', ['allStarTags']),
	},
  mounted() {
    this.initData()
  },
  watch: {
    page () {
      this.getAliases()
    },
  },
	methods: {
		initData() {
    	this.getAliases()
		},
		linkGen(page) {
			return {
				name: 'aliases',
				query: {
					page,
					limit: this.limit,
				},
			}
		},
		debounceInput: debounce(function (e) {
			this.getAliases(1)
		}, 500),
		getAliases(page) {
			if(page) {
				this.page = page
			}
			this.$store.dispatch('aliases/getAliases', {
				skip: (this.page - 1) * this.limit,
				limit: this.limit * 1,
				query: this.searchQuery,
			})
		},
		toggleAction(alias) {
			this.actionModal.show = true
			this.actionModal.alias = alias
		},
		showsCount(showsConnection) {
      return this.makeUnique(showsConnection).length;
    },
		selectedStar(input) {
      this.selectedStarD = input;
    },
    makeUnique(showsConnection = []) {
      let shows = [];
      showsConnection.forEach((connection) => {
        shows.push(connection.show);
      });
      let uniqueShows = Array.from(new Set(shows.map((a) => a.id))).map(
        (id) => {
          return shows.find((a) => a.id === id);
        }
      );
      return uniqueShows;
    },
		deleteAlias(id) {
			this.confirmDialog().then((value) => {
				if (value) {
					this.$store.dispatch('aliases/deleteAlias', {id, success: this.successToast, error: this.errorDialog})
				}
			})
		},
		deleteConnection(connections) {
      this.confirmDialog().then((result) => {
        if (result) {
					this.$store.dispatch('aliases/deleteConnections', {
						connections,
						alias: this.actionModal.alias,
						success: this.successToast,
						error: this.errorDialog,
					});
        }
      });
    },
    deleteAllConnection(showId = null) {
      if (showId) {
        let connections = [];
        for (let tag of this.actionModal.alias.showstags) {
          if (tag.show.id == showId) {
            connections.push({ id: tag.id });
          }
        }
        this.deleteConnection(connections);
      }
    },
		videoPlay(show = {}) {
      this.selectedShow = show
      this.showVideoPlayer = show.id ? true : false
    },
		connectToExisting() {
      this.confirmDialog().then((result) => {
        if (result) {
          let starId = this.selectedStarD.id;
          let aliasId = this.actionModal.alias.id;
					this.$store.dispatch('aliases/connectToExisting', {
						starId,
						aliasId,
						success: (message) => {
							this.successToast(message);
							this.actionModal.connectToTag = false;
							this.selectedStarD = null;
							this.actionModal.alias = {};
							this.actionModal.show = false;
						},
						error: this.errorDialog,
					});

        }
      });
    },

	},





}
</script>

<style>
.modal-body{
	overflow: unset;
}

</style>
