import rows from './rows'

const requestedShowsQuery = `
query($first: Int, $skip: Int){
  requestedShows(first: $first, skip: $skip){
    count
    rows{
      ${rows}
    }
  }
}`


export default requestedShowsQuery
