var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.summaries),function(summary){return _c('div',{key:summary.user.id,staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-6 mb-3"},[_c('div',{staticClass:"card card-s"},[_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title text-center"},[_vm._v("Summary")]),_c('table',{staticClass:"table table-border"},[_c('tr',[_vm._m(0,true),_c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v(_vm._s(summary.total))])])])]),_c('tr',[_vm._m(1,true),_c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v(_vm._s(summary.show_created))])])])]),_c('tr',[_vm._m(2,true),_c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v(_vm._s(summary.show_contributed))])])])]),_c('tr',[_vm._m(3,true),_c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v(_vm._s(summary.tag_created))])])])]),_c('tr',[_vm._m(4,true),_c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v(_vm._s(summary.tag_contributed))])])])])]),_c('p',{staticClass:"card-text"},[_c('small',{staticClass:"text-muted"},[_vm._v("Last activity on "+_vm._s(summary.last_activity_time ? new Date( Number(summary.last_activity_time) ).toLocaleString() : ""))])])])]),_c('div',{staticClass:"col-md-4 pl-0"},[(
								summary.user.displayImage &&
								summary.user.displayImage != '/img/default.png'
							)?_c('a',{attrs:{"href":summary.user.displayImage,"target":"_blank"}},[_c('img',{staticClass:"w-100",attrs:{"src":summary.user.displayImage}})]):_c('svg',{staticClass:"svg-inline--fa fa-user fa-w-14",staticStyle:{"max-height":"95px"},attrs:{"aria-hidden":"true","focusable":"false","data-prefix":"fas","data-icon":"user","role":"img","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{staticClass:"___class_+?20___",attrs:{"fill":"currentColor","d":"M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"}})]),_c('div',[_c('h4',{staticClass:"m-2 text-center"},[_vm._v(_vm._s(summary.user.name))])])])])])]),_c('div',{staticClass:"col-md-6 mb-3"},[_c('b-nav',{attrs:{"tabs":"","fill":""}},[_c('b-nav-item',{attrs:{"active":_vm.tableView},on:{"click":_vm.toggleTableView}},[_c('feather-icon',{attrs:{"title":"Table view","icon":"ListIcon"}})],1),_c('b-nav-item',{attrs:{"active":!_vm.tableView},on:{"click":_vm.toggleTableView}},[_c('feather-icon',{attrs:{"title":"Contribution History","icon":"BarChartIcon"}})],1)],1),(!_vm.tableView)?_c('b-card',[_c('vue-apex-charts',{attrs:{"type":"line","height":"360","options":_vm.getChartData(summary.grouped_data).chartOptions,"series":_vm.getChartData(summary.grouped_data).series}})],1):_vm._e(),(_vm.tableView)?_c('b-card',[_c('b-table',{staticStyle:{"text-align":"center"},attrs:{"striped":"","items":_vm.getTableData(summary.grouped_data),"fields":[
						{ key: 'date', label: 'Date' },
						{ key: 'show_created', label: 'Show Created' },
						{ key: 'tag_created', label: 'Tag Created' },
						{ key: 'show_contributed', label: 'Show Contributed' },
						{ key: 'tag_contributed', label: 'Tag Contributed' },
						{ key: 'total', label: 'Total' } ]}})],1):_vm._e()],1)])}),0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v("Total Activities")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v("Shows Created")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v("Shows Contributed")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v("Tags Created")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('p',{staticClass:"card-text"},[_c('strong',[_vm._v("Tags Contributed")])])])}]

export { render, staticRenderFns }