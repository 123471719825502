import profiles from '../../graphql/query/websites/profile'

export default {
    namespaced: true,
    state: {
        profiles: [],
        loading: false,
        count: 0,
        page: 1,
    },
    getters: {
			profiles: state => state.profiles,
			count: state => state.count,
			loading: state => state.loading,
    },
    mutations: {
      setProfiles (state, {rows, count, page}) {
        state.profiles = rows
        state.count = count
        state.page = page
        state.loading = false
      },
      setLoading (state, loading) {
        state.loading = loading
      },
      romoveProfile (state, id) {
        state.profiles = state.profiles.filter(profile => profile.id != id)
        state.count = state.count - 1
      },
			addProfile (state, profile) {
				// push top
				state.profiles.unshift(profile)
				state.count = state.count + 1
			},
			updateProfile (state, profile) {
				state.profiles = state.profiles.map(item => {
					if (item.id === profile.id) {
						return profile
					}
					return item
				})
			}
    },
    actions: {
			async getWebsites({commit, state}, {page, limit}) {
				commit('setLoading', true)
				await axios.post("/graphql", {
					operationName: "",
					query: profiles,
					variables: {
						first: limit || null,
						skip: (page - 1) * limit || 0,
					}
				}).then(res => {
					if (res.data.data.sitesProfiles) {
						commit('setProfiles', {rows: res.data.data.sitesProfiles.rows, count: res.data.data.sitesProfiles.count, page: page})
					}
				}).finally(() => {
					commit('setLoading', false)
				})
			},
			async deleteWebsite({commit}, {id, success, error}) {
				await axios.post("/graphql", {
					operationName: "",
					query: `
						mutation sitesProfileDelete($id: ID!) {
							sitesProfileDelete(id: $id)
						}
					`,
					variables: {
						id: id,
					}
				}).then(res => {
					if (res.data.data.sitesProfileDelete) {
						commit('romoveProfile', id)
						success('Website deleted successfully')
					} else {
						error('Error deleting website')
					}
				}).catch(err => {
					error('Error deleting website')
				})
			},
			async createWebsite({commit}, {input, success, error}) {
				//set loading
				commit('setLoading', true)
				await axios.post("/graphql", {
					operationName: "",
					query: `mutation($input: SitesProfileInput!) {
							sitesProfileCreate(SitesProfileInput: $input) {
									id
									name
									domain
									image
							}
					}`,
					variables: {
						input: input
					}

				}).then(res => {
					if (res.data.data.sitesProfileCreate) {
						commit('addProfile', res.data.data.sitesProfileCreate)
						success()
					}
				}
				).catch(err => {
					error(err)
				}).finally(() => {
					commit('setLoading', false)
				})
			},
			async updateWebsite({commit}, {id, input, success, error}) {
				//set loading
				commit('setLoading', true)
				await axios.post("/graphql", {
					operationName: "",
					query: `mutation($id: ID!, $input: SitesProfileInput!) {
							sitesProfileUpdate(id: $id, SitesProfileInput: $input) {
									id
									name
									domain
									image
							}
					}`,
					variables: {
						id: id,
						input: input
					}

				}).then(res => {
					if (res.data.data.sitesProfileUpdate) {
						commit('updateProfile', res.data.data.sitesProfileUpdate)
						success()
					}
				}
				).catch(err => {
					error(err)
				}).finally(() => {
					commit('setLoading', false)
				})
			}

    },
  }
