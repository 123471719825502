export default {
	namespaced: true,
	state: {
		loading: false,
		reviews: [],
		count: 0,
		page: 1,
	},
	getters: {
		reviews: state => state.reviews,
		count: state => state.count,
		loading: state => state.loading,
	},
	mutations: {
		setReviews (state, {rows, count}) {
			state.reviews = rows
			state.count = count
			state.loading = false
		},
		setLoading (state, loading) {
			state.loading = loading
		},
		updateReview (state,
			{id,
			comment,
			isPublic,
			spoilerAlert,
			storyRatingPoint,
			actingRatingPoint,
			directionRatingPoint}) {
			state.reviews = state.reviews.map(review => {
				if (review.id == id) {
					review.comment = comment
					review.isPublic = isPublic
					review.spoilerAlert = spoilerAlert
					review.storyRatingPoint = storyRatingPoint
					review.actingRatingPoint = actingRatingPoint
					review.directionRatingPoint = directionRatingPoint
				}
				return review
			})
		},
	},
	actions: {
		async getReveiws ({commit, state}, {skip, limit}) {
			commit('setLoading', true)
			axios
        .post("/graphql", {
          operationName: "",
          query: `query($first: Int, $skip: Int){
            allReviews(first: $first, skip: $skip){
							count
							rows{
								id
								comment
								isPublic
								spoilerAlert
								storyRatingPoint
								actingRatingPoint
								directionRatingPoint
								show{
									id
									title
									slug
									url
								}
								user{
									name
								}
							}
            }
          }
          `,
          variables: { first: limit, skip: skip },
        })
        .then((response) => {
          if (response.data?.data?.allReviews) {
            commit('setReviews', response.data.data.allReviews)
          }
        });
		},
		async updateReview ({commit, state}, {id, input, success = console.log, error = console.log}) {
			axios.post("/graphql", {
        operationName: "",
        query: `
				mutation($id: ID!, $review: ReviewUpdateInput!) {
					reviewUpdateAdmin(id: $id, review: $review) {
						id
						comment
						isPublic
						spoilerAlert
						storyRatingPoint
						actingRatingPoint
						directionRatingPoint
					}
				}
				`,
        variables: { id: id, review: input },
      }).then((response) => {
				if (response.data?.data?.reviewUpdateAdmin) {
					success('Review updated successfully')
					commit('updateReview', response.data.data.reviewUpdateAdmin)
				} else {
					error('Error updating review')
				}
			}).catch((err) => {
				console.log(err)
				error('Error updating review')
			})
		}

	},
}
