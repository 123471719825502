<template>
	<b-card>

		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white cursor-pointer" @click="initData" title="Click to refresh tags.">Tags</h3>
			<div class="d-flex">
				<b-button variant="success" class="ml-2" @click="toggleAddTag">Add Tag</b-button>
			</div>
		</template>
		<br>
		<div class="search-form">
      <b-input-group>
        <b-input-group-prepend>
          <b-button variant="outline-primary">
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-input-group-prepend>
        <b-form-input placeholder="Search by name..." @input="debounceInput" v-model.trim="searchQuery"/>
        <b-input-group-append>
          <b-button variant="primary" @click="getTags(1)">
            Search !
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
		<br>

		<b-table striped bordered  v-if="!loading && count" responsive="sm" :items="tags" :fields="fields" class="text-center">
			<template v-slot:cell(name)="data">
				<b-link :href="getPublicUrl('/tag/'+data.item.slug)" target="__blank">{{ data.item.name }}</b-link>
			</template>
			<template v-slot:cell(actions)="data">
				<div class="d-flex justify-content-center">
					<b-button variant="primary" class="mr-min btn-icon" @click="toggleUpdateTag(data.item)">Edit</b-button>
					<b-button variant="danger" class="mr-min btn-icon" @click="deleteTag(data.item.id)">
						<feather-icon icon="TrashIcon" size="18"/>
					</b-button>
					<b-button class="mr-min btn-icon"  variant="primary" @click="toggleActivity(data.item)">
						<feather-icon icon="ActivityIcon" size="18"/>
					</b-button>
					<action-button :tagId="data.item.id" />
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="loading">loading...</span>
			<span v-else>No tags found</span>
		</div>

		<template v-slot:footer>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(count / limit) || 1"
				use-router
			></b-pagination-nav>
			<show-count :count="count" :limit="limit" :page="page"/>
		</template>


    <!-- tags user activities -->
    <b-modal
      v-model="userActivityOnSelectedTag.show"
      ok-only
      ok-title="Close"
      size="lg"
      :title="'User Activities - ' + userActivityOnSelectedTag.title"
    >
      <b-table
				v-if="!userActivityOnSelectedTag.loading && userActivityOnSelectedTag.count"
				responsive
				:items="userActivityOnSelectedTag.activities"
				:fields="userActivityOnSelectedTag.fields"
				class="text-center"
				striped bordered
				:busy="userActivityOnSelectedTag.loading"
			>
        <template #cell(createdAt)="data">
          <span class="text-nowrap">
            {{ new Date(Number(data.item.createdAt)).toLocaleString() }}
          </span>
        </template>

        <template #cell(person)="data">
          <span class="text-nowrap">
            {{ (data.item.user) ? data.item.user.name : (data.item.channel) ? data.item.channel.tag.name : '' }}
          </span>
        </template>

        <template #cell(details)="data">
          <div @click="toggleActivityCollapse(data.item.id)" class="activity-details text-center" :class="data.item.collapse ? 'activity-details-collapse': ''">
          <p >
            {{ data.item.details }}
          </p>
          <feather-icon v-if="data.item.details" class="icon" icon="ChevronDownIcon"
                size="18"/>
          </div>
        </template>
      </b-table>
      <div v-else class="p-2 text-center">
				<span v-if="userActivityOnSelectedTag.loading">loading...</span>
				<span v-else>No activities found</span>
			</div>
    </b-modal>
    <!--  tags user activities end -->

		<!-- add tag -->
		<b-modal
			v-model="addTag.show"
			ok-title="Add"
			size="lg"
			title="Add Tag"
			@ok="addTagValidation"
			:ok-disabled="addTag.loading"
		>

		<validation-observer ref="tagRole">
			<b-form>
				<b-row>

					<!-- domain -->
					<b-col cols="12">
						<b-form-group
							label="Name"
							label-for="name"
						>
							<validation-provider
								#default="{ errors }"
								name="name"
								rules="required|regex:^[a-zA-Z0-9 ]+$"
							>
								<b-form-input
									id="name"
									v-model="addTag.input.name"
									:state="errors.length > 0 ? false:null"
									placeholder="Tag name"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Type"
							label-for="type"
						>
								<b-form-select
									id="type"
									v-model="addTag.input.type"
									:options="tagTypes"
								/>
						</b-form-group>
						<b-form-group v-if="addTag.input.type === 'custom'">
							<validation-provider
								#default="{ errors }"
								name="custom"
								rules="regex:^[a-zA-Z0-9 ]+$"
							>
								<b-form-input
									id="custom"
									v-model="addTag.input.typeCustomInput"
									:state="errors.length > 0 ? false:null"
									placeholder="Custom Type"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group>
							<div class="d-flex justify-content-between">
								<h4>Add Aliases</h4>
								<b-button variant="primary" @click="addNewAlias">Add New</b-button>
							</div>
							<br>
							<div>
								<div v-for="(alias, index) in addTag.input.tagAliases" :key="index" class="d-flex mb-1">
									<validation-provider
										#default="{ errors }"
										name="alias"
										rules="regex:^[a-zA-Z0-9 ]+$"
									>
										<b-form-input
											id="alias"
											v-if="addTag.input.tagAliases[index]"
											v-model="addTag.input.tagAliases[index].aliasName"
											:state="errors.length > 0 ? false:null"
											placeholder="Alias Name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
									<b-button class="ml-1" variant="danger" @click="removeAlias(index)">Remove</b-button>
								</div>
							</div>
						</b-form-group>



					</b-col>
				</b-row>
			</b-form>
		</validation-observer>

		</b-modal>


		<!-- add tag -->
		<b-modal
			v-model="updateTag.show"
			ok-title="Update"
			size="lg"
			title="Update Tag"
			@ok="updateTagValidation"
			:ok-disabled="updateTag.loading"
		>

		<validation-observer ref="tagRoleUpdate">
			<b-form>
				<b-row>

					<!-- domain -->
					<b-col cols="12">
						<b-form-group
							label="Name"
							label-for="name"
						>
							<validation-provider
								#default="{ errors }"
								name="name"
								rules="required|regex:^[a-zA-Z0-9 ]+$"
							>
								<b-form-input
									id="name"
									v-model="updateTag.input.name"
									:state="errors.length > 0 ? false:null"
									placeholder="Tag name"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group
							label="Type"
							label-for="type"
						>
								<b-form-select
									id="type"
									v-model="updateTag.input.type"
									:options="tagTypes"
								/>
						</b-form-group>
						<b-form-group v-if="updateTag.input.type === 'custom'">
							<validation-provider
								#default="{ errors }"
								name="custom"
								rules="regex:^[a-zA-Z0-9 ]+$"
							>
								<b-form-input
									id="custom"
									v-model="updateTag.input.typeCustomInput"
									:state="errors.length > 0 ? false:null"
									placeholder="Custom Type"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group>
							<div class="d-flex justify-content-between">
								<h4>Add Aliases</h4>
								<b-button variant="primary" @click="addNewAliasUpdate">Add New</b-button>
							</div>
							<br>
							<div>
								<div v-for="(alias, index) in updateTag.input.tagAliases" :key="index" class="d-flex mb-1">
									<validation-provider
										#default="{ errors }"
										name="alias"
										rules="regex:^[a-zA-Z0-9 ]+$"
										v-if="updateTag.input.tagAliases[index]"
									>
										<b-form-input
											id="alias"
											v-if="updateTag.input.tagAliases[index]"
											v-model="updateTag.input.tagAliases[index].aliasName"
											:state="errors.length > 0 ? false:null"
											placeholder="Alias Name"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
									<b-button class="ml-1" variant="danger" @click="removeAliasUpdate(index)">Remove</b-button>
								</div>
							</div>
						</b-form-group>



					</b-col>
				</b-row>
			</b-form>
		</validation-observer>

		</b-modal>
  </b-card>

</template>

<script>
import {  BFormCheckbox } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import ActionButton from '@/components/tags/Action.vue'

export default {
  data() {
    return {
			page: this.$route.query.page || 1,
      limit: this.$route.query.limit || 10,
			searchQuery: '',
			fields: [
				{ key: 'name', label: 'Name' },
				{ key: 'slug', label: 'Slug' },
				{ key: 'type', label: 'Type' },
				{ key: 'showCount', label: 'Show Count' },
				{ key: 'lastUpdatedBy.name', label: 'Last Updated By' },
				{ key: 'actions', label: 'Actions' },
			],
			userActivityOnSelectedTag:{
				show: false,
				loading: false,
				title: '',
				activities: [],
				tag: {},
				page: 1,
				limit: 20,
        fields: [
          { key: 'createdAt', label: 'Date' },
          { key: 'activity', label: 'Activity' },
          { key: 'childSlug', label: 'Episode' },
          { key: 'person', label: 'User/Channel' },
          { key: 'details', label: 'Details' },
        ],
			},
			addTag: {
				show: false,
				loading: false,
				input: {
					name: '',
					type: 'tag',
					tagAliases: [],
					typeCustomInput: '',
				}
			},
			updateTag: {
				show: false,
				loading: false,
				input: {
					name: '',
					type: 'tag',
					tagAliases: [],
					typeCustomInput: '',
				}
			},
			tagTypes: [
				{ value: 'tag', text: 'Tag' },
				{ value: 'year', text: 'Year' },
				{ value: 'connection', text: 'Connection' },
				{ value: 'custom', text: 'Custom' },
			],
    }
  },
	computed: {
    ...mapGetters('tags', ['tags', 'count', 'loading']),
	},
	components: {
		ActionButton
	},
  mounted() {
    this.initData()
  },
  watch: {
    page () {
      this.getTags()
    },
  },
	methods: {
		initData() {
    	this.getTags()
		},
		linkGen(page) {
			return {
				name: 'tags',
				query: {
					page,
					limit: this.limit,
				},
			}
		},
		debounceInput: debounce(function (e) {
      this.getTags(1)
    }, 500),
		getTags(page) {
			if(page) {
				this.page = page
			}
			this.$store.dispatch('tags/getTags', {
				skip: (this.page - 1) * this.limit,
				limit: this.limit * 1,
				query: this.searchQuery,
			})
		},
		toggleAddTag() {
			this.addTag.show = !this.addTag.show
			this.addTag.input = {
					name: '',
					type: 'tag',
					tagAliases: [],
				}
		},
		addTagValidation(e) {
			e.preventDefault();

			this.$refs.tagRole.validate().then(success => {
				if (success) {
					this.addTag.loading = true
					const input = {
						name: this.addTag.input.name,
						type: this.addTag.input.type,
						tagAliases: this.addTag.input.tagAliases,
					}
					if(this.addTag.input.type === 'custom') {
						input.type = this.addTag.input.typeCustomInput.trim()
					}
					this.$store.dispatch('tags/addTag', input).then((res) => {
						this.addTag.show = false
						this.successToast('Tag added successfully')
					}).catch((err) => {
						this.errorDialog(err.message)
					}).finally(() => {
						this.addTag.loading = false
					})
				}
			})
		},
		updateTagValidation(e) {
			e.preventDefault();

			this.$refs.tagRoleUpdate.validate().then(success => {
				if (success) {
					this.updateTag.loading = true
					const input = {
						name: this.updateTag.input.name,
						type: this.updateTag.input.type,
						tagAliases: this.updateTag.input.tagAliases,
					}
					if(this.updateTag.input.type === 'custom') {
						input.type = this.updateTag.input.typeCustomInput.trim()
					}
					this.$store.dispatch('tags/updateTag', {
						id: this.updateTag.input.id,
						input,
					}).then((res) => {
						this.updateTag.show = false
						this.successToast('Tag updated successfully')
					}).catch((err) => {
						this.errorDialog(err.message)
					}).finally(() => {
						this.updateTag.loading = false
					})
				}
			})
		},
		toggleUpdateTag(tag) {
			this.updateTag.show = !this.updateTag.show
			this.updateTag.input = {
				id: tag.id,
				name: tag.name,
				type: tag.type,
				tagAliases: tag.tagAliases,
				typeCustomInput: '',
			}
			if(['tag', 'year', 'connection'].indexOf(tag.type) === -1) {
				this.updateTag.input.typeCustomInput = tag.type
				this.updateTag.input.type = 'custom'
			}
		},
		toggleActivity(tag) {
			this.userActivityOnSelectedTag.show = true
			this.userActivityOnSelectedTag.loading = true
			this.userActivityOnSelectedTag.title = tag.name
			this.userActivityOnSelectedTag.tag = tag
			this.getActivities()
		},
		getActivities() {
			this.userActivityOnSelectedTag.loading = true
			this.$store.dispatch('tags/getTagActivities', {
				id: this.userActivityOnSelectedTag.tag?.id,
			}).then(({activities, count}) => {
				this.userActivityOnSelectedTag.activities = activities
				this.userActivityOnSelectedTag.count = count
				this.userActivityOnSelectedTag.loading = false
			}).catch((err) => {
				this.userActivityOnSelectedTag.loading = false
			})
		},
		deleteTag(id) {
			this.confirmDialog({
        text: "Yes, remove this tag."
      }).then(value => {
        if (value) {
          this.$store.dispatch('tags/deleteTag', { id, success: this.successToast, error: this.errorToast })
        }
      });
		},
    toggleActivityCollapse(id) {
      this.userActivityOnSelectedTag.activities = this.userActivityOnSelectedTag.activities.map(activity => {
        if(activity.id === id) {
          activity.collapse = activity.collapse ? false : true
        }
        return activity
      })
    },
		addNewAlias() {
			this.addTag.input.tagAliases.push({
				aliasName: '',
			})
		},
		removeAlias(index) {
			this.addTag.input.tagAliases.splice(index, 1)
		},
		addNewAliasUpdate() {
			this.updateTag.input.tagAliases.push({
				aliasName: '',
			})
		},
		removeAliasUpdate(index) {
			this.updateTag.input.tagAliases.splice(index, 1)
		},
	},




}
</script>

<style>
.modal-body{
	overflow: unset;
}

</style>
