<template>
	<div>
		<b-table
			v-if="!tagSuggestions.loading && tagSuggestions.count"
			responsive="sm"
			:items="tagSuggestions.rows"
			:fields="fields"
			class="text-center"
			striped
			bordered
		>
			<template v-slot:cell(title)="data">
				<a
					:href="
						getPublicUrl(
							(data.item.tag.type == 'channel' ? '/channel/' : '/star/') +
								data.item.tag.slug
						)
					"
					target="_blank"
				>
					{{ data.item.tag.name }}
					<br />
					<span class="badge badge-warning">{{ data.item.tag.type }}</span>
				</a>
			</template>

			<template v-slot:cell(image)="data">
				<a
					:href="data.item.tag.thumbImageUrl"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						:src="data.item.tag.thumbImageUrl"
						:alt="data.item.name"
						class="stars-image-list"
						style="max-height: 95px; max-width: 250px"
					/>
				</a>
			</template>

			<template v-slot:cell(suggestion)="data">
				<span v-if="data.item.type == 'thumbImageUrl'">
					<a
						:href="data.item.thumbImageUrl"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							:src="data.item.thumbImageUrl"
							:alt="data.item.tag.name"
							class="stars-image-list"
							width="250"
						/>
					</a>
				</span>
				<span v-else>
					{{ data.item.value || data.item[data.item.type] }}
				</span>
			</template>

			<template v-slot:cell(username)="data">
				{{
					data.item.suggestions && data.item.suggestions.length > 0
						? data.item.suggestions[0].user.name
						: ""
				}}
			</template>

			<template v-slot:cell(action)="data">
				<div class="dashboard-action d-flex m-auto">
					<a @click="approveTag(data.item)" title="approve">
						<feather-icon icon="CheckCircleIcon" size="18" />
					</a>
					<a @click="rejectSuggestion(data.item)" title="reject" class="trash">
						<feather-icon icon="XCircleIcon" size="18" />
					</a>
				</div>
			</template>
		</b-table>
		<div v-else class="p-2 text-center">
			<span v-if="tagSuggestions.loading">loading...</span>
			<span v-else>No suggestion found</span>
		</div>

		<br />
		<div>
			<b-pagination-nav
				v-model="page"
				:link-gen="linkGen"
				:number-of-pages="Math.ceil(tagSuggestions.count / limit) || 1"
				use-router
			></b-pagination-nav>

			<show-count :count="tagSuggestions.count" :limit="limit" :page="page" />
		</div>

		<b-modal
			id="modal-prevent-closing"
			ref="tagDeleteModal"
			title="Delete Tag Suggestion."
			ok-title="Delete"
			cancel-variant="outline-secondary"
			centered
			@show="resetModal"
			@hidden="resetModal"
			@ok="handleTagDelete"
		>
			<p>You won't be able to revert this!</p>

			<form ref="form" @submit.stop.prevent="handleTagDelete">
				<b-form-group
					label="Reason"
					label-for="reason-input"
					invalid-feedback="Reason ..."
				>
					<b-form-input id="reason-input" v-model="tagDeleteModal.reason" />
				</b-form-group>
			</form>
		</b-modal>

		<b-modal
			id="modal-prevent-closing"
			ref="approveTagSuggestion"
			title="Approve Tag Suggestion."
			ok-title="Approve"
			cancel-variant="outline-secondary"
			centered
			@show="resetModalApproveTag"
			@hidden="resetModalApproveTag"
			@ok="approveTagSuggestion"
		>
			<div class="video-edit-wrapper">
				<form v-if="selectedItem">
					<div v-if="selectedItem.tag" class="row">
						<div class="col text-center">
							<img
								:src="selectedItem.tag.thumbImageUrl"
								:alt="selectedItem.tag.name"
								class="tag-thumbnail mb-3"
								width="250"
							/>
							<h3 class="text-center">
								{{ selectedItem.tag.name }}
							</h3>
						</div>
					</div>
					<div class="form-group">
						<label for="tagValue">Suggetion({{ selectedItem.type }})</label>
						<div v-if="selectedItemvalue" class="mb-2">
							<label for="tagValue">Type</label>
							<input
								type="text"
								class="form-control video-title"
								id="tagValue"
								v-model="selectedItem.type"
							/>

							<label for="tagValue">Info</label>
							<input
								type="text"
								class="form-control video-title"
								id="tagValue"
								v-model="selectedItem.value"
							/>
						</div>
						<select
							class="form-control"
							id="type"
							v-else-if="selectedItem.type === 'type'"
							v-model="selectedItem.value"
						>
							<option value="actor">Actor</option>
							<option value="writer">Writer</option>
							<option value="director">Director</option>
							<option value="producer">Producer</option>
						</select>
						<div v-else>
							<textarea v-if="selectedItem.type == 'description'" class="form-control" id="tagValue" v-model="selectedItem[selectedItem.type]"></textarea>
							<input
								v-else
								type="text"
								class="form-control video-title"
								id="tagValue"
								v-model="selectedItem[selectedItem.type]"
							/>

						</div>

					</div>
				</form>
				<div v-else class="text-center">something wrong..!!</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import {
	BCard,
	BCardText,
	BRow,
	BCol,
	BForm,
	BFormGroup,
	BFormInput,
	BFormTextarea,
	BInputGroupAppend,
	BInputGroup,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
	components: {
		BCard,
		BCardText,
		BRow,
		BCol,
		BForm,
		BFormGroup,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			page: Number(this.$route.query.page) || 1,
			limit: Number(this.$route.query.limit) || 20,
			fields: [
				{ key: "title", label: "Title" },
				{ key: "image", label: "Image" },
				{ key: "type", label: "Type" },
				{ key: "suggestion", label: "Suggestion" },
				{ key: "username", label: "User" },
				{ key: "count", label: "Count" },
				{ key: "action", label: "Action" },
			],
			tagDeleteModal: {
				id: null,
				reason: "",
				addAsShow: false,
			},
			selectedItem: "",
		};
	},
	mounted() {
		this.loadData();
	},
	computed: {
		...mapGetters("suggestions", ["tagSuggestions"]),
	},
	watch: {
		page() {
			this.loadData();
		},
	},
	methods: {
		linkGen(page) {
			return {
				name: "starChannelSuggestions",
				query: {
					page,
					limit: this.limit,
				},
			};
		},
		loadData() {
			this.$store.dispatch("suggestions/fetchTagSuggestions", {
				page: this.page,
				limit: this.limit,
			});
		},
		rejectSuggestion({ id }) {
			this.$refs.tagDeleteModal.show();
			this.tagDeleteModal.id = id;
		},
		handleTagDelete(convertAsShow = false) {
			if (!this.tagDeleteModal.id) {
				this.errorDialog({
					title: "Error",
					text: "Something went wrong. Please try again later.",
				});
				return;
			} else {
				this.$store.dispatch('suggestions/rejectShowSuggestion', {input: this.tagDeleteModal, success: this.successToast, error: this.errorDialog, type:'tag'})
			}
		},
		resetModal() {
			this.tagDeleteModal = {
				id: null,
				reason: "",
				addAsShow: false,
			};
		},
		resetModalApproveTag() {
			this.selectedItem = "";
		},
		approveTag(item) {
			this.$refs.approveTagSuggestion.show();
			this.selectedItem = { ...item };
			if (this.selectedItem.type == "title") {
				this.makeSlug();
			}
			if (this.selectedItem.type == "releasedYear") {
				this.makeSlugFromReleasedYear();
			}
			if (this.selectedItem.type === "type") {
				this.customShowType = this.selectedItem.value =
					this.selectedItem.value.toLowerCase();
			}
			if (this.selectedItem.type === "episode") {
				this.selectedItem.value = this.selectedItem.video.slug;
				this.selectedItem.autoApproveEpisode =
					this.selectedItem.show.autoApproveEpisode;
			}
			this.tagImage = this.tagImageUrl = this.tagImageDataUrl = "";
			this.selectedItemvalue =
				this.selectedItem.type != "type" ? item.value || null : null;
			if (this.selectedItem.video == null) {
				this.selectedItem.video = "";
			}
		},
		approveTagSuggestion() {
			let selectedItemValue = this.selectedItem.value
				? this.selectedItem.value
				: this.selectedItem[this.selectedItem.type];

			this.$store.dispatch("suggestions/approveTagSuggestion", {
				input: {
					id: this.selectedItem.id,
					value: selectedItemValue,
					type: this.selectedItem.type,
				},
				success: (msg) => {
					this.successToast(msg);
					this.$refs.approveTagSuggestion.hide();
					this.selectedItem = "";
				},
				error: this.errorDialog,
			});
		},
		selectItem(item) {
			this.selectedItem = item;
			this.videoSuggestionInput.slug = item.value;
		},
		makeSlug() {
			this.selectedItem.show.slug =
				this.selectedItem.value
					.toLowerCase()
					.replace(/[^a-z0-9]+/g, "-")
					.replace(/-$/, "") +
				(this.selectedItem.show.releasedYear != null
					? "-" + this.selectedItem.show.releasedYear
					: "");
		},
		makeSlugFromReleasedYear() {
			this.selectedItem.show.slug =
				this.selectedItem.show.title
					.toLowerCase()
					.replace(/[^a-z0-9]+/g, "-")
					.replace(/-$/, "") +
				"-" +
				this.selectedItem.value;
		},
	},
};
</script>
