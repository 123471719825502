import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { BLink,
    BModal,
    BPaginationNav,
    BPagination,
    BFormGroup,
    BFormInput,
		BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BCard,
    BCardText,
		BForm,
    BFormCheckbox,
		BFormSelect,
    BButton,
    BTable,
		BRow,
		BCol,
		BTabs,
		BTab,
		BBadge,
		VBTooltip
} from 'bootstrap-vue'
import VueBootstrapTypeahead from 'vue-typeahead-bootstrap'
import { Drag, Drop } from 'vue-drag-drop';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import flatPickr from 'vue-flatpickr-component'
import PlayerModal from '@/@core/components/video/PlayerModal'
import BrandIcon from '@/components/icons/BrandIcon'
import ShowCount from '@/components/pagination/ShowCount'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VTooltip from 'v-tooltip'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('b-link', BLink)
Vue.component('b-modal', BModal)
Vue.component('b-pagination-nav', BPaginationNav)
Vue.component('b-pagination', BPagination)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form', BForm)
Vue.component('b-form-input', BFormInput)
Vue.component('b-card', BCard)
Vue.component('b-card-text', BCardText)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group-prepend', BInputGroupPrepend)
Vue.component('b-button', BButton)
Vue.component('b-table', BTable)
Vue.component('player-modal', PlayerModal)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('flat-pickr', flatPickr)
Vue.component('brand-icon', BrandIcon)
Vue.component('b-badge', BBadge)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('drag', Drag)
Vue.component('drop', Drop)
Vue.directive('b-tooltip', VBTooltip)
Vue.component('app-timeline', AppTimeline)
Vue.component('app-timeline-item', AppTimelineItem)
Vue.component('show-count', ShowCount)
