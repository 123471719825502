export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Shows',
        route: 'shows',
        icon: 'FileIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'UsersIcon',
      },
    ],
  },
]
