export default {
    namespaced: true,
    state: {
				unknownShowSuggestions: [],
        loading: false,
        count: 0,
        page: 1,
    },
    getters: {
			unknownShowSuggestions: state => state.unknownShowSuggestions,
			count: state => state.count,
			loading: state => state.loading,
    },
    mutations: {
      setUnknownShowSuggestions (state, {rows, count, page}) {
				state.unknownShowSuggestions = rows || []
				state.count = count || 0
				state.page = page || 1
				state.loading = false
			},
			setLoading (state, loading) {
				state.loading = loading
			},
			deleteUnknownShowSuggestions(state, ids) {
				state.unknownShowSuggestions = state.unknownShowSuggestions.filter(f => !ids.includes(f.id))
			}
    },
    actions: {
			async getSuggestions({commit, state}, { page = 1, limit = 20}) {
				commit('setLoading', true)
				const skip = (page - 1) * limit;
      	axios
        .post("/graphql", {
          operationName: "",
          query: `
						query($skip: Int, $first: Int,) {
							unknownShowSuggestions(first: $first, skip: $skip) {
								count
								rows {
									id
									title
									releasedYear
									tags
									externalLink
								}
							}
						}
						`,
          variables: {"skip": skip, "first": limit},
        })
        .then((response) => {
          if (response.data.data.unknownShowSuggestions) {
						commit('setUnknownShowSuggestions', {rows: response.data.data.unknownShowSuggestions.rows, count: response.data.data.unknownShowSuggestions.count, page})
          }
        })
				.catch((error) => {
					console.log(error)
				})
				.finally(() => {
					commit('setLoading', false)
				})
			},
			async deleteSuggestion({commit}, {id, error, success}) {
				commit('setLoading', true)
				axios
				.post("/graphql", {
					operationName: "",
					query: `
					mutation($id: ID!) {
						deleteUnknownShowSuggestion(id: $id)
					}
					`,
					variables: {"id": id},
				})
				.then((response) => {
					if (response.data.data.deleteUnknownShowSuggestion) {
						commit('deleteUnknownShowSuggestions', [id])
						success()
					}
				})
				.catch((e) => {
					error("Something went wrong. Please try again later.")
					console.log(e)
				})
				.finally(() => {
					commit('setLoading', false)
				})
			}
    },
  }
