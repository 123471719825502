
const profiles = `
	query {
		sitesProfiles {
			count
			rows {
				id
				name
				domain
				image
			}
		}
	}
`


export default profiles
