const proccessFirebaseError = (error) => {
    const message = {}
    const errorMessage = error.message
    if (errorMessage.includes("email-already-in-use")) {
      message.email = "Email already in use"
    } else if (errorMessage.includes("invalid-email")) {
      message.email = "Invalid email"
    } else if (errorMessage.includes("weak-password")) {
      message.password = "Password is too weak"
    } else if (errorMessage.includes("invalid-password") || errorMessage.includes("internal-error")) {
      message.password = "Password is invalid"
    } else if (errorMessage.includes("user-not-found")) {
      message.email = "User not found"
    } else if (errorMessage.includes("wrong-password")) {
      message.password = "Wrong password"
    } else if (errorMessage.includes("too-many-requests")) {
      message.email = "Too many requests"
    } else {
      message.message = "Something went wrong"
    }
    message.message = message.email || message.password || message.message
    return message
  }
  
  export default proccessFirebaseError