export default {
	namespaced: true,
	state: {
		loading: false,
		stars: [],
		count: 0,
		page: 1,
	},
	getters: {
		stars: state => state.stars,
		count: state => state.count,
		loading: state => state.loading,
		page: state => state.page,
	},
	mutations: {
		setStars (state, {rows, count, page}) {
			state.stars = rows
			state.count = count
			state.page = page
			state.loading = false
		},
		setLoading (state, loading) {
			state.loading = loading
		},
		addStar(state, tag) {
			state.stars.unshift(tag)
			state.count++
		},
		updateStar(state, tag) {
			const index = state.stars.findIndex(t => t.id === tag.id)
			if (index !== -1) {
				state.stars.splice(index, 1, tag)
			}
		},
		removeStar (state, id) {
			state.stars = state.stars.filter(tag => tag.id !== id)
			state.count = state.count - 1
		},
		addLink (state, {id, link}) {
			const index = state.stars.findIndex(t => t.id === id)
			if (index !== -1) {
				state.stars[index].links.unshift(link)
			}
		},
		updateLink (state, {tagId, link}) {
			const index = state.stars.findIndex(t => t.id === tagId)
			if (index !== -1) {
				const linkIndex = state.stars[index].links.findIndex(l => l.id === link.id)
				if (linkIndex !== -1) {
					state.stars[index].links.splice(linkIndex, 1, link)
				}
			}
		},

		deleteLink (state, {id, linkId}) {
			const index = state.stars.findIndex(t => t.id === id)
			if (index !== -1) {
				state.stars[index].links = state.stars[index].links.filter(link => link.id !== linkId)
			}
		},
	},
	actions: {
		async addStar ({commit, dispatch}, input) {
			const addStar  = await axios
			.post("/graphql", {
					operationName: "",
					query: `
					mutation($input: TagInput!) {
						tagCreate(input: $input) {
							id
							name
							nameBn
							thumbImageUrl
							displayImageUrl
							type
							slug
							birthPlace
							birthday
							birthYear
							nationality
							activeYear
							lastUpdatedBy{
								id
								name
								email
							}
							profiles{
								id
								tagId
								key
								info
							}
							tagAliases{
								id
								aliasName
							}
							info
							links{
								id
								url
								type
							}
						}
					}
					`,
					variables: { input: input }
				})
			if(addStar.data.data?.tagCreate) {
				commit('addStar', addStar.data.data.tagCreate)
				return true
			} else {
				throw new Error(addStar.data.errors[0]?.message)
			}
		},
		async updateStar ({commit, dispatch}, input) {
			const updateStar = await axios
			.post("/graphql", {
					operationName: "",
					query: `
						mutation($id: ID!, $input: TagUpdateInput!) {
							tagUpdate(id : $id, input: $input) {
								id
								name
								nameBn
								thumbImageUrl
								displayImageUrl
								type,
								slug
								birthPlace
								birthday
								birthYear
								nationality
								activeYear
								lastUpdatedBy{
									id
									name
									email
								}
								profiles{
									id
									tagId
									key
									info
								}
								tagAliases{
								id
								aliasName
								}
							info
							links{
								id
								url
								type
							}
						}
					}
					`,
					variables: {
						id: input.id,
						input: input.input,
					}
				})
			if(updateStar.data.data?.tagUpdate) {
				commit('updateStar', updateStar.data.data.tagUpdate)
				return true
			} else {
				throw new Error(updateStar.data.errors[0]?.message)
			}
		},
		async getStars ({commit, state}, {skip, limit, query}) {
			commit('setLoading', true)
			const res = await axios.post("/graphql", {
				operationName: "",
				query: `
				query($query: String! ,$first: Int,$skip: Int){
					searchStar(query: $query, first: $first, skip: $skip){
						count,
						rows{
							id
							name
							nameBn
							slug
							type
							thumbImageUrl
							displayImageUrl
							birthday
							birthYear
							birthPlace
							nationality
							activeYear
							info
							lastUpdatedBy{
									id
									name
									email
							}
							profiles {
									id
									key
									info
							}
								links{
										id
										url
										type
								}
							tagAliases{
									id
									aliasName
							}
						}
					}
				}`,
				variables: {
					query: query,
					first: limit,
					skip: skip
				}
			})

			commit('setStars', {
				rows: res.data.data.searchStar?.rows || [],
				count: res.data.data.searchStar?.count || 0,
				page: skip/limit + 1
			})
		},
		async getSugestedStars ({commit, state}, {skip, limit}) {

			commit('setLoading', true)
			const res = await axios.post("/graphql", {
					operationName: "",
					query: `
					query($first: Int,$skip: Int){
							newStarSuggestions(first: $first, skip: $skip){
							count,
							rows{
									id
									type
									thumbImageUrl
									count
									name
									description
									birthday
									birthYear
									birthPlace
									nationality
									activeYear
									suggestions{
										user{
											name
										}
									}
							}
					}
			}`,
				variables: { first: limit, skip: skip },
			})
			commit('setStars', {
				rows: res.data.data.newStarSuggestions?.rows || [],
				count: res.data.data.newStarSuggestions?.count || 0,
				page: skip/limit + 1
			})
		},
		async deleteStar({commit}, {id, success, error}) {
			await axios.post("/graphql", {
				operationName: "",
				query: `
					mutation($id: ID!) {
						tagDelete(id: $id )
				}
				`,
				variables: {
					id: id,
				}
			}).then(res => {
				if (res.data.data?.tagDelete) {
					commit('removeStar', id)
					success('Star deleted successfully')
				} else if(res.data.errors) {
					throw new Error(res.data.errors[0].message)
				}
			}).catch(err => {
				error(err)
			})
		},

		async approveStar({commit}, {id, input, success, error}) {
			await axios.post("/graphql", {
				operationName: "",
				query: `
				mutation($id: ID!, $input: NewStarApproveInput) {
					approveNewStarSuggestion(id : $id, input: $input)
				}
				`,
				variables: {
					id: id,
					input: input
				}
			}).then(res => {
				if (res.data.data?.approveNewStarSuggestion) {
					commit('removeStar', id)
					success('Star approved successfully')
				} else if(res.data.errors) {
					throw new Error(res.data.errors[0].message)
				}
			}).catch(err => {
				error(err)
			})
		},
		async deleteSuggestions({commit}, {id, success, error}) {
			await axios.post("/graphql", {
				operationName: "",
				query: `
					mutation($id: ID!) {
						deleteSuggestion(id: $id )
				}
				`,
				variables: {
					id: id,
				}
			}).then(res => {
				if (res.data.data?.deleteSuggestion) {
					commit('removeStar', id)
					success('Star suggestion deleted successfully')
				} else if(res.data.errors) {
					throw new Error(res.data.errors[0].message)
				}
			}).catch(err => {
				error(err)
			})
		}
	},
}
