<template>
	<div>
		<a @click="toggleModal()">
			<feather-icon icon="FilmIcon" />
		</a>
    <!-- add show-->
    <b-modal
      v-model="episodesManageModal"
      ok-title="Save Changes"
			@ok="saveEpisodes()"
      size="xl"
      title="Manage Episodes"
			no-close-on-backdrop
    >

    <table class="table table-bordered table-striped bordered  table-hover text-center shows-table shows-table" style="position:relative">
      <thead>
        <tr>
          <th>Slug</th>
          <th>Youtube Video Id</th>
          <th>Duration</th>
          <th>Image</th>
          <th>Video Publised At</th>
          <th>External Link</th>
          <th>Video Embeddable</th>
          <th>Default</th>
          <th>Public</th>
					<th>Actions</th>
        </tr>
      </thead>
      <tr v-if="activeEpisodesLoading">
        <div class="table-spinner">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </tr>

      <tbody>
        <tr v-for="(episode, index) in activeEpisodes" :key="index">
          <td>
						<b-input-group>
							<b-form-input
								:value="episode.slug"
								@keyup="(e) => setSlug(e, index, true)"
								:data-epid="episode.id"
								placeholder="Slug"
							/>
						</b-input-group>
					</td>
					<td>
						<b-input-group>
							<b-form-input
								:value="episode.youtubeVideoId" @keyup="(e) => setYoutubeVideoId(e, index, true)"
								@change="reloadEpisode(index, episode.youtubeVideoId, true)"
								:data-epid="episode.id"
								placeholder="Youtube Video ID"
							/>
							<b-input-group-append is-text>
								<feather-icon
									icon="CopyIcon"
									class="cursor-pointer"
									@click="copyToClipboard(episode.youtubeVideoId)"
								/>
							</b-input-group-append>
						</b-input-group>
					</td>
					<td>
						<b-input-group>
							<b-form-input
								:value="episode.duration"
								@keyup="(e) => setDuration(e, index, true)"
								:data-epid="episode.id"
								placeholder="Duration"
							/>
						</b-input-group>
					</td>
					<td>
						<div v-if="episode.id == ''">
							<a v-if="episode.thumbImageUrlLg" :href="largeImage(episode.thumbImageUrl)" target="_blank"
									rel="noopener noreferrer">
									<img class="episode-thumbnail" :src="episode.thumbImageUrl" width="150px">
							</a>
							</div>
							<a v-else :href="largeImage(episode.thumbImageUrl)" target="_blank"
							rel="noopener noreferrer">
							<img class="episode-thumbnail" :src="episode.thumbImageUrl" width="150px">
					</a>
					</td>
					<td>
						<b-input-group>
							<b-form-input
								:value="inputEpisodeDate(episode.videoPublishedAt)"
								@change="setVideoPublishedAt($event, index, true)"
								:data-epid="episode.id"
								placeholder="Video Published At"
								type="date"
							/>
						</b-input-group>
					</td>
					<td>
						<b-input-group>
							<b-form-input
								:value="episode.externalLink"
								@keyup="(e) => setExternalLink(e, index, true)"
								:data-epid="episode.id"
								placeholder="External Link"
							/>
						</b-input-group>
					</td>
					<td> <input type="checkbox" :checked="episode.videoEmbeddable" :value="episode.videoEmbeddable" @change="(e) => toggleVideoEmbeddable(e, index, true)" :data-epid="episode.id"> </td>
					<td> <input v-if="episode.isPublic" type="checkbox" :checked="(defaultEpisode == episode.slug)"  @change="toggleDefault(episode.slug, index)" :data-epid="episode.id"> </td>
					<td>
						<b-form-checkbox
							:checked="episode.isPublic"
							@change="toggleIsPublic(index, true)"
							class="custom-control-primary"
							name="check-button"
							switch
						/>
					</td>
					<td>
						<div class="dashboard-action d-flex">

							<a @click="deleteEpisode(index, true)" class="trash">
                <feather-icon icon="TrashIcon"
                size="18"/>
              </a>

							<a v-if="episode.videoEmbeddable" @click="videoPlay(episode)">
                <feather-icon icon="PlayCircleIcon"
                size="18"/>
              </a>
              <a v-else :href="'https://www.youtube.com/watch?v='+ episode.youtubeVideoId" target="_blank">
                <feather-icon icon="PlayCircleIcon"
                size="18"/>
              </a>

							<a @click="saveEpisodes(episode.id)">
                <feather-icon icon="SaveIcon"
                size="18"/>
              </a>
							<a @click="reloadEpisode(index, episode.youtubeVideoId, true)">
                <feather-icon icon="RefreshCcwIcon"
                size="18"/>
              </a>


						</div>

					</td>
        </tr>
      </tbody>

			<!-- Pagination -->
			<tfoot>
				<tr>
					<td colspan="10">
						<b-pagination
							:disabled="activeEpisodesLoading"
							v-model="activeEpisodePageValue"
							:total-rows="activeEpisodeCount"
							:per-page="limit"
						></b-pagination>
					</td>
				</tr>
			</tfoot>
    </table>

		<br>
		<div v-if="suggestedEpisodes.length" >
			<center>
				<h3>Suggested Episodes</h3>
			</center>
			<table class="table table-bordered table-striped bordered  table-hover text-center shows-table shows-table" style="position:relative">
				<thead>
					<tr>
						<th>Slug</th>
						<th>Youtube Video Id</th>
						<th>Duration</th>
						<th>Image</th>
						<th>Video Publised At</th>
						<th>External Link</th>
						<th>Video Embeddable</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tr v-if="requestedEpisodesLoading">
					<div class="table-spinner">
						<div class="spinner-border" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</tr>

				<tbody>
					<tr v-for="(episode, index) in suggestedEpisodes" :key="index">
						<td>
							<b-input-group>
								<b-form-input
									:value="episode.slug"
									@keyup="(e) => setSlug(e, index, false)"
									:data-epid="episode.id"
									placeholder="Slug"
								/>
							</b-input-group>
						</td>
						<td>
							<b-input-group>
								<b-form-input
									:value="episode.youtubeVideoId" @keyup="(e) => setYoutubeVideoId(e, index, false)"
									@change="reloadEpisode(index, episode.youtubeVideoId, false)"
									:data-epid="episode.id"
									placeholder="Youtube Video ID"
								/>
								<b-input-group-append is-text>
									<feather-icon
										icon="CopyIcon"
										class="cursor-pointer"
										@click="copyToClipboard(episode.youtubeVideoId)"
									/>
								</b-input-group-append>
							</b-input-group>
						</td>
						<td>
							<b-input-group>
								<b-form-input
									:value="episode.duration"
									@keyup="(e) => setDuration(e, index, false)"
									:data-epid="episode.id"
									placeholder="Duration"
								/>
							</b-input-group>
						</td>
						<td>
							<div v-if="episode.id == ''">
								<a v-if="episode.thumbImageUrlLg" :href="largeImage(episode.thumbImageUrl)" target="_blank"
										rel="noopener noreferrer">
										<img class="episode-thumbnail" :src="episode.thumbImageUrl" width="150px">
								</a>
								</div>
								<a v-else :href="largeImage(episode.thumbImageUrl)" target="_blank"
								rel="noopener noreferrer">
								<img class="episode-thumbnail" :src="episode.thumbImageUrl" width="150px">
						</a>
						</td>
						<td>
							<b-input-group>
								<b-form-input
									:value="inputEpisodeDate(episode.videoPublishedAt)"
									@change="setVideoPublishedAt($event, index, false)"
									:data-epid="episode.id"
									placeholder="Video Published At"
									type="date"
								/>
							</b-input-group>
						</td>
						<td>
							<b-input-group>
								<b-form-input
									:value="episode.externalLink"
									@keyup="(e) => setExternalLink(e, index, false)"
									:data-epid="episode.id"
									placeholder="External Link"
								/>
							</b-input-group>
						</td>
						<td> <input type="checkbox"  :checked="episode.videoEmbeddable" :value="episode.videoEmbeddable" @change="(e) => toggleVideoEmbeddable(e, index, false)" :data-epid="episode.id"> </td>
						<td>
							<div class="dashboard-action d-flex">

								<a @click="deleteSuggestion(episode.id)" class="trash">
									<feather-icon icon="TrashIcon"
									size="18"/>
								</a>

								<a v-if="episode.videoEmbeddable" @click="videoPlay(episode)">
									<feather-icon icon="PlayCircleIcon"
									size="18"/>
								</a>
								<a v-else :href="'https://www.youtube.com/watch?v='+ episode.youtubeVideoId" target="_blank">
									<feather-icon icon="PlayCircleIcon"
									size="18"/>
								</a>
								<a @click="approveEpisode(episode)" class="approve">
									<feather-icon icon="CheckCircleIcon"
									size="18"/>
								</a>
								<a @click="reloadEpisode(index, episode.youtubeVideoId, false)">
									<feather-icon icon="RefreshCcwIcon"
									size="18"/>
								</a>


							</div>

						</td>
					</tr>
				</tbody>
				<!-- Pagination -->
				<tfoot>
					<tr>
						<td colspan="10">
							<b-pagination
								:disabled="requestedEpisodesLoading"
								v-model="requestedEpisodePageValue"
								:total-rows="requestedEpisodeCount"
								:per-page="limit"
							></b-pagination>
						</td>
					</tr>
				</tfoot>

			</table>
		</div>

		<div class="add-new">
			<b-button @click="addNewEpisode" variant="primary">
				<feather-icon icon="PlusIcon" size="18" />
				<span class="align-middle ml-25">Add New</span>
			</b-button>
		</div>
    </b-modal>

		<b-modal
      v-model="deleteEpisodeSuggestion.show" ref="deleteSuggestionModal" title="Delete Suggestion" @ok="deleteSuggestionQ" ok-title="Delete" cancel-title="Cancel">
			<p>Are you sure you want to delete this suggestion?</p>
			<div class="input">
				<b-form-checkbox
					v-model="deleteEpisodeSuggestion.addAsShow"
				>
				Add as show suggestion
				</b-form-checkbox>
			</div>
		</b-modal>

    <!-- add show end -->
    <player-modal :showVideoPlayer="showVideoPlayer" :selectedShow="selectedEpisode" :videoPlay="videoPlay"></player-modal>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'
const validLInkRegx = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
export default {
	data() {
		return {
			episodesManageModal: false,
			errorMessage: '',
			selectedEpisode: {},
			showVideoPlayer: false,
			deleteEpisodeSuggestion: {
				show: false,
				id: null,
				addAsShow: false,
			}
		}
	},
	props: {
		show: {
			type: Object,
			default: null
		},
	},
	computed: {
		...mapGetters('episodes', ['episodes',
		'defaultEpisode',
		'activeEpisodes',
		'activeEpisodePage',
		'activeEpisodeCount',
		'activeEpisodesLoading',
		'suggestedEpisodes',
		'requestedEpisodePage',
		'requestedEpisodeCount',
		'requestedEpisodesLoading',
		'loading', 'limit']),

		activeEpisodePageValue : {
			get() {
				return this.activeEpisodePage;
			},
			set(value) {
				this.$store.commit('episodes/setActiveEpisodePage', value);
				this.$store.dispatch('episodes/getEpisodes', {id: this.show.id, isActive: true});
			}
		},
		requestedEpisodePageValue : {
			get() {
				return this.requestedEpisodePage;
			},
			set(value) {
				this.$store.commit('episodes/setRequestedEpisodePage', value);
				this.$store.dispatch('episodes/getEpisodes', {id: this.show.id, isActive: false});
			}
		},
	},
	watch: {

	},
	methods: {
		toggleModal() {
			this.episodesManageModal = !this.episodesManageModal;
			if(this.episodesManageModal) {
				this.loadEpisodes();
			}
		},
		loadEpisodes() {
			this.$store.dispatch('episodes/getEpisodes', {id: this.show.id, isActive: true});
			this.$store.dispatch('episodes/getEpisodes', {id: this.show.id, isActive: false});
		},
		saveEpisodes(id = null) {
			this.$store.dispatch('episodes/updateEpisodes', {id, success: this.successToast, error: this.errorDialog});
		},
		setSlug(e, index, isActive) {
			const slug = e.target.value;
			this.$store.commit('episodes/setSlug', {index, slug, isActive});
		},
		setYoutubeVideoId (e, index, isActive) {
			const id = e.target.dataset.epid;
			let youtubeVideoId = e.target.value;
			if (youtubeVideoId.length > 11) {
				const match = youtubeVideoId.match(validLInkRegx);
				youtubeVideoId =
						match && match[7].length == 11 ? match[7] : false;
			}
			if(!youtubeVideoId) {
				this.errorDialog('Invalid Youtube Video ID');
				return;
			}

			this.$store.commit('episodes/setYoutubeVideoId', {index, youtubeVideoId, isActive});
		},
		setDuration (e, index, isActive) {
			const id = e.target.dataset.epid;
			const duration = e.target.value;
			this.$store.commit('episodes/setDuration', {index, duration, isActive});
		},
		setVideoPublishedAt (videoPublishedAt, index, isActive) {
			videoPublishedAt = new Date(videoPublishedAt).getTime();

			this.$store.commit('episodes/setVideoPublishedAt', {index, videoPublishedAt, isActive});
		},
		setExternalLink (e, index, isActive) {
			const id = e.target.dataset.epid;
			const externalLink = e.target.value;
			this.$store.commit('episodes/setExternalLink', {index, externalLink, isActive});
		},
		toggleVideoEmbeddable (e, index, isActive) {
			const id = e.target.dataset.epid;
			this.$store.commit('episodes/toggleVideoEmbeddable', {index, isActive});
		},
		setDefault (e, isActive) {
			const id = e.target.dataset.epid;
			const isDefault = e.target.value;
			this.$store.commit('episodes/setDefault', {id, isDefault, isActive});
		},
		setPublic (e, isActive) {
			const id = e.target.dataset.epid;
			const isPublic = e.target.value;
			this.$store.commit('episodes/setPublic', {id, isPublic, isActive});
		},
		toggleIsPublic (index, isActive) {
			this.$store.commit('episodes/toggleIsPublic', {index, isActive});
		},
		toggleDefault (slug) {
			this.$store.commit('episodes/toggleDefault', slug);
		},
		inputEpisodeDate(date) {
			// check timestamp or timestring
			if (isNaN(date)) {
				return new Date(date).toISOString()?.split('T')[0];
			}
			return new Date(Number(date)).toISOString()?.split('T')[0];
		},
		videoPlay(show = {}) {
			this.selectedEpisode = show;
      this.showVideoPlayer = show.id ? true : false
    },
		deleteEpisode(index, isActive) {
			this.$store.commit('episodes/deleteEpisode', {index, isActive});
		},
		reloadEpisode(index, youtubeVideoId = null, isActive) {
			this.$store.dispatch('episodes/reloadEpisode', {index, youtubeVideoId, isActive, success: this.successToast, error: this.errorDialog});
		},
		addNewEpisode() {
			this.$store.commit('episodes/addNewEpisode');
		},
		approveEpisode(episode) {
			this.$store.dispatch('episodes/approveEpisode', {episode, success: this.successToast, error: this.errorDialog});
		},
		deleteSuggestion(id) {
			this.deleteEpisodeSuggestion.show = true;
			this.deleteEpisodeSuggestion.id = id;
			this.deleteEpisodeSuggestion.addAsShow = false;
		},
		deleteSuggestionQ() {
			if(this.deleteEpisodeSuggestion.id) {
				this.$store.dispatch('episodes/deleteSuggestion', {id: this.deleteEpisodeSuggestion.id, addAsShow: this.deleteEpisodeSuggestion.addAsShow, success: (message) => {
					this.successToast(message);
					this.deleteEpisodeSuggestion.show = false;
					this.deleteEpisodeSuggestion.id = null;
					this.deleteEpisodeSuggestion.addAsShow = false;
				 }, error: this.errorDialog});
			} else {
				this.errorDialog('No episode selected');
			}
		}


	},
}
</script>
