<template>
    <!-- Show Video Modal -->
    <div class="modal fade show" v-show="showVideoPlayer" tabindex="-1" role="dialog" aria-hidden="true" style="display:block; background: #00000078;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="videoPlay()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="admin-video">
                        <iframe width="100%" height="315"
                            :src="'https://www.youtube.com/embed/' + selectedShow.youtubeVideoId"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="selectedShow.youtubeVideoId" @click="copyToClipboard(selectedShow.youtubeVideoId)" class="btn btn-success" title="copy youtube id">
                      <feather-icon icon="ClipboardIcon" size="18"/>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="videoPlay()">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Show Video Modal End-->
</template>

<script>

export default {
    props: {
        showVideoPlayer: {
            type: Boolean,
            default: false
        },
        selectedShow: {
            type: Object,
            default: () => {}
        },
        videoPlay: {
            type: Function,
            default: () => {}
        }
    },
}
</script>