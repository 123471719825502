export default {
    namespaced: true,
    state: {
        logs: [],
        loading: false,
        count: 0,
        page: 1,
    },
    getters: {
			logs: state => state.logs,
			count: state => state.count,
			loading: state => state.loading,
    },
    mutations: {
      setLogs (state, {rows, count, page}) {
				state.logs = rows
				state.count = count
				state.page = page
				state.loading = false
			},
			setLoading (state, loading) {
				state.loading = loading
			},
    },
    actions: {
			async fetchLogs({commit, state}, {page, limit}) {
				commit('setLoading', true)
				axios
				.post("/graphql", {
					operationName: "",
					query: `
						query($skip: Int, $first: Int) {
							logs(skip: $skip, first: $first) {
								count
								rows {
									id
									action
									currentFile
									input
									errorMessage
									createdAt
									updatedAt
								}

							}
						}
					`,
					variables: {
						first: limit || 20,
						skip: (page - 1) * limit || 0,
					},
				})
				.then((response) => {
					if (response.data && response.data.data?.logs) {
						commit('setLogs', {
							rows: response.data.data.logs.rows,
							count: response.data.data.logs.count,
							page,
						})
					}
				})
			}

    },
  }
