<template>
  <b-card>
		<template v-slot:header class="bg-primary flex">
			<h3 class="text-white"  @click="getLogs" title="Click to refresh logs" >Error Logs</h3>
		</template>
		<br>
		<template>
			<b-table striped bordered  responsive :items="logs" :fields="fields" class="text-center">
				<template v-slot:cell(createdAt)="data">
					{{ new Date(Number(data.item.createdAt)).toLocaleString() }}
				</template>
			</b-table>
		</template>
		<br>

		<b-pagination-nav
      v-model="page"
      :link-gen="linkGen"
      :number-of-pages="Math.ceil(count / limit) || 1"
      use-router
    ></b-pagination-nav>
		<show-count :count="count" :limit="limit" :page="page"/>

  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			fields: [
				{ key: 'id', label: 'ID' },
				{ key: 'action', label: 'Action' },
				{ key: 'currentFile', label: 'File' },
				{ key: 'input', label: 'Inputs' },
				{ key: 'errorMessage', label: 'Error Message' },
				{ key: 'createdAt', label: 'created At' },
			],
		}
	},
	mounted() {
		this.getLogs()
	},
	computed: {
    ...mapGetters('errorLogs', ['logs', 'count', 'loading']),
		page: {
			get() {
				return (this.$route.query?.page || 1) * 1;
			},
			set(page) {
				if(page == this.page) return;
				this.$router.push({ query: { ...this.$route.query, page } })
			}
		},
		limit: {
			get() {
				return (this.$route.query?.limit || 20) * 1;
			},
			set(limit) {
				if(limit == this.limit) return;
				this.$router.push({ query: { ...this.$route.query, limit } })
			}
		},
	},
	watch: {
    page () {
      this.getLogs()
    },
	},
	methods: {
		linkGen(page) {
			return {
				name: 'errorLogs',
				query: {
					page,
					limit: this.limit,
					pending: this.pending,
				},
			}
		},
		getLogs() {
			this.$store.dispatch('errorLogs/fetchLogs', {
				page: this.page,
				limit: this.limit,
			})
		},
	},
}
</script>

<style>

</style>
